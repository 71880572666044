import styled from "styled-components";

export const ContainerIdentificationCnpjPart = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5rem;

  p {
    margin-bottom: 0;
    font-size: 0.813rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;

    color: ${({ theme }) => theme?.myDocuments?.primaryDescription};
  }

  span {
    color: ${({ theme }) => theme?.myDocuments?.secondaryDescription};
  }

  box-shadow: 1px 0 0 ${({ theme }) => theme?.console?.inputBorderColor},
    0 1px 0 ${({ theme }) => theme?.console?.inputBorderColor};
`;
