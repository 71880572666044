import styled from "styled-components";

export const ConsolePageDatePickerContainer = styled.div`
  &,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__month-container,
  .react-datepicker__header,
  .react-datepicker__time-list,
  .react-datepicker {
    background: ${({ theme }) => theme?.console.tableOptionsBackground};

    border-color: ${({ theme }) => theme?.console.tableBorderColor} !important;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker {
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.15));
  }

  .react-datepicker__input-container {
    height: 48px;
  }

  .react-datepicker__time-container {
    border-color: ${({ theme }) => theme?.console.tableBorderColor} !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    text-transform: capitalize;

    color: ${({ theme }) => theme?.console.sidebar.titleColor};
    font: 600 0.875rem/1.3125rem "Inter";
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme?.console.sidebar.titleColor};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-list-item {
    color: ${({ theme }) => theme?.console.sidebar.optionTextColor};
    font: 400 0.75rem/1.125rem "Inter";
  }

  .react-datepicker__time-list-item,
  .react-datepicker__day {
    transition: 0.3s all;

    :hover {
      background-color: #0073e5 !important;
      color: white;
    }
  }

  .react-datepicker__day--selected {
    background-color: #0073e5 !important;
    color: white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2rem;
  }

  .react-datepicker__day {
    height: 2rem;
    border-radius: 4px;

    padding-top: 6px;
  }

  .react-datepicker__triangle {
    ::before,
    ::after {
      display: none;
    }
  }
`;
