import React from "react";

export const GroupOverviewIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3359 2H2.66927C1.93394 2 1.33594 2.598 1.33594 3.33333V12.6667C1.33594 13.402 1.93394 14 2.66927 14H13.3359C14.0713 14 14.6693 13.402 14.6693 12.6667V3.33333C14.6693 2.598 14.0713 2 13.3359 2ZM2.66927 12.6667V3.33333H7.33594V12.6667H2.66927ZM8.66927 12.6667V3.33333H13.3359L13.3366 12.6667H8.66927Z"
        fill="currentColor"
      />
      <path
        d="M10 4.66797H12V6.0013H10V4.66797ZM10 7.33464H12V8.66797H10V7.33464Z"
        fill="currentColor"
      />
    </svg>
  );
};
