import { useCallback, useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";

import sendLogToSlack from "shared/infra/services/slackAPI";
import { ERROR_MESSAGES_FROM_VERCEL } from "shared/types/monitoringPageTypes";
import {
  getPreviousURLFromSessionStorage,
  savePreviousURLInSessionStorage,
} from "shared/utils/handleURLInSessionStorage";
import { useGetBrowserInfoToMonitoring } from "shared/utils/hooks/useGetBrowserInfoToMonitoring";
import useNextLoadingProgress from "shared/utils/hooks/useNextLoadingProgress";
import { checkIfHTMLDocumentHasSomeErrorMessage } from "shared/utils/monitoringPageUtils";

import { captureException } from "@sentry/nextjs";

export const useMonitoringPageErrors = () => {
  const { makeErrorMessage } = useGetBrowserInfoToMonitoring();
  const isPageLoading = useNextLoadingProgress();
  const [shouldStartMonitoring, setShouldStartMonitoring] = useState(false);
  const { events, push, asPath } = useRouter();
  const alreadyHandledWithError = useRef(false);

  const startMonitoringForVercelErrorsInPage = () =>
    setShouldStartMonitoring(true);

  const handleVerifyIFPageHasSomeVercelError = useCallback(() => {
    if (alreadyHandledWithError.current) return;

    const hasPreviousUrlSaved = getPreviousURLFromSessionStorage();
    if (!hasPreviousUrlSaved) savePreviousURLInSessionStorage(asPath);

    try {
      const htmlDocumentHasSomeVercelError =
        checkIfHTMLDocumentHasSomeErrorMessage();

      if (!htmlDocumentHasSomeVercelError) return;
      const logErrorMessage = makeErrorMessage();

      sendLogToSlack(logErrorMessage);
      captureException(logErrorMessage);
      alreadyHandledWithError.current = true;

      const isApplicationError = logErrorMessage.includes(
        ERROR_MESSAGES_FROM_VERCEL.APPLICATION_ERROR,
      );
      if (isApplicationError) push("/error");

      const isPageNotFoundError =
        logErrorMessage.includes(ERROR_MESSAGES_FROM_VERCEL.PAGE_NOT_FOUND) ||
        logErrorMessage.includes(
          ERROR_MESSAGES_FROM_VERCEL.CUSTOM_PAGE_NOT_FOUND,
        );

      if (isPageNotFoundError) push("/not-found");
    } catch (error) {
      console.log("Error (handleVerifyIFPageHasSomeVercelError): ", error);
      captureException(error);
    }
  }, [makeErrorMessage, push]);

  useEffect(() => {
    if (!shouldStartMonitoring || alreadyHandledWithError.current) return;

    try {
      events?.on("routeChangeStart", handleVerifyIFPageHasSomeVercelError);
      events?.on("routeChangeError", handleVerifyIFPageHasSomeVercelError);
    } catch (error) {
      console.log("Error (useEffect | shouldStartMonitoring): ", error);
      captureException(error);
    }
  }, [events, handleVerifyIFPageHasSomeVercelError, shouldStartMonitoring]);

  useEffect(() => {
    if (isPageLoading || alreadyHandledWithError.current) return;

    const timer = setTimeout(() => {
      if (!shouldStartMonitoring) return;

      handleVerifyIFPageHasSomeVercelError();
    }, 3000);

    return () => clearTimeout(timer);
  }, [
    handleVerifyIFPageHasSomeVercelError,
    isPageLoading,
    shouldStartMonitoring,
  ]);

  return { startMonitoringForVercelErrorsInPage };
};
