import styled, { css } from "styled-components";

import { DropDownGroupsContainer } from "pages/[tenant]/g/components/ContextTopbar/components/DropDownGroups/styles";
import { MenuGroupListContainer } from "pages/[tenant]/g/components/ContextTopbar/components/MenuGroupList/styles";

import { LogoContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo/styles";
import { MenusListContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList/styles";
import { UserMenuContainer } from "shared/providers/Layout/components/NewTopBar/components/atoms/UserMenu/styles";
import { HamburguerMenuContainer } from "shared/providers/Layout/components/NewTopBar/components/molecules/HamburguerMenu/styles";
import { OptionsContainer } from "shared/providers/Layout/components/NewTopBar/components/molecules/Options/styles";

interface INewTopBarContainerProps {
  shouldHaveBackground: boolean;
  pageIsLoading: boolean;
  isMobile: boolean;
  pageHaveMedia: boolean;
}

interface ITopBarBackgroundProps {
  shouldHaveBackground: boolean;
  isMobile: boolean;
  setupGuide: boolean
}

export const TopBarBackground = styled.div<ITopBarBackgroundProps>`
  transition: 0.3s all;

  position: fixed;
  top: ${({setupGuide}) => setupGuide ? "60px" : 0};
  z-index: 97;
  width: 100%;

  background: ${({ shouldHaveBackground, theme }) =>
    shouldHaveBackground
      ? theme.colors.globalTopbar.solidBackground
      : theme.colors.globalTopbar.linearGradient};

  ${({ theme, shouldHaveBackground }) =>
    shouldHaveBackground &&
    css`
      border-bottom: 1px solid ${theme.colors.globalTopbar.borderColor};
    `}

    ${({ isMobile, setupGuide }) =>
    isMobile && setupGuide &&
    css`
     top: 75px;
     @media screen and (max-width: 477px){
      top: 90px;
     }
    `}
    
`;

export const NewTopBarContainer = styled.div<INewTopBarContainerProps>`
  width: 100%;

  height: 4rem;
  margin: 0 auto;
  max-width: 120rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 !important;
    `}

  padding: 0.7rem 2.6rem;

  ${({ pageHaveMedia, shouldHaveBackground }) =>
    pageHaveMedia &&
    shouldHaveBackground &&
    css`
      ${SideOptionsContainer} > ${MenusListContainer} li a {
        color: #c8c8c9;
      }

      .search-icon path,
      .user-arrow-icon path {
        stroke: #f2f2f2 !important;
      }

      ${HamburguerMenuContainer} {
        .bar-1,
        .bar-2,
        .bar-3 {
          background-color: #f2f2f2 !important;
        }
      }
    `}

  > ${DropDownGroupsContainer} {
    width: 100%;
    height: 100%;

    :hover {
      &,
      svg {
        background: initial;
      }
    }

    ~ ${MenuGroupListContainer} {
      width: 100%;
    }
  }

  @media (max-width: 850px) {
    justify-content: center;

    ${OptionsContainer}, ${HamburguerMenuContainer} {
      display: none;
    }

    ${LogoContainer} {
      position: initial;
      top: initial;
      bottom: initial;
      transform: initial;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ pageHaveMedia, theme }) =>
    pageHaveMedia &&
    css`
      ${HamburguerMenuContainer} {
        .bar-1,
        .bar-2,
        .bar-3 {
          background-color: ${theme.colors.globalTopbar.iconsColor};
        }
      }

      ${UserMenuContainer} {
        svg:not(.user-icon) path {
          stroke: ${theme.colors.globalTopbar.iconsColor};
        }
      }
    `}

  ${({ pageIsLoading, theme }) =>
    pageIsLoading &&
    css`
      background-color: ${theme.colors.globalTopbar.solidBackground};

      ${UserMenuContainer} button {
        background-color: transparent;

        :hover {
          cursor: pointer;

          ${theme.mode === "dark"
            ? "background-color: rgba(255, 255, 255, 0.2)"
            : "box-shadow: 0 0 0 1px #282a30"};
        }
      }
    `}
`;

interface ISideOptionsContainerProps {
  scrollIsOnTop: boolean;
}

export const SideOptionsContainer = styled.div<ISideOptionsContainerProps>`
  display: flex;

  > ${MenusListContainer} {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-left: 1.5rem;

    max-width: calc(((100vw / 2) - 100px) - 10.2rem);
    overflow: hidden;
    max-height: 36px;
    flex-wrap: wrap;

    @media (max-width: 1100px) {
      display: none;
    }

    a {
      font: 600 1rem/1.5rem "Inter";
      color: ${({ theme }) => theme.colors.globalTopbar.menuItensColor};

      padding: 0.38rem 0.75rem;
      display: block;

      border-radius: 4px;

      transition: 0.3s all;

      :hover,
      &.active {
        background-color: ${({ theme }) =>
          theme.colors.globalTopbar.menuItensHoverColor} !important;
      }
    }
  }
`;
