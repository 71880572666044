export const getModeledInitialData = (initialData, setCompanyData) => {
  if (!initialData) return null;

  const dataOriginal = "1983-05-17T00:00:00";

  const data = new Date(dataOriginal);

  const day = data.getDate();
  const moth = data.getMonth() + 1;
  const year = data.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${moth
    .toString()
    .padStart(2, "0")}/${year}`;

  const changeEditUserData = (dataToUpdate) => {
    setCompanyData((prev) => ({
      ...prev,
      ...dataToUpdate,
    }));
  };

  const newInitialData = initialData?.data;

  const changeEditDocumentsUnique = ({
    key,
    value,
  }: {
    key: string;
    value: any;
  }) => {
    setCompanyData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  changeEditDocumentsUnique({
    key: "person_type",
    value: newInitialData?.person_type,
  });
  changeEditDocumentsUnique({ key: "birthdate", value: formattedDate });
  changeEditDocumentsUnique({
    key: "document_type",
    value: newInitialData?.document_type,
  });

  changeEditUserData(newInitialData?.person_data);
  changeEditUserData(newInitialData?.company_data);
};
