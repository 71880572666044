import { ContainerIdentificationCnpjPart } from "./styles";

export const IdentificationCnpjPart = ({ title, identification }) => {
  return (
    <ContainerIdentificationCnpjPart>
      <p>{title}</p>
      <span>{identification}</span>
    </ContainerIdentificationCnpjPart>
  );
};
