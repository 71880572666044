import styled, { css } from "styled-components";

import { AliceNotFound } from "shared/components/organisms/AliceCarouselImport/styles";

interface IBillboardPageContentProps {
  hasHighlights: boolean;
}

export const BillboardPageContainer = styled.div`
  background-color: #010101;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  transition: 0.3s all;

  font-size: max(0.729vw, 0.75rem);

  > ${AliceNotFound} {
    height: 100vh;
    width: 100%;
    pointer-events: none;
    max-height: 1045px;
    aspect-ratio: 16 / 9;

    svg {
      height: 5rem;
      width: 5rem;
    }
  }

  .alice-carousel__wrapper {
    @media (max-width: 768px) {
      overflow: initial;
    }
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    background-color: transparent;
    height: 100%;
    padding: 0;
    position: absolute;
    max-width: 3.125rem;
    transition: all 200ms ease-in-out;

    * {
      height: 100%;
      display: grid;
      place-items: center;
    }
  }

  .alice-carousel__prev-btn {
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
  }

  .alice-carousel__next-btn {
    display: flex;
    flex-direction: row-reverse;
    top: 0;
    right: 0;
  }

  .alice-carousel__next-btn-wrapper,
  .alice-carousel__prev-btn-wrapper {
    display: grid;
    place-items: center;
    padding: 1rem 0;
  }

  .alice-carousel__next-btn-item,
  .alice-carousel__prev-btn-item {
    background-color: #14141480;
    padding: 0;
    width: 100%;
  }
`;

export const BillboardPageContent = styled.div<IBillboardPageContentProps>`
  margin-top: -12vw;
  width: 100vw;

  @media (max-width: 1100px) {
    margin-top: -8vw;
  }

  @media (max-width: 768px) {
    margin-top: 14vw;
  }

  @media (max-width: 500px) {
    margin-top: 20vw;
  }

  .carousel-container {
    padding-bottom: 3rem;
  }

  ${({ hasHighlights }) =>
    !hasHighlights &&
    css`
      bottom: 0;
      margin-top: 5rem;
      position: relative;
      width: 100vw;

      .carousel-container {
        padding-bottom: 0;
      }
    `}
`;
