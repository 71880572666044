import { useContext } from "react";

import AuthContext from "shared/providers/context/AuthContext";

import { ContainerAlertUpdateModal } from "./styles";

export const AlertUpdateDocuments = () => {
  const { loggedUser } = useContext(AuthContext);
  const observation = loggedUser?.personal_documents?.observations;

  return (
    <ContainerAlertUpdateModal>
      <span>Infelizmente o cadastro dos seus documentos não foi aprovado!</span>
      <p>O que você precisa ajustar:</p>
      <p>{observation}</p>
    </ContainerAlertUpdateModal>
  );
};
