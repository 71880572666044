import CircularLoader from "shared/components/atoms/CircularLoader";

import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { ContainerFinallyCreate } from "./styles";

const FinallyCreate = () => {
  const { loading, statusSendDocuments } = useDocumentsContext();

  return (
    <ContainerFinallyCreate>
      {loading ? (
        <CircularLoader />
      ) : (
        <p>
          {statusSendDocuments === "success"
            ? "  Seus dados foram enviados com sucesso! Em breve você receberá um e-mail de atualização, dando início a sua jornada de vendas."
            : "Não foi possível o envio dos documentos. "}
        </p>
      )}
    </ContainerFinallyCreate>
  );
};

export default FinallyCreate;
