import { TopFlagContent } from "@Console/billing/components/atoms/TopFlag/atoms/TopFlagContent";
import { useTopFlagConfig } from "@Console/billing/hooks/useTopFlagConfig";

import { TopFlagContainer } from "./styles";

export const TopFlag = () => {
  const { flagConfig } = useTopFlagConfig();

  const flagIsEmpty = !(
    flagConfig?.backgroundColor &&
    flagConfig?.buttonLabel &&
    flagConfig?.icon &&
    flagConfig?.leftText &&
    flagConfig?.rightText
  );

  if (flagIsEmpty) return null;
  return (
    <TopFlagContainer backgroundColor={flagConfig?.backgroundColor}>
      <TopFlagContent {...flagConfig} />
    </TopFlagContainer>
  );
};
