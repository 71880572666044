import { parseCookies } from "nookies";

import sendLogToSlack from "shared/infra/services/slackAPI";
import { getBrowserInfo } from "shared/utils/getBrowserInfo";
import { getUserSO } from "shared/utils/getUserSO";
import { isInLocalhost } from "shared/utils/isInLocalhost";
import {
  timeInMilisecondsOfTolerance,
  timesOfReloadingsToConsiderThatPageKeepsReloading,
} from "shared/utils/monitoringInfiniteReloadingUtils";

import { captureException, captureMessage } from "@sentry/nextjs";

export const sendPageKeepsReloadingLogToSlack = async (
  path: string,
): Promise<boolean> => {
  try {
    if (isInLocalhost()) return;

    const { username } = parseCookies();

    const logText = [
      `\n *:repeat_one: Um usuário permanece em loop.*`,
      `\n *Página:* ${path}.`,
      `\n *Usuário:* ${
        username ? username?.replaceAll('"', "") : "Não existe usuário logado"
      }.`,
      `\n *Navegador:* ${getBrowserInfo()}.`,
      `\n *Sistema Operacional:* ${getUserSO()}.`,
      `\n Essa página foi recarregada pelo menos *${timesOfReloadingsToConsiderThatPageKeepsReloading}x* em 2 minutos, com intervalos inferiores a *${
        timeInMilisecondsOfTolerance / 1000
      }* segundos entre cada carregamento.`,
    ].reduce((currentText, currentLine) => currentText + currentLine, "");

    await sendLogToSlack(logText);
    return true;
  } catch (error) {
    console.log("Error (sendPageKeepsReloadingLogToSlack): ", error);
    captureException(error);
    captureMessage("Error (sendPageKeepsReloadingLogToSlack)");
    return false;
  }
};
