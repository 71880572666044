import { useState } from "react";

import ptBR from "date-fns/locale/pt-BR";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageForm } from "@Console/components/molecules/ConsolePageForm";

import { ConsolePageDatePickerContainer } from "./styles";

registerLocale("pt-BR", ptBR);

interface IConsolePageDatePickerProps {
  label: string;
  value?: Date;
  handleChange: (date: Date) => void;
  showTimePicker?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
}

export const ConsolePageDatePicker = ({
  label,
  handleChange,
  value,
  showTimePicker,
  isInvalid,
  disabled,
}: IConsolePageDatePickerProps) => {
  const [startDate, setStartDate] = useState(value || null);

  const dateFormat = showTimePicker ? "d/MM/yyyy hh:mm aa" : "d/MM/yyyy";

  const handleCustomChange = (date: Date) => {
    setStartDate(date);
    handleChange(date);
  };

  const customId = removeSpacesAndAccentsFromString(label);

  return (
    <ConsolePageDatePickerContainer id={customId}>
      <DatePicker
        disabled={disabled}
        selected={startDate}
        onChange={handleCustomChange}
        showTimeSelect={showTimePicker}
        timeFormat="HH:mm"
        timeIntervals={10}
        timeCaption="Hora"
        dateFormat={dateFormat}
        locale="pt-BR"
        disabledKeyboardNavigation
        customInput={
          <ConsolePageForm.Input
            label={label}
            value={startDate?.toString()}
            type="text"
            inputMode="text"
            autoComplete="off"
            role="presentation"
            isInvalid={isInvalid}
            disabled={disabled}
            onChange={(item) =>
              handleCustomChange(new Date(item?.target?.value))
            }
          />
        }
      />
    </ConsolePageDatePickerContainer>
  );
};
