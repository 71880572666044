import { ISelectOption } from "pages/[tenant]/console/types";

import { OptionContainer } from "./styles";

interface IOptionProps {
  option: ISelectOption;
  onClick: (...args: any) => void;
  isActive: boolean;
}

export const Option = ({ onClick, option, isActive }: IOptionProps) => {
  const handleClick = (event) => {
    const isDisabled = event.target.getAttribute("data-disabled");
    if (isDisabled === "true") return;

    onClick(event);
  };

  return (
    <OptionContainer
      data-id={option.id}
      data-disabled={!!option?.disabled}
      className={`${isActive ? "active" : ""}`}
      onClick={handleClick}
    >
      {option.label}
    </OptionContainer>
  );
};
