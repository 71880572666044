import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";

import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageTextAreaContainer } from "./styles";

interface IConsolePageTextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  isInvalid?: boolean;
}

export const ConsolePageTextArea = ({
  isInvalid,
  required,
  label,
  ...props
}: IConsolePageTextAreaProps) => {
  const customId = removeSpacesAndAccentsFromString(label);

  return (
    <ConsolePageTextAreaContainer isInvalid={isInvalid}>
      <label>
        {label}
        {required && <span className="required-flag">*</span>}
      </label>
      <textarea
        {...props}
        autoComplete="off"
        data-testid="console-page-input"
        id={customId}
      />
    </ConsolePageTextAreaContainer>
  );
};
