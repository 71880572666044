export function getCloudEndpoint(uuid) {
  switch (uuid) {
    case "dev":
      return "https://back.ensiniodev.com";

    case "loadtest":
      return "http://loadtest.ensinio.local";

    case "qa1":
      return "https://qa1.ensinioqa1.click";

    case "qa2":
      return "https://qa2.ensinioqa2.click";

    case "testemigra2":
      return "https://qa2.ensinioqa2.click";

    case "qa3":
      return "https://qa3.ensinioqa3.click";

    case "qa4":
      return "https://qa4.ensinioqa4.click";

    case "qa5":
      return "https://qa5.ensinioqa5.click";

    case "qa6":
      return "https://qa6.ensinioqa6.click";

    default:
      return `https://${uuid}.ensinio.cloud`;
  }
}
