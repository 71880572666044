import { BsPatchCheckFill } from "react-icons/bs";
import {ContainerGuide, InfoContainer} from "./styles"
import { useRouter } from "next/router";

const BoxSetupGuide = ({isInConsolePage}) => {
  const {push} = useRouter();
  return (
    <ContainerGuide onClick={() => push("/console/setup")} $isInConsolePage={isInConsolePage}>
      <InfoContainer>
        <BsPatchCheckFill />
        <p>
          Falta pouco, você está quaaaase lá!{"  "} 
          <span>
            Conclua o Guia de Setup para começar a utilizar sua plataforma.
          </span>
        </p>
      </InfoContainer>
      <button onClick={() => push("/console/setup")}>Ir para o Guia de Setup</button>
    </ContainerGuide>
  );
};

export default BoxSetupGuide;
