import React, { useState } from "react";

import Image from "next/image";
import Link from "next/link";

import { Popover } from "antd";
import { FiSliders } from "react-icons/fi";

import { Bag } from "shared/assets/icons/Bag";
import { ComponentErrorBoundary } from "shared/components/atoms/ComponentErrorBoundary";
import { ArrowIcon } from "shared/providers/Layout/components/NewTopBar/assets/ArrowIcon";
import { ConfigIcon } from "shared/providers/Layout/components/NewTopBar/assets/ConfigIcon";
import { LogoutIcon } from "shared/providers/Layout/components/NewTopBar/assets/LogoutIcon";
import { UserIcon } from "shared/providers/Layout/components/NewTopBar/assets/UserIcon";
import { SwitchTheme } from "shared/providers/Layout/components/NewTopBar/components/atoms/SwitchTheme";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import capitalizeFirstLetter from "shared/utils/capitalizeFirstLetter";
import { handleSplitName } from "shared/utils/handleSplitName";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { OptionsContainer, UserMenuContainer } from "./styles";

export const Options = () => {
  const { signOut, loggedUser: user } = useAuthContext();
  const isAdmin = user?.admin || user?.superadmin;
  const isPromoter = user?.promoter;

  const userIsLogged = !!user;

  const { setState } = useGlobalContext();
  const handleOpenAuthModal = () =>
    setState((prev) => ({
      ...prev,
      modal: {
        open: true,
        page: "login",
      },
    }));

  return (
    <ComponentErrorBoundary>
      <OptionsContainer>
        {userIsLogged && <h1>{capitalizeFirstLetter(user.first_name)}</h1>}

        <ul>
          {userIsLogged && (
            <li>
              <Link href={`/user/${user?.username}`}>
                <UserIcon />
                Perfil
              </Link>
            </li>
          )}
          {isAdmin && !isPromoter && (
            <li>
              <Link href="/console">
                <ConfigIcon /> Admin Console
              </Link>
            </li>
          )}
          {isPromoter && (
            <li>
              <Link href="/cp/analytics">
                <ConfigIcon /> Painel de Controle
              </Link>
            </li>
          )}
          {userIsLogged && (
            <li>
              <Link href="/account">
                <FiSliders /> Minha conta
              </Link>
            </li>
          )}
          {userIsLogged && (
            <li>
              <Link href="/orders">
                <Bag /> Minhas compras
              </Link>
            </li>
          )}
          <li>
            <SwitchTheme />
          </li>
          <li>
            {userIsLogged ? (
              <button onClick={signOut}>
                <LogoutIcon /> Deslogar
              </button>
            ) : (
              <button onClick={handleOpenAuthModal}>
                <UserIcon /> Entrar / Criar conta
              </button>
            )}
          </li>
        </ul>
      </OptionsContainer>
    </ComponentErrorBoundary>
  );
};

export const UserMenu = () => {
  const [optionsIsOpen, setOptionsIsOpen] = useState(false);

  const { scrollIsOnTop, pageIsLoading } = useTopbarContext();

  const { loggedUser } = useAuthContext();
  const userHavePhoto = !!loggedUser?.photo;

  return (
    <UserMenuContainer
      optionsIsOpen={optionsIsOpen}
      scrollIsOnTop={scrollIsOnTop || pageIsLoading}
    >
      <Popover
        placement="bottomRight"
        content={
          <ComponentErrorBoundary>
            <Options />
          </ComponentErrorBoundary>
        }
        trigger={["click"]}
        className="popover"
        data-testid="popover"
        onOpenChange={() => setOptionsIsOpen(!optionsIsOpen)}
        overlayClassName="topbar-usermenu-popover-container"
      >
        <div className="avatar">
          {loggedUser ? (
            userHavePhoto ? (
              <Image alt="" src={loggedUser?.photo} width={30} height={30} />
            ) : (
              <span>{handleSplitName(loggedUser?.full_name)}</span>
            )
          ) : (
            <UserIcon />
          )}
        </div>

        <ArrowIcon />
      </Popover>
    </UserMenuContainer>
  );
};
