import { SVGProps } from "react";

interface ISvg extends SVGProps<SVGSVGElement> {}

export default ({ ...props }: ISvg) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.667 8L3.33366 8"
      stroke="#B3B3B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12.6665L3.33333 7.99984L8 3.33317"
      stroke="#B3B3B3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
