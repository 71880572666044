import styled, { css } from "styled-components";

import { ConsolePageButtonStyles } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/styles";
import { consolePrimaryColor } from "pages/[tenant]/console/styles/consoleGlobalColors";

interface IConsolePageCheckboxContainerProps {
  isChecked: boolean;
  isInvalid: boolean;
}

export const ConsolePageCheckboxContainer = styled(
  ConsolePageButtonStyles,
)<IConsolePageCheckboxContainerProps>`
  padding: 0;

  width: fit-content;

  .label {
    color: ${({ theme }) => theme.console.sidebar.titleColor};
  }

  .arrow-container {
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;

    border-radius: 4px;
    box-shadow: inset 0px 0px 0px 2px #656565;

    &,
    svg {
      transition: 0.3s all;
    }

    svg {
      position: relative;
      top: ${({ isChecked }) => (isChecked ? "0%" : "-100%")};
    }

    ${({ isChecked }) =>
      isChecked &&
      css`
        background-color: ${consolePrimaryColor};
        box-shadow: inset 0px 0px 0px 2px ${consolePrimaryColor};
      `}

    ${({ isInvalid }) =>
      isInvalid &&
      css`
        box-shadow: inset 0px 0px 0px 2px red;
      `}
  }
`;
