import { useMemo } from "react";

import { IDocuments } from "@Console/statement/types";

type IDocumentsKey = keyof IDocuments;

type IValidations = {
  [key in IDocumentsKey]: boolean;
};

export const useDocumentsValidation = (companyData: IDocuments, personType) => {
  const validations: Partial<IValidations> = useMemo(() => {
    const isLegalPerson = personType === "legal_entity";

    return {
      cnpj: isLegalPerson ? companyData?.cnpj?.length === 18 : true,
      name: companyData?.name?.length >= 5,
      birthdate: companyData?.birthdate?.length === 10,
      cpf: companyData?.cpf?.length === 14,
      residencial_cep: companyData?.residencial_cep?.length === 9,
      residencial_number: companyData?.residencial_number?.length >= 1,
      residencial_street: companyData?.residencial_street?.length >= 1,
      residencial_neighborhood:
        companyData?.residencial_neighborhood?.length >= 1,
      residencial_city: companyData?.residencial_city?.length >= 1,
      residencial_uf: companyData?.residencial_uf?.length >= 1,
    };
  }, [companyData]);

  const fieldIsValid = (field: IDocumentsKey): boolean => !validations[field];

  const allFieldsIsValid = (): boolean => {
    const allFieldsHaveTrueValidationValue = Object.entries(validations).every(
      ([_, isValid]) => isValid,
    );

    return allFieldsHaveTrueValidationValue;
  };

  return {
    fieldIsValid,
    allFieldsIsValid,
  };
};
