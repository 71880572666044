import { useCallback, useEffect } from "react";

import api from "shared/infra/services/api";
import ErrorHandler from "shared/infra/services/errorHandler";

import { captureException, captureMessage } from "@sentry/nextjs";

const useApiInterceptor = () => {
  const handleApiInterceptor = useCallback(() => {
    try {
      if (api) {
        api.interceptors.response.use(
          (response) => response,
          (error) => {
            ErrorHandler(error);
            return Promise.reject(error);
          },
        );
      }
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in useEffect that calls ErrorHandler when intercepting requests to core-api.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, []);

  useEffect(() => {
    handleApiInterceptor();
  }, [handleApiInterceptor]);

  return {};
};

export default useApiInterceptor;
