import React from "react";

export const MessageError = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 2.33464V12.3346C0.5 13.2538 1.2475 14.0013 2.16667 14.0013H5.08333L8 17.3346L10.9167 14.0013H13.8333C14.7525 14.0013 15.5 13.2538 15.5 12.3346V2.33464C15.5 1.41547 14.7525 0.667969 13.8333 0.667969H2.16667C1.2475 0.667969 0.5 1.41547 0.5 2.33464ZM7.16667 3.16797H8.83333V8.16797H7.16667V3.16797ZM7.16667 9.83463H8.83333V11.5013H7.16667V9.83463Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};
