import { useRouter } from "next/router";

import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import ArrowLeft from "pages/[tenant]/g/assets/icons/ArrowLeft";

export const CustomGroupSectionLabel = () => {
  const { back } = useRouter();
  const { closeMobileSectionsDropdown } = useConsolePageContext();

  const handleClick = () => {
    closeMobileSectionsDropdown();
    back();
  };

  return (
    <ConsolePageForm.Button
      variant="transparent"
      onClick={handleClick}
      style={{ width: "100%", justifyContent: "start" }}
      id="group-config-return-page-button"
    >
      <ArrowLeft /> Configuração do grupo
    </ConsolePageForm.Button>
  );
};
