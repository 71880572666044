import styled from "styled-components";

import { AboutSideBarContainer } from "pages/[tenant]/g/[group_slug]/about/components/SideBar/styles";
import { BannerContainer } from "pages/[tenant]/g/components/Banner/styles";
import { CertificateWidgetsContainer } from "pages/[tenant]/g/components/CertificateWidgets/styles";
import { ListAllGroupsContainer } from "pages/[tenant]/g/components/ListAllGroups/styles";
import { SideBarContainer } from "pages/[tenant]/g/components/SideBar/styles";

export const GroupPageIndexContainer = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  background-color: ${({ theme }) => theme.groups.backgroundColor};

  .ref-topbar-background {
    opacity: 0;
  }

  ${BannerContainer} {
    height: 100%;
  }

  :not(:has(${BannerContainer})) {
    ${AboutSideBarContainer} {
      margin-top: 0;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 8rem;

    :has(${BannerContainer}) {
      padding-top: 0;
    }
  }

  @media (max-width: 850px) {
    margin-top: 3.4375rem;

    padding-top: 5rem;

    :has(${BannerContainer}) {
      padding-top: 0;
    }
  }
`;

interface ICoverProps {
  shouldReduceMargin?: boolean;
  isSetupGuide?: boolean;
}

export const Cover = styled.div<ICoverProps>`
  margin-top: ${({ shouldReduceMargin, isSetupGuide }) =>
    shouldReduceMargin ? "4rem" : isSetupGuide ? "12rem" : "6.9rem"};

  img {
    object-fit: cover;
  }
`;

export const FeedContainer = styled.div`
  max-width: 54rem;
  width: 100%;

  @media (max-width: 1199px) {
    flex-direction: column;
    margin-right: 0rem;
  }
`;

export const GroupContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;

  @media (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const GroupContent = styled.div`
  display: grid;
  grid-template-columns: 2fr minmax(auto, 21.875rem);

  :has(${SideBarContainer}) {
    grid-template-columns: minmax(auto, 21.875rem) 2fr minmax(auto, 21.875rem);
  }

  :has(${AboutSideBarContainer}) {
    grid-template-columns: 1fr 2fr 1fr;
  }

  ${FeedContainer} {
    max-width: 54rem;
  }

  justify-content: center;
  gap: 1.5rem;
  position: relative;
  width: 100%;
  max-width: 90.5rem; // 1.448px

  .right-sidebar-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  img {
    object-fit: cover;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 54rem;

    :has(${SideBarContainer}) {
      grid-template-columns: minmax(auto, 24rem) 2fr;
    }

    ${SideBarContainer} {
      grid-row: 1 / 3;
    }

    .right-sidebar-content:has(
        ${CertificateWidgetsContainer} ~ ${ListAllGroupsContainer}
      ) {
      grid-column: 2;
      grid-row: 1;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr !important;

    .right-sidebar-content:has(
        ${CertificateWidgetsContainer} ~ ${ListAllGroupsContainer}
      ) {
      margin-top: 1rem;
      order: -1;

      grid-column: 1;
    }
  }
`;

export const GlobalSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 70px;
  z-index: 99;

  background-color: #000000;
  padding: 10px;
  top: 63px;
`;
