import React, { InputHTMLAttributes, useEffect, useState } from "react";

import CheckboxArrow from "pages/[tenant]/console/assets/CheckboxArrow";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageCheckboxContainer } from "./styles";

interface IConsolePageCheckboxProps
  extends InputHTMLAttributes<HTMLButtonElement> {
  handleChange: (checked: boolean) => void;
  checked: boolean;
  label?: string;
  isInvalid?: boolean;
}

export const ConsolePageCheckbox = ({
  label,
  handleChange,
  isInvalid,
  checked,
  ...props
}: IConsolePageCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => setIsChecked(checked), [checked]);

  const handleCustomChange = () => {
    setIsChecked((prev) => !prev);
    handleChange(!isChecked);
  };

  const customId = removeSpacesAndAccentsFromString(
    label || "console-page-checkbox",
  );

  return (
    <ConsolePageCheckboxContainer
      isChecked={isChecked}
      isInvalid={isInvalid}
      {...props}
      onClick={handleCustomChange}
      type="button"
      data-testid="console-page-checkbox"
      id={customId}
    >
      <div className="arrow-container">
        <CheckboxArrow />
      </div>

      {!!label && <div className="label">{label}</div>}
    </ConsolePageCheckboxContainer>
  );
};
