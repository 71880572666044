import api from "shared/infra/services/api";
import getToken from "shared/infra/services/auth/getToken";

import { captureException } from "@sentry/nextjs";

const token = getToken();
const headers = {
  Authorization: token ? "Bearer " + token : null,
  "X-Requested-With": "XMLHttpRequest",
};

export default function useSearchCNPJ() {
  const getInformationCNPJ = async ({
    cnpj,
    setLoading,
    setCnpjData,
    setIsValidationCNPJ,
  }) => {
    setLoading(true);

    const cnpjFormatted = cnpj.replace(/[^\d]+/g, "");

    if (cnpjFormatted) {
      try {
        const response = await api.get(`search-cnpj/${cnpjFormatted}`, {
          headers,
        });

        setCnpjData(response.data);

        if (response?.data?.situacaoCadastral?.codigo == "2") {
          setCnpjData(response.data);
          setIsValidationCNPJ(true);
        } else {
          setIsValidationCNPJ(false);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 500) {
          setIsValidationCNPJ(false);
        } else {
          console.error("Erro useSearchCNPJ: ", error);
        }

        setIsValidationCNPJ(false);
        captureException(error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsValidationCNPJ(false);
      setLoading(false);
    }
  };

  return {
    getInformationCNPJ,
  };
}
