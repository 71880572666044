import Script from "next/script";

const ConsoleScripts = () => {
  function usetiful(w, d, s) {
    let a = d.getElementsByTagName("head")[0];
    let r = d.createElement("script");
    r.async = 1;
    r.src = s;
    r.setAttribute("id", "usetifulScript");
    r.dataset.token = "5ab942c24e77e7197160f89924457e66";
    a.appendChild(r);

    return null;
  }

  return (
    <>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=ebad473e-3f76-4102-b1a9-4813c68d1def"
      />

      {usetiful(window, document, "https://www.usetiful.com/dist/usetiful.js")}
    </>
  );
};

export default ConsoleScripts;
