import styled, { css } from "styled-components";

import { consolePrimaryColor } from "pages/[tenant]/console/styles/consoleGlobalColors";

interface IRadioContainerProps {
  radioGroupIsDisabled: boolean;
  withRadioCircle: boolean;
}

export const RadioContainer = styled.label<IRadioContainerProps>`
  display: block;
  width: fit-content;

  &,
  label:hover {
    cursor: pointer;
    user-select: none;
  }

  ${({ radioGroupIsDisabled }) =>
    radioGroupIsDisabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  :has(input:disabled) {
    &,
    input {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  :not(:has(input:disabled)) {
    &,
    input {
      cursor: pointer;
    }
  }

  ${({ withRadioCircle }) =>
    !withRadioCircle &&
    css`
      input[type="radio"] {
        display: none;
      }

      .element-container > :first-child {
        transition: 0.3s all;
      }

      :has(input[type="radio"]:checked) {
        .element-container > :first-child {
          box-shadow: 0px 0px 0px 2px ${consolePrimaryColor};
          color: ${consolePrimaryColor};
        }
      }
    `}

  input[type="radio"] {
    transition: 0.3s all;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid ${consolePrimaryColor};
    border-radius: 50%;
    outline: none;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    transition: 0.3s all;
  }

  input[type="radio"]:checked {
    :before {
      box-shadow: inset 0px 0px 0px 2px
        ${({ theme }) => (theme.mode === "dark" ? "black" : "white")};
      background-color: ${consolePrimaryColor};
    }
  }
`;
