export function handleSplitName(fullNameString: string): string {
  try {
    if (fullNameString) {
      let names = fullNameString?.split(" ");
      let initials = names[0]?.substring(0, 1)?.toUpperCase();

      if (names?.length > 1) {
        initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
      }

      return initials;
    }
  } catch (error) {
    console.log(error);
  }
}
