import React from "react";

import { IRadio } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { RadioContainer } from "./styles";

interface IRadioProps {
  radio: IRadio;
  radioGroupName: string;
  defaultSelectedValue: string;
  handleClickRadio: (...args: any) => void;
  radioGroupIsDisabled?: boolean;
  checked: boolean;
  withRadioCircle?: boolean;
}

export const Radio = ({
  radio,
  radioGroupName,
  defaultSelectedValue,
  handleClickRadio,
  radioGroupIsDisabled,
  checked,
  withRadioCircle,
}: IRadioProps) => {
  const isActive = defaultSelectedValue === radio.value;
  const className = isActive ? "active" : "";

  const handleCustomClick = (event) => {
    const optionIsDisabled = radio?.disabled;
    if (optionIsDisabled) return;

    handleClickRadio(event);
  };

  const customId = removeSpacesAndAccentsFromString(
    `${radioGroupName}-${radio.id}`,
  );

  return (
    <RadioContainer
      htmlFor={`${radioGroupName}-${radio.id}`}
      className={className}
      radioGroupIsDisabled={radioGroupIsDisabled}
      withRadioCircle={withRadioCircle}
      id={customId}
    >
      <input
        type="radio"
        name={radioGroupName}
        defaultChecked={isActive}
        id={`${radioGroupName}-${radio.id}`}
        onClick={handleCustomClick}
        data-radio-id={radio.id}
        disabled={radioGroupIsDisabled || radio?.disabled}
        data-testid="console-page-radio"
        checked={isActive || checked}
      />
      <div className="element-container">{radio.element}</div>
    </RadioContainer>
  );
};
