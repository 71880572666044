import styled from "styled-components";

export const ComponentErrorBoundaryContainer = styled.div`
  margin: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => (theme?.mode === "dark" ? "#f2f2f2" : "#050505")};
`;
