import styled, { css, keyframes } from "styled-components";

export const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const slideInSecondary = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const slideOutSecondary = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

interface ISideMenuContainerProps {
  show: boolean;
}

interface SecondarySideMenuContainerProps {
  show: boolean;
}

export const SideMenuContainer = styled.div`
  width: 21.875rem; // 350px
  height: 100vh;

  padding: 2.6rem 3.75rem 0;
  overflow: hidden;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
  }

  button {
    background-color: transparent;
    border: none;

    :hover {
      cursor: pointer;
    }

    path {
      stroke: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
    }
  }

  a {
    display: block;
    padding: 0.5rem 0;
    transition: 0.3s all;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: ${({ theme }) => theme.colors.globalTopbar.textColor};
    font: 600 1.25rem/1.875rem "Inter";

    :hover {
      color: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
    }
  }

  ul {
    list-style: none;
    margin: 0;
  }
`;

export const PrimarySideMenuContainer = styled(
  SideMenuContainer,
)<ISideMenuContainerProps>`
  background: ${({ theme }) => theme.colors.globalTopbar.primaryBackground};
  animation: ${({ show }) => (show ? slideIn : slideOut)} 0.3s ease-in-out;
  z-index: 2;

  .custom-button-cta {
    margin-top: 0.5rem;
  }

  .content-items {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    a {
      padding: 0;
    }
  }
`;

export const SecondarySideMenuContainer = styled(
  SideMenuContainer,
)<SecondarySideMenuContainerProps>`
  padding: 2.6rem 3.75rem;
  background: ${({ theme }) => theme.colors.globalTopbar.secondaryBackground};
  z-index: 1;

  animation: ${({ show }) => (show ? slideInSecondary : slideOutSecondary)} 0.6s
    0s forwards;
  position: fixed;
`;

export const Overlay = styled.div<{ show: boolean }>`
  position: absolute;
  background: #0000008a;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 98;

  display: flex;

  transition: 0.3s all;
  opacity: 0;
  pointer-events: none;

  overflow-y: scroll;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const Divider = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.globalTopbar.borderColor};
  margin: 1rem 0;
`;

export const SecondaryDivider = styled(Divider)`
  left: -130px;
  width: 200%;
`;

export const ContentItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 1rem 0;

  border-top: 1px solid ${({ theme }) => theme.colors.globalTopbar.borderColor};
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.globalTopbar.borderColor};

  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.globalTopbar.textColor};

  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    transform: scale(1.02);
    color: ${({ theme }) => theme.colors.globalTopbar.textHoverColor};
  }
`;
