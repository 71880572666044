import React, { useCallback, useState } from "react";

import { useRouter, useSearchParams } from "next/navigation";

import { ClosedBookOutline } from "pages/[tenant]/console/groups/[group_slug]/modules/assets/ClosedBookOutline";
import useModulesPageContext from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageContext";
import { IModuleLesson } from "pages/[tenant]/console/groups/[group_slug]/modules/types";

import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import DragAndDropIcon from "pages/[tenant]/g/assets/icons/DragAndDropIcon";

import CircularLoader from "shared/components/atoms/CircularLoader";
import { Draggable } from "shared/components/organisms/DragAndDrop";

import { LessonListIconContainer, LessonListItemContainer } from "./styles";

interface ILessonListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  lesson: IModuleLesson;
  isLessonlistVisible: boolean;
  isLoading: boolean;
  index: number;
}

const LessonListItem = ({
  lesson,
  isLessonlistVisible,
  isLoading,
  index,
  ...props
}: ILessonListItemProps) => {
  const {
    setLesson,
    clearLessonState,
    group,
    updateLessonLocally,
    setModulesInternalRoutes,
  } = useModulesPageContext();
  const { closeMobileInternalSectionsDropdown } = useConsolePageContext();

  const { push } = useRouter();

  const params = useSearchParams();
  const lesson_slug = params.get("lesson_slug");

  const isLessonSelected = lesson_slug === lesson?.slug;

  const [isHovering, setIsHovering] = useState(false);

  const onMouseLeave = () => setIsHovering(false);
  const onMouseEnter = () => setIsHovering(true);

  const constructPathToLesson = () =>
    `/console/groups/${group?.slug}/modules/${lesson?.group_topic_slug}/lesson-types/${lesson?.slug}`;

  const goToCreateALessonSection = useCallback(() => {
    closeMobileInternalSectionsDropdown();
    push(constructPathToLesson());
  }, [
    setModulesInternalRoutes,
    closeMobileInternalSectionsDropdown,
    push,
    constructPathToLesson,
  ]);

  const handleClick = useCallback(() => {
    clearLessonState();
    setLesson(lesson);
    goToCreateALessonSection();
  }, [
    clearLessonState,
    setLesson,
    updateLessonLocally,
    lesson,
    goToCreateALessonSection,
  ]);

  return (
    <Draggable draggableId={lesson?.id?.toString()} index={index}>
      <LessonListItemContainer
        {...props}
        isLessonlistVisible={isLessonlistVisible}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isLessonSelected={isLessonSelected}
      >
        <LessonListIconContainer isHovering={isHovering}>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <>
              <DragAndDropIcon className="drag-icon" />
              <ClosedBookOutline className="closed-book-icon" />
            </>
          )}
        </LessonListIconContainer>

        <span>{lesson?.title}</span>
      </LessonListItemContainer>
    </Draggable>
  );
};

export default LessonListItem;
