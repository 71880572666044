import React, { ChangeEvent, InputHTMLAttributes } from "react";

import { IConsolePageInputsProps } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageInputContainer } from "./styles";

export type IConsolePageInputExtendedProps =
  InputHTMLAttributes<HTMLInputElement> & IConsolePageInputsProps;

interface IConsolePageInputProps extends IConsolePageInputExtendedProps {
  useCustomId?: boolean;
}

export const ConsolePageInput = ({
  label,
  isInvalid,
  required,
  useCustomId,
  ...props
}: IConsolePageInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props?.onChange) props.onChange(event);
  };

  const customId = useCustomId
    ? removeSpacesAndAccentsFromString(label)
    : props?.id || "";

  return (
    <ConsolePageInputContainer
      shouldMoveFloatingLabel={!!props?.value}
      isInvalid={isInvalid}
    >
      <label>
        {label}
        {required && <span className="required-flag">*</span>}
      </label>
      <input
        onChange={handleChange}
        value={props?.value}
        required={required}
        {...props}
        autoComplete="off"
        data-testid="console-page-input"
        id={customId}
      />
    </ConsolePageInputContainer>
  );
};
