import { useRouter } from "next/router";

import moment from "moment";
import "moment/locale/pt";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import {
  ITopFlagContent,
  ITopFlagContentMapStatus,
} from "@Console/billing/types";

export type ITopFlagContentMap = {
  [key in ITopFlagContentMapStatus]: Pick<
    ITopFlagContent,
    "leftText" | "rightText" | "buttonOnClick"
  >;
};

moment.locale("pt");

export const useTopFlagTextsContent = () => {
  const {
    state: { tenancy },
  } = useGlobalContext();
  const { billing } = tenancy;
  const { push } = useRouter();

  const dayInString = moment(billing?.trial_ends_at).fromNow(true);

  const textsMap = {
    trial: {
      leftText: (
        <p>
          <b>Você está no período trial.</b> Alguma dúvida? Fale com nossa
          equipe, estamos prontos para te ajudar!
        </p>
      ),
      rightText: (
        <p>
          Restam <b>{dayInString}</b> até o fim do período trial
        </p>
      ),
      buttonOnClick: () => push("/console/billing"),
    },
    trialIsFinishing: {
      leftText: (
        <p>
          <b>Seu período trial está acabando.</b> Precisa de mais tempo para
          explorar? Ou está pronto para escolher um plano? Fale com nossa
          equipe!
        </p>
      ),
      rightText: (
        <p>
          Restam <b>{dayInString}</b> até o fim do período trial
        </p>
      ),
      buttonOnClick: () => push("/console/billing"),
    },
    trialIsFinished: {
      leftText: (
        <p>
          <b>Poxa, seu período trial acabou.</b> Continue aproveitando sua
          plataforma escolhendo um de nossos planos. Ainda tem dúvidas? Vamos
          conversar!
        </p>
      ),
      rightText: <p>Período trial encerrado.</p>,
      buttonOnClick: () => push("/console/billing"),
    },
    billingFailed: {
      leftText: (
        <p>
          <b>Não conseguimos realizar a cobrança da sua fatura.</b> Por favor,
          confira o método de pagamento selecionado.
        </p>
      ),
      rightText: <p>Aguardando pagamento.</p>,
      buttonOnClick: () => push("/console/billing"),
    },
    canceledService: {
      leftText: (
        <p>
          <b>Serviço interrompido.</b> Não conseguimos identificar o pagamento
          da sua fatura. Por favor, regularize sua situação para retomar o
          serviço.
        </p>
      ),
      rightText: <p>Plataforma bloqueada.</p>,
      buttonOnClick: () => push("/console/billing"),
    },
  };

  const getTextsFromBillingKey = (billingName: ITopFlagContentMapStatus) =>
    textsMap[billingName];

  return {
    getTextsFromBillingKey,
  };
};
