import styled from "styled-components";

export const ContainerEntityType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .cards-type-person {
    display: flex;
    gap: 1rem;

    @media (max-width: 550px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const ContainerAlertNaturalPerson = styled.div`
  background: #7c7009;
  padding: 0.75rem;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  gap: 0.75rem;
  span {
    color: #ffffff;
  }
`;

export const WrapperButtonsDocuments = styled.div`
  border-top: 1px solid ${({ theme }) => theme.myDocuments?.border};

  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  padding: 0.75rem 1.5rem 0;
`;
