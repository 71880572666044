import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthContext from "shared/providers/context/AuthContext";

import {
  ErrorsState,
  ICNPJData,
  IDocuments,
  IDocumentsContext,
  IDocumentsInitial,
} from "@Console/statement/types";

export const DocumentsContext = createContext<IDocumentsContext>(
  {} as IDocumentsContext,
);

export const DocumentsContextProvider = ({
  children,
}: PropsWithChildren<ReactNode>) => {
  const { loggedUser } = useContext(AuthContext);

  const isDocumentDisapproved =
    loggedUser?.personal_documents?.status === "disapproved";

  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalDocuments, setOpenModalDocuments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkboxStepCNPJ, setCheckboxStepCNPJ] = useState(false);
  const [hasImagesDocuments, setHasImagesDocuments] = useState(false);
  const [statusSendDocuments, setStatusSendDocuments] = useState("false");

  const [companyData, setCompanyData] = useState<IDocuments>({} as IDocuments);
  const [initialDataDocuments, setInitialDataDocuments] =
    useState<IDocumentsInitial>({} as IDocumentsInitial);

  const [personType, setPersonType] = useState("");
  const [errors, setErrors] = useState<ErrorsState>({} as ErrorsState);

  const [steps, setSteps] = useState(1);

  useEffect(() => {
    if (isDocumentDisapproved) {
      setSteps(0);
    } else {
      setSteps(1);
    }
  }, [isDocumentDisapproved]);

  const [cnpjData, setCnpjData] = useState({} as ICNPJData);

  const [isValidationCNPJ, setIsValidationCNPJ] = useState(false);
  const [buttonStepTwoCNPJ, setButtonStepTwoCNPJ] = useState(false);

  const [openWait, setOpenWait] = useState(false);

  return (
    <DocumentsContext.Provider
      value={{
        openModalSuccess,
        setOpenModalSuccess,
        openModalDocuments,
        setOpenModalDocuments,
        companyData,
        setCompanyData,
        personType,
        setPersonType,
        errors,
        setErrors,
        steps,
        setSteps,
        isValidationCNPJ,
        setIsValidationCNPJ,
        loading,
        setLoading,
        checkboxStepCNPJ,
        setCheckboxStepCNPJ,
        hasImagesDocuments,
        setHasImagesDocuments,

        cnpjData,
        setCnpjData,

        buttonStepTwoCNPJ,
        setButtonStepTwoCNPJ,

        openWait,
        setOpenWait,

        isDocumentDisapproved,

        initialDataDocuments,
        setInitialDataDocuments,

        statusSendDocuments,
        setStatusSendDocuments,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
