import axios from "axios";

export const useAddress = () => {
  const getAddressBrazilAPIRequest = async (zipcode: string) => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${zipcode}/json/`,
      );

      const { cep, street, state, neighborhood, city } = response.data;

      if (!cep || !street || !state || !neighborhood || !city)
        return {
          error: "Dados do endereço incompletos",
          ...response.data,
        };

      return response.data;
    } catch (error) {
      console.log("error -->", error);
    }
    return { error: "CEP não encontrado na base do ViaCEP" };
  };

  return {
    getAddressBrazilAPIRequest,
  };
};
