import { useCallback, useEffect } from "react";

import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import { captureException } from "@sentry/nextjs";

const ensinioOldFeaturesPages = [
  "/dashboard",
  "/courses",
  "/media",
  "/social",
  "/blog",
  "/usersCommunity",
  "/bundles",
  "/plans",
  "/collections",
  "/categories",
];

interface IUseNewFeaturesAvailable {
  tenancy: {
    settings: {
      site: { ensinioNewFeaturesEnable: string[] };
      others: { ensinioNewFeaturesEnable: string[] };
    };
  };
}
const useNewFeaturesAvailable = ({ tenancy }: IUseNewFeaturesAvailable) => {
  const router = useRouter();
  const pathname = usePathname();

  const handleIfNewFeaturesAreAvailable = useCallback(() => {
    try {
      if (!tenancy) return;

      const matchedPages = ensinioOldFeaturesPages.filter(
        (page) => pathname.includes(page) && !pathname.includes("/g/"),
      );
      const siteSettings = tenancy?.settings?.site;
      const otherSettings = tenancy?.settings?.others;

      const newFeatsIsEnable =
        siteSettings?.ensinioNewFeaturesEnable ||
        otherSettings?.ensinioNewFeaturesEnable;
      if (Number(newFeatsIsEnable) === 1 && matchedPages.length > 0) {
        router.push("/browse");
      }
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in useEffect that redirects pages from 1.0 to 2.0.";

      console.log(errorMessage, error);
      captureException(error);
      captureException(errorMessage);
    }
  }, [pathname, router, tenancy]);

  useEffect(() => {
    handleIfNewFeaturesAreAvailable();
  }, [handleIfNewFeaturesAreAvailable]);

  return {};
};

export default useNewFeaturesAvailable;
