import styled from "styled-components";

export const ContainerSendDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .cards-type-person {
    display: flex;
    gap: 1rem;

    @media (max-width: 550px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;
