import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import {
  saveDestinationInSessionStorage,
  savePreviousURLInSessionStorage,
} from "shared/utils/handleURLInSessionStorage";

import { captureException } from "@sentry/nextjs";

const useNextLoadingProgress = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const handleStart = (url: string) => {
        savePreviousURLInSessionStorage(router?.asPath);
        saveDestinationInSessionStorage(url);
        return url !== router?.asPath && setLoading(true);
      };
      const handleComplete = () => setLoading(false);

      router?.events?.on("routeChangeStart", handleStart);
      router?.events?.on("routeChangeComplete", handleComplete);
      router?.events?.on("routeChangeError", handleComplete);

      return () => {
        router?.events?.off("routeChangeStart", handleStart);
        router?.events?.off("routeChangeComplete", handleComplete);
        router?.events?.off("routeChangeError", handleComplete);
      };
    } catch (error) {
      console.log("[useNextLoading]: an error occurred on useNextLoading.");
      captureException(error);
    }
  });
  return loading;
};

export default useNextLoadingProgress;
