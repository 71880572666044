import styled from "styled-components";

export const ContainerCnpjIdentification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .checkbox-aware {
    display: flex;
    gap: 0.75rem;

    span {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${({ theme }) => theme?.myDocuments?.primaryDescription};
    }
  }
`;

export const ContainerAlertData = styled.div`
  background: #7c7009;
  padding: 0.75rem;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  gap: 0.75rem;
  span {
    color: #ffffff;
  }
`;

export const ContentCnpjIdentification = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  grid-gap: 1px;
  border: 1px solid ${({ theme }) => theme?.console?.inputBorderColor};

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
