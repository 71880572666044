/* eslint-disable no-param-reassign */
function hexToRgb(hex) {
  if (hex.includes("rgb")) return hex;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function luminanace(r, g, b) {
  const a = [r, g, b]?.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : (v + 0.055) / 1.055 ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(background, text) {
  const background_lum = luminanace(
    background?.r,
    background?.g,
    background?.b,
  );
  const text_lum = luminanace(text?.r, text?.g, text?.b);

  const brightest = Math?.max(background_lum, text_lum);
  const darkest = Math?.min(background_lum, text_lum);

  const contrast = (brightest + 0.05) / (darkest + 0.05);
  return { contrast, background_lum, text_lum };
}

export default function contrastAutoAdjust({ background, text }) {
  const results =
    background && text && contrast(hexToRgb(background), hexToRgb(text));

  if (results?.contrast < 5.5) {
    if (results?.background_lum <= 0.5) {
      if (results?.text_lum > 0.5) {
        return text;
      }
      return "#f1f1f1";
    }

    return "#181818";
  }

  return text || "";
}
