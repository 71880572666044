import { Component, ReactNode } from "react";

export interface IErrorBoundaryState {
  hasError: boolean;
  fallback?: ReactNode;
  error?: Error;
}

interface IErrorBoundaryProps {
  children: ReactNode;
}

export class IPageErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {}
