import React, { InputHTMLAttributes, useState } from "react";

import { EyeIcon } from "pages/[tenant]/console/assets/EyeIcon";
import { EyeOffIcon } from "pages/[tenant]/console/assets/EyeOffIcon";
import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";
import { IConsolePageInputsProps } from "pages/[tenant]/console/types";

import { ConsolePagePasswordInputContainer } from "./styles";

type IConsolePagePasswordInputProps = IConsolePageInputsProps &
  InputHTMLAttributes<HTMLInputElement>;

export const ConsolePagePasswordInput = ({
  ...props
}: IConsolePagePasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordView = () => {
    const inputIsDisabled = props?.disabled;
    if (inputIsDisabled) return;

    setShowPassword((prev) => !prev);
  };

  return (
    <ConsolePagePasswordInputContainer>
      <ConsolePageForm.Input
        {...props}
        type={showPassword ? "text" : "password"}
      />

      <button
        onClick={handleTogglePasswordView}
        type="button"
        title={showPassword ? "Esconder senha" : "Mostrar senha"}
        data-testid="console-page-password-input-toggle"
        disabled={props?.disabled}
      >
        {showPassword ? <EyeOffIcon /> : <EyeIcon />}
      </button>
    </ConsolePagePasswordInputContainer>
  );
};
