import { useState } from "react";

import { DropResult } from "react-beautiful-dnd";

import api from "shared/infra/services/api";

import * as Sentry from "@sentry/nextjs";

import { IModuleLesson } from "../types";
import useModulesPageContext from "./useModulesPageContext";

const useLessonsDragAndDrop = ({ module }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { modules, setModules } = useModulesPageContext();

  const onLessonDragEnd = async (result: DropResult) => {
    try {
      const { source, destination } = result;
      if (!destination) return null;

      const [newOrder] = module?.lessons.splice(source.index, 1);
      module?.lessons.splice(destination.index, 0, newOrder);

      setModules(appendLessonOnModule({ lessons: module?.lessons }));
      await onDragSuccess({ newLessons: module?.lessons });
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (onLessonDragEnd): ${error}`);
    }
  };

  const onDragSuccess = async ({ newLessons }) => {
    try {
      const lessonIds = newLessons.map((lesson: IModuleLesson) => lesson?.id);
      module?.lessons.map((lesson, index) => ({ ...lesson, position: index }));

      await onLessonDragEndSuccess(lessonIds);
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (onDragSuccess): ${error}`);
    }
  };

  const onLessonDragEndSuccess = async (lessons_ids: number[]) => {
    const abortController = new AbortController();
    try {
      await api.patch(
        `group-lessons/reorder/${module?.id}`,
        {
          lessons_ids,
        },
        {
          signal: abortController.signal,
        },
      );
    } catch (error) {
      Sentry.captureException(error);
      abortController.abort();
      console.log(`Error (onLessonDragEndSuccess): ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const appendLessonOnModule = ({ lessons }) => {
    return modules.map((moduleItem) =>
      moduleItem?.id !== module?.id ? moduleItem : { ...moduleItem, lessons },
    );
  };

  return { isLoading, onLessonDragEnd };
};

export default useLessonsDragAndDrop;
