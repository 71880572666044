import React from "react";

export const Wallet = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5.25V3.75C15 2.92275 14.3273 2.25 13.5 2.25H3.75C2.5095 2.25 1.5 3.2595 1.5 4.5V13.5C1.5 15.1508 2.8455 15.75 3.75 15.75H15C15.8273 15.75 16.5 15.0773 16.5 14.25V6.75C16.5 5.92275 15.8273 5.25 15 5.25ZM13.5 12H12V9H13.5V12ZM3.75 5.25C3.55689 5.24136 3.37455 5.15857 3.24096 5.01886C3.10736 4.87916 3.0328 4.6933 3.0328 4.5C3.0328 4.3067 3.10736 4.12084 3.24096 3.98114C3.37455 3.84143 3.55689 3.75864 3.75 3.75H13.5V5.25H3.75Z"
        fill="white"
      />
    </svg>
  );
};
