import { ModulesPageContextProvider } from "@Console/groups/[group_slug]/modules/context/ModulesPageContext";
import { CollectionProvider } from "modules/Collection/context";
import { GlobalSearchProvider } from "modules/GlobalSearch/context";
import { OrdersProvider } from "modules/Order/context";

import GroupContextProvider from "pages/[tenant]/g/context/GroupsContext";

import { MenusContextProvider } from "shared/providers/context/MenusContext";
import { NewGlobalSearchContextProvider } from "shared/providers/context/NewGlobalSearchContext";

import { ConsolePageContextProvider } from "@Console/context/ConsolePage";
import { DocumentsContextProvider } from "@Console/statement/context/DocumentsContext";

import { globalComposeProviders } from "./GlobalComposeProviders";

export type TProviders = typeof providers;

const providers = [
  [CollectionProvider],
  [OrdersProvider],
  [ModulesPageContextProvider],
  [ConsolePageContextProvider],
  [DocumentsContextProvider],
  [GlobalSearchProvider],
  [GroupContextProvider],
  [NewGlobalSearchContextProvider],
  [MenusContextProvider],
];

export const AppProvider = globalComposeProviders(providers);
