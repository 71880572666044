import { useEffect, useState } from "react";

import { SelectArrow } from "pages/[tenant]/console/assets/SelectArrow";
import { ISelectOption } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { Option } from "./Option";
import {
  ConsolePageSelectContainer,
  ConsolePageSelectContainerOptions,
  Select,
} from "./styles";

interface IConsolePageSelectProps {
  options: ISelectOption[];
  defaultLabel: string;
  defaultValue?: string;
  handleChange: (selectedOption: any) => void;
  isInvalid?: boolean;
  disabled?: boolean;
}

export const ConsolePageSelect = ({
  options,
  defaultLabel,
  defaultValue,
  handleChange,
  isInvalid,
  disabled,
}: IConsolePageSelectProps) => {
  const [optionsIsVisible, setOptionsIsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => setSelectedOption(defaultValue), [defaultValue]);

  const handleToggleOptions = () => {
    const selectIsDisabled = disabled;
    if (selectIsDisabled) return;

    setOptionsIsVisible((prev) => !prev);
  };

  const handleSelectOption = ({ target }: any) => {
    const optionId = +(target as HTMLLIElement).getAttribute("data-id");
    const findedOption = options.find((option) => option.id === optionId);

    handleChange(findedOption);
    setOptionsIsVisible(false);
    setSelectedOption(findedOption.label);
  };

  const handleCloseOptions = () => setOptionsIsVisible(false);

  useEffect(() => {
    if (optionsIsVisible)
      window.addEventListener("click", handleCloseOptions, true);
    else window.removeEventListener("click", handleCloseOptions, true);

    return () => window.removeEventListener("click", handleCloseOptions, true);
  }, [optionsIsVisible]);

  const customId = removeSpacesAndAccentsFromString(defaultLabel);

  return (
    <ConsolePageSelectContainer optionsIsVisible={optionsIsVisible}>
      <Select
        onClick={handleToggleOptions}
        type="button"
        isInvalid={isInvalid}
        disabled={disabled}
        data-testid="console-page-select"
        id={customId}
      >
        <div className="values-wrapper">
          <div className="label">{defaultLabel}</div>
          <div className="value">{selectedOption}</div>
        </div>
        <SelectArrow />
      </Select>

      <ConsolePageSelectContainerOptions
        optionsIsVisible={optionsIsVisible}
        data-testid="console-page-select-options"
      >
        {options.map((option) => (
          <Option
            key={option.id}
            onClick={handleSelectOption}
            option={option}
            isActive={option.label === selectedOption}
          />
        ))}
      </ConsolePageSelectContainerOptions>
    </ConsolePageSelectContainer>
  );
};
