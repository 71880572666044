import { IPageLog } from "shared/types/monitoringInfiniteReloadingTypes";
import {
  getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance,
  maxTimesThatPageCanReloadBetweenTolerance,
  sendTimeOfPageReloadedToSlack,
  setInSessionStorageAlreadySendLogToSlack,
  timeInMilisecondsOfTolerance,
} from "shared/utils/monitoringInfiniteReloadingUtils";

import { captureException, captureMessage } from "@sentry/nextjs";

export const handleSendLogToSlack = async (
  pageLogsFromSessionStorage: IPageLog,
) => {
  try {
    const timeOfEachPageRealodedBetweenMilisecondsOfTolerance =
      getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance({
        pageLogs: pageLogsFromSessionStorage,
        timeOfTolerance: timeInMilisecondsOfTolerance,
      });

    if (!timeOfEachPageRealodedBetweenMilisecondsOfTolerance) return;

    Object.entries(
      timeOfEachPageRealodedBetweenMilisecondsOfTolerance,
    )?.forEach(async ([path, timesOfPageReloaded]) => {
      const pageReloadedMoreTimesThanTolerance =
        timesOfPageReloaded >= maxTimesThatPageCanReloadBetweenTolerance;
      if (!pageReloadedMoreTimesThanTolerance) return;

      const logWasSendedToSlack = await sendTimeOfPageReloadedToSlack({
        path,
      });

      if (logWasSendedToSlack) setInSessionStorageAlreadySendLogToSlack();
    });
  } catch (error) {
    console.log("Error (handleSendLogToSlack): ", error);
    captureException(error);
    captureMessage("Error (handleSendLogToSlack)");
  }
};
