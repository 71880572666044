import { AnalyticsSalesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/AnalyticsSalesIcon";
import { CertificatesIssuedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/CertificatesIssuedIcon";
import { ConcludedMissionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ConcludedMissionsIcon";
import { EngagementIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/EngagementIcon";
import { ExportsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ExportsIcon";
import { LearningIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/LearningIcon";
import { ProgressIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/ProgressIcon";
import { QuizzAnswersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/QuizzAnswersIcon";
import { UsersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/UsersIcon";
import { IOption, IOptionsHook } from "pages/[tenant]/console/types";

export const useAnalyticsOptions = (): IOptionsHook => {
  const sectionLabel = "Relatórios";
  const analytics: IOption[] = [
    {
      id: 1,
      label: "Progresso de cohorts",
      path: "/console/progress",
      icon: <ProgressIcon />,
      shouldHide: true,
    },
    {
      id: 2,
      label: "Usuários",
      path: "/console/dashboards/users",
      icon: <UsersIcon />,
      shouldHide: true,
    },
    {
      id: 3,
      label: "Engajamento",
      path: "/console/dashboards/engagement",
      icon: <EngagementIcon />,
      shouldHide: true,
    },
    {
      id: 4,
      label: "Vendas",
      path: "/console/dashboards/sales",
      icon: <AnalyticsSalesIcon />,
      shouldHide: true,
    },
    {
      id: 5,
      label: "Aproveitamento",
      path: "/console/dashboards/learning",
      icon: <LearningIcon />,
      shouldHide: true,
    },
    {
      id: 6,
      label: "Quizzes",
      path: "/console/quizzes",
      icon: <QuizzAnswersIcon />,
      shouldHide: true,
    },
    {
      id: 7,
      label: "Emissão de certificados",
      path: "/console/certificates",
      icon: <CertificatesIssuedIcon />,
      shouldHide: true,
    },
    {
      id: 8,
      label: "Missões",
      path: "/console/missions",
      icon: <ConcludedMissionsIcon />,
      shouldHide: true,
    },
    {
      id: 9,
      label: "Dataset export",
      description:
        "Exporte conjuntos inteiros de dados para elaboração de relatórios ou análise de dados em ferramentas externas.",
      path: "/console/reports",
      icon: <ExportsIcon />,
    },

    {
      id: 10,
      label: "Dash. aproveitamento",
      description:
        "Veja a performance dos seus usuários nas aulas e conteúdos da sua plataforma por grupo ou módulo específico.",
      path: "/console/performance",
      icon: <ExportsIcon />,
    },
  ];

  return {
    options: analytics,
    label: sectionLabel,
  };
};
