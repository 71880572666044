import { detect } from "detect-browser";

import { captureException, captureMessage } from "@sentry/nextjs";

export const getBrowserInfo = () => {
  try {
    const browser = detect();
    return `${browser?.name} v${browser?.version}`;
  } catch (error) {
    console.log("Erro (getBrowserInfo): ", error);
    captureException(error);
    captureMessage("Erro (getBrowserInfo)");
    return "";
  }
};
