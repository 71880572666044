import styled from "styled-components";

export const PageErrorBoundaryContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 25px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 2.25rem;
    margin-bottom: 35px;
  }

  p {
    font-size: 1.125rem;
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
  }

  button {
    padding: 0.5rem 1rem;
    border-radius: 4px;

    font: 600 0.75rem/1.125rem "Inter";

    text-align: center;

    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    opacity: 0.9;

    transition: 0.3s all;

    white-space: nowrap;
    background-color: #605cff;
    color: #fafafa;

    :hover {
      cursor: pointer;
    }
  }
`;
