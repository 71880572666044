import { useState } from "react";

import { useRouter, useSearchParams } from "next/navigation";

import { message } from "antd";

import { IGroup } from "pages/[tenant]/g/@types";

import api from "shared/infra/services/api";

import * as Sentry from "@sentry/nextjs";

import { IModule } from "../types";
import useModulesPageContext from "./useModulesPageContext";

const useModulesPageService = () => {
  const {
    modules,
    setGroup,
    setModules,
    setModule,
    module,
    group,
    clearModuleState,
    clearModulesState,
  } = useModulesPageContext();

  const { push } = useRouter();
  const params = useSearchParams();

  const group_slug = params.get("group_slug");
  const module_slug = params.get("module_slug");

  const [isGroupLoading, setIsGroupLoading] = useState(false);

  const [modulesPage, setModulesPage] = useState(1);
  const [isModulesLastPage, setIsModulesLastPage] = useState(false);
  const [isModulesLoading, setIsModulesLoading] = useState(false);

  function resetModulesHandler() {
    setModulesPage(1);
    setIsModulesLastPage(false);
    clearModulesState();
  }

  function moldeModuleCreation(): FormData {
    if (module) {
      const { title, position, group_id, active, cover_image } = module;
      const formData = new FormData();

      const fields = {
        title: title,
        position: position,
        active: Number(active) || 0,
        group_id: group_id,
      };

      if (cover_image instanceof File) Object.assign(fields, { cover_image });

      for (let field in fields) formData.append(field, fields[field]);

      return formData;
    }
  }

  async function getGroup(controller: AbortController) {
    try {
      setIsGroupLoading(true);
      const response = await api.get(`groups/${group_slug}`, {
        signal: controller.signal,
      });
      const groupFromResponse: IGroup = response.data.data;
      setGroup(groupFromResponse);
    } catch (error) {
      if (error?.response?.data?.message === "Group not found!")
        push("/console/groups");

      controller.abort();
      Sentry.captureException(error);
      console.log(`Error (getGroup): ${error}`);
    } finally {
      setIsGroupLoading(false);
    }
  }

  async function getModuleDetails(controller: AbortController) {
    try {
      setIsGroupLoading(true);
      const response = await api.get(`group-topics/${module_slug}`, {
        signal: controller.signal,
      });

      const moduleFromResponse: IModule = response.data.data;
      setModule(moduleFromResponse);
    } catch (error) {
      if (error?.response?.data?.message === "Group topic not found!")
        push("/console/groups");
      controller.abort();
      Sentry.captureException(error);
      console.log(`Error (getModuleDetails): ${error}`);
    } finally {
      setIsGroupLoading(false);
    }
  }

  async function getModulesObserver() {
    try {
      setIsModulesLoading(true);
      const response = await api.get(
        `group-topics/${group?.id}/all?perPage=25&page=${modulesPage}&withInactive=true&withLessons=true`,
      );
      const lastPage: number = response.data.meta.last_page;
      setIsModulesLastPage(() => modulesPage >= lastPage);
      setModulesPage((page) => page + 1);
      setModules(modules.concat(response.data.data));
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (getModulesObserver): ${error}`);
    } finally {
      setIsModulesLoading(false);
    }
  }

  async function updateModule() {
    try {
      setIsModulesLoading(true);

      const response = await api.post(
        `group-topics/${module?.slug}`,
        moldeModuleCreation(),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const moduleUpdated: IModule = response.data.data;

      moduleUpdated.lessons = module.lessons;

      setModule({
        ...module,
        cover_image_src: module.cover_image_src,
        slug: moduleUpdated.slug,
      });

      setModules(
        modules.map((module) =>
          module.id === moduleUpdated.id ? moduleUpdated : module,
        ),
      );
      message.success(`Módulo ${moduleUpdated?.title} atualizado.`);
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (updateModule): ${error}`);
    } finally {
      setIsModulesLoading(false);
    }
  }

  async function createModule() {
    try {
      setIsModulesLoading(true);

      const response = await api.post(`group-topics`, moldeModuleCreation(), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const moduleUpdated: IModule = response.data.data;
      setModule({
        ...module,
        cover_image_src: module.cover_image_src,
        slug: moduleUpdated?.slug,
        created_at: moduleUpdated?.created_at,
      });
      setModules(modules.concat(moduleUpdated));
      message.success(`Módulo ${moduleUpdated?.title} atualizado.`);
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (createModule): ${error}`);
    } finally {
      setIsModulesLoading(false);
    }
  }

  async function removeModule(module: IModule) {
    try {
      setIsModulesLoading(true);

      await api.delete(`group-topics/${module?.slug}`);

      setModules(modules.filter((moduleItem) => moduleItem?.id !== module.id));
      message.success(`Módulo ${module?.title} removido.`);
      clearModuleState();
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Error (removeModule): ${error}`);
    } finally {
      setIsModulesLoading(false);
    }
  }

  return {
    getGroup,
    updateModule,
    createModule,
    removeModule,

    getModulesObserver,
    getModuleDetails,
    resetModulesHandler,

    isGroupLoading,
    isModulesLastPage,
    isModulesLoading,
  };
};

export default useModulesPageService;
