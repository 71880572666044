import styled from "styled-components";

export const ContainerCompanyData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .address-company {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .legal-person-identification {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
