import styled, { css } from "styled-components";

import { consolePageMobileBreakpoint } from "pages/[tenant]/console/hooks/useConsolePageConfig";

interface ISectionOptionsDropdownButtonProps {
  shouldRotateIcon: boolean;
}

export const SectionOptionsDropdownButton = styled.button<ISectionOptionsDropdownButtonProps>`
  @media (min-width: ${consolePageMobileBreakpoint}px) {
    display: none;
  }

  align-items: center;

  white-space: nowrap;

  background-color: ${({ theme }) => theme.console.backgroundColor};

  display: flex;
  gap: 0.75rem;
  justify-content: start;

  padding: 0.75rem;

  width: 100%;

  position: sticky;
  z-index: 11;

  border: none;

  :hover {
    cursor: pointer;
  }

  &,
  .notification-number {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    font: 600 0.8125rem/1.21875rem "Inter";
  }

  .notification-number {
    text-align: end;
    width: 100%;
  }

  &,
  svg {
    transition: 0.3s all;
  }

  ${({ shouldRotateIcon }) =>
    shouldRotateIcon &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const OptionsContent = styled.div`
  position: sticky;
  top: 0;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    background-color: ${({ theme }) => theme.console.backgroundColor};

    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

    left: 0;
    position: fixed;
    overflow: scroll;
    height: 100vh;

    padding: 92px 0.75rem 4rem 0.75rem;
    width: 100%;

    transition: 0.3s all;
    transform: translateY(-100%);
  }
`;

interface IOptionsContainerProps {
  showMobileDropdown: boolean;
}

export const OptionsContainer = styled.div<IOptionsContainerProps>`
  transition: all 0.3s ease-in-out;

  grid-row: 1 / 3;

  padding: 0.8rem 0.75rem;
  padding-top: 0;

  position: relative;

  border-right: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

  background-color: ${({ theme }) => theme.console.sidebar.backgroundColor};

  min-width: 230px;

  z-index: 999999;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    position: fixed;
    width: 100%;
    top: 56px;
    padding: 0;

    border-bottom: 1px solid ${({ theme }) => theme.console.sidebar.borderColor};

    ${({ showMobileDropdown }) =>
      showMobileDropdown &&
      css`
        ${OptionsContent} {
          transform: translateY(0%);
        }
      `}
  }

  .title {
    color: ${({ theme }) => theme.console.sidebar.titleColor};
    font: 600 1rem/1.5rem "Inter";
    text-align: center;

    height: 3.125rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  ul {
    margin: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 0.13rem;
  }

  a {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    font: 600 0.8125rem/1.21875rem "Inter";
    border-radius: 4px;

    display: block;

    padding: 0.5rem;

    white-space: nowrap;

    position: relative;
    z-index: 10;

    transition: all 0.3s ease-in-out;

    display: flex;
    align-items: center;
    gap: 0.75rem;

    :hover:not(.active) {
      background-color: ${({ theme }) =>
        theme.console.sidebar.optionHoverBackgroundColor} !important;
      cursor: pointer;
      color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    }

    &.active {
      color: #f2f2f2;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .notification-number {
      flex: 1;
      text-align: end;
    }
  }

  li[data-disabled="true"] {
    opacity: 0.6;

    a {
      &.active {
        background-color: #605cff !important;
        color: #f2f2f2;
      }

      :hover {
        background-color: transparent !important;
        cursor: not-allowed;
      }
    }
  }
`;
