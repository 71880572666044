import { isInLocalhost } from "shared/utils/isInLocalhost";

import { captureException, captureMessage } from "@sentry/nextjs";

const sendLogToSlack = async (logText, customEndpoint = null) => {
  try {
    if (isInLocalhost()) return;

    const payload = { text: logText };

    await fetch(
      customEndpoint ||
        "https://hooks.slack.com/services/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",
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
    );
  } catch (error) {
    console.log("Erro (sendLogToSlack): ", error);
    captureException(error);
    captureMessage("Erro (sendLogToSlack)");
  }
};

export default sendLogToSlack;
