import styled, { css } from "styled-components";

interface ILogoContainerProps {
  shouldPositionLogoInCenterOfScreen: boolean;
}

export const LogoContainer = styled.div<ILogoContainerProps>`
  ${({ shouldPositionLogoInCenterOfScreen }) =>
    shouldPositionLogoInCenterOfScreen &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    `}

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.colors.globalTopbar.textColor};
  }

  a {
    display: block;
  }

  img {
    object-fit: contain;
    max-height: 38px;
    max-width: 300px;

    width: fit-content;
  }
`;
