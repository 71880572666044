import { useEffect, useMemo, useState } from "react";

import { IMenu } from "pages/[tenant]/cp/@types";
import { orderMenusByPosition } from "pages/[tenant]/cp/utils/orderMenusByPosition";

import { ComponentErrorBoundary } from "shared/components/atoms/ComponentErrorBoundary";
import { OptionArrowIcon } from "shared/providers/Layout/components/NewTopBar/assets/OptionArrowIcon";
import { MenuItem } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenuItem";
import { filterMenulistByActiveItem } from "shared/providers/Layout/utils/filterMenuListByActiveItem";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";

import { captureException } from "@sentry/nextjs";

import { useMenusContext } from "../../../hooks/useMenusContext";
import { MenusListContainer } from "./styles";

interface IMenusListProps {
  triggerOnLinkClick?: () => void;
  rightArrowIcon?: boolean;
  shouldLimitMenuItensLength?: boolean;
}

export const MenusList = ({
  triggerOnLinkClick = () => null,
  rightArrowIcon,
  shouldLimitMenuItensLength = false,
}: IMenusListProps) => {
  const appModeEnable = sessionStorage.getItem("appModeEnable");
  const isAppModeEnable = appModeEnable === "1";

  const { menus } = useMenusContext();
  const { loggedUser } = useAuthContext();
  const { fetchMenus } = useMenusContext();
  const [hasError, setHasError] = useState(false);
  const menuItens = useMemo<IMenu[] | null>(() => {
    if (!menus?.main) return null;

    const ordenedMenus = [...menus?.main];
    const onlyActiveItems = filterMenulistByActiveItem(ordenedMenus);

    const menuWithLimitedLenght = onlyActiveItems?.slice(0, 5);

    return shouldLimitMenuItensLength ? menuWithLimitedLenght : onlyActiveItems;
  }, [loggedUser, menus]);

  function isPurchaseLink(url) {
    return String(url)?.includes("allphome.com.br");
  }

  function removePurchaseLinkForMobileApp(itens) {
    if (!isAppModeEnable) return itens;

    const itensAllowedToRenderInMobileApp = itens.filter(
      (item) => !isPurchaseLink(item?.static_url),
    );

    return itensAllowedToRenderInMobileApp;
  }

  useEffect(() => {
    try {
      const initialFetch = async () => {
        const response = await fetchMenus();
        if (typeof response === "number") return setHasError(true);

        const ordenedMenus = orderMenusByPosition(response)?.main;
        const onlyActiveItems = filterMenulistByActiveItem(ordenedMenus);

        const menuWithLimitedLenght = onlyActiveItems?.slice(0, 5);

        return shouldLimitMenuItensLength
          ? menuWithLimitedLenght
          : onlyActiveItems;
      };

      initialFetch();
    } catch (error) {
      console.log(
        "[NewTopBar/MenusList]: an error occurred in the useEffect that calls 'initialFetch'.",
      );
      captureException(error);
    }
  }, [loggedUser, menus]);

  if (!menuItens?.length || hasError) return null;
  return (
    <MenusListContainer>
      {removePurchaseLinkForMobileApp(menuItens)?.map((item) => (
        <ComponentErrorBoundary key={item?.id}>
          <li onClick={triggerOnLinkClick}>
            <MenuItem item={item}>
              {rightArrowIcon && <OptionArrowIcon />}
            </MenuItem>
          </li>
        </ComponentErrorBoundary>
      ))}
    </MenusListContainer>
  );
};
