import React from "react";

export const Alert = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="6" fill="#050505" />
      <path
        d="M9.96018 1.66797C5.38685 1.66797 1.66602 5.4063 1.66602 10.0013C1.66602 14.5963 5.40435 18.3346 9.99935 18.3346C14.5943 18.3346 18.3327 14.5963 18.3327 10.0013C18.3327 5.4063 14.5768 1.66797 9.96018 1.66797ZM10.8327 14.168H9.16602V12.5013H10.8327V14.168ZM10.8327 10.8346H9.16602V5.83464H10.8327V10.8346Z"
        fill="#FFE600"
      />
    </svg>
  );
};
