import styled from "styled-components";

export const ContainerTopBarCheckout = styled.div`
  width: 100%;

  height: 4rem;
  margin: 0 auto;

  padding: 0 1rem;

  background: ${({ theme }) => theme.colors.globalTopbar.solidBackground};

  display: flex;

  align-items: center;

  justify-content: flex-end;

  .image-container {
    display: flex;

    left: 50%;
    transform: translate(-50%);
    position: absolute;
  }

  img {
    max-height: 38px;
    max-width: 300px;
  }
`;
