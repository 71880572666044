import React, { PropsWithChildren, ReactNode, useEffect } from "react";

import { useRouter } from "next/navigation";

import { DisabledPage } from "pages/[tenant]/console/components/templates/DisabledPage";
import { useLayoutOptions } from "pages/[tenant]/console/hooks/useLayoutOptions";

export const ConsoleLayout = ({ children }: PropsWithChildren<ReactNode>) => {
  const {
    pageIsDisabled,
    isNotInUserPlan,
    pageIsHidden,
    TemplateFeatureNotInPlan,
  } = useLayoutOptions();
  const { push } = useRouter();

  useEffect(() => {
    if (pageIsHidden) {
      console.log("Página desativada =/");
      push("/console");
    }
  }, [pageIsHidden]);

  if (pageIsDisabled) return <DisabledPage />;
  if (isNotInUserPlan) return <TemplateFeatureNotInPlan />;

  return <>{children}</>;
};
