
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ReactElement, ReactNode, useState } from "react";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import App from "next/app";
import Script from "next/script";

import "antd/dist/antd.css";
import "intl-pluralrules";

import CircularLoader from "shared/components/atoms/CircularLoader";
import useApiInterceptor from "shared/hooks/appPageHooks/useApiInterceptor";
import useAppInitialTheme from "shared/hooks/appPageHooks/useAppInitialTheme";
import useCloudEndpoint from "shared/hooks/appPageHooks/useCloudEndpoint";
import useCustomScripts from "shared/hooks/appPageHooks/useCustomScripts";
import useDestroyCookieBeforeUnload from "shared/hooks/appPageHooks/useDestroyCookieBeforeUnload";
import useFetchInitialMenus from "shared/hooks/appPageHooks/useFetchInitialMenus";
import useMonitoringAppInfinityReloading from "shared/hooks/appPageHooks/useMonitoringAppInfinityReloading";
import useMonitoringErrorsForVercel from "shared/hooks/appPageHooks/useMonitoringErrorsForVercel";
import useNewFeaturesAvailable from "shared/hooks/appPageHooks/useNewFeaturesAvailable";
import useUTMSource from "shared/hooks/appPageHooks/useUTMSource";
import useWindowErrorEvents from "shared/hooks/appPageHooks/useWindowErrorEvents";
import api from "shared/infra/services/api";
import ErrorBoundary from "shared/providers/ErrorBoundary";
import Layout from "shared/providers/Layout";
import { LayoutProvider } from "shared/providers/Layout/context";
import { AuthContextProvider } from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import GlobalContextType from "shared/providers/context/GlobalContextType";
import initialStates from "shared/providers/context/initialStates";
import { TenantData } from "shared/providers/context/initialStates/tenancy";
import GlobalStyle from "shared/styles/GlobalStyle";
import { cannyConfigs } from "shared/utils/cannyConfig";
import capitalizeFirstLetter from "shared/utils/capitalizeFirstLetter";
import insertCustomScript from "shared/utils/entities/customScripts/insertCustomScript";
import { getCloudEndpoint } from "shared/utils/entities/tenant/getCloudEndpoint";
import getCustomPageTenancy from "shared/utils/entities/tenant/getCustomPageTenancy";
import { getPlatformTenant } from "shared/utils/entities/tenant/getPlatformTenant";
import { getAllSettledPromiseValue } from "shared/utils/getAllSettledPromiseValue";

import * as Sentry from "@sentry/nextjs";
import { Analytics } from "@vercel/analytics/react";

import AppHead from "./AppHead";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  query?: { tenant?: string; isMobileAppClient?: string };
  tenancy: TenantData;
  pageName: string;
  cookies: { isMobileAppClient: string };
  rawPathname: string;
  customPageTenancy?: {
    image_thumbnail: string;
    title: string;
    seo_title: string;
    seo_description: string;
    seo_keyword: string;
  } | null;
};

function MyApp({
  Component,
  pageProps,
  query,
  tenancy,
  pageName,
  customPageTenancy,
  cookies,
  rawPathname,
}: AppPropsWithLayout) {
  const [state, setState] = useState<GlobalContextType>({
    ...initialStates,
  } as GlobalContextType);

  const slug = query?.tenant;
  const themeMode = state?.theme?.value;
  const tenantSettings = state?.tenancy?.settings;
  const generalTheme = tenantSettings ? tenantSettings["general-theme"] : {};
  const advancedTheme = tenantSettings ? tenantSettings["advanced-theme"] : {};
  const tenantDataExists = state?.tenancy
    ? Object?.keys(state?.tenancy)?.length
    : false;

  const { customScripts } = useCustomScripts({ slug });
  const { handleInitialPlatformThemeValues } = useAppInitialTheme({
    cookies,
    query,
    setState,
    tenancy,
  });

  useCloudEndpoint({ slug });
  useFetchInitialMenus({ handleInitialPlatformThemeValues, query, tenancy });
  useNewFeaturesAvailable({ tenancy });

  useUTMSource();
  useApiInterceptor();

  useWindowErrorEvents();
  useMonitoringAppInfinityReloading();
  useMonitoringErrorsForVercel();
  useDestroyCookieBeforeUnload();

  return (
    <>
      <ErrorBoundary>
        <AppHead
          tenancy={tenancy}
          pageName={pageName}
          query={query}
          customScripts={customScripts}
          customPageTenancy={customPageTenancy}
        />

        <Script id="canny">{cannyConfigs}</Script>

        {customScripts?.body?.map(({ script }) => insertCustomScript(script))}

        <GlobalContext.Provider value={{ state, setState }}>
          <AuthContextProvider>
            <GlobalStyle
              themeMode={themeMode}
              $generalTheme={generalTheme}
              $advancedTheme={advancedTheme}
            />

            {tenantDataExists ? (
              rawPathname === "/reset/[token]" ? (
                <Component {...pageProps} />
              ) : (
                <LayoutProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </LayoutProvider>
              )
            ) : (
              <div className="container-loading">
                <CircularLoader />
              </div>
            )}

            <Analytics />
          </AuthContextProvider>
        </GlobalContext.Provider>
      </ErrorBoundary>
    </>
  );
}

MyApp.getInitialProps = async (ctx) => {
  try {
    const appProps = await App.getInitialProps(ctx);
    const query = ctx.router.query;
    const platform = query?.tenant;
    const request = ctx?.ctx?.req;

    const isAbletoGetInitialProps =
      platform &&
      platform !== "undefined" &&
      platform !== "g" &&
      !platform.includes("browse");

    if (isAbletoGetInitialProps) {
      const host = getCloudEndpoint(platform);
      const clientIp = request?.connection?.remoteAddress || "";

      const pathname = ctx?.router?.pathname
        ?.replace("/[tenant]/", "")
        ?.split("/")[0];

      const [customPageTenancyResult, tenancyResult] = await Promise.allSettled(
        [
          getCustomPageTenancy({ query, pathname }),
          getPlatformTenant(query?.tenant + ".ensinio.com", clientIp, platform),
        ],
      );

      const tenancy = getAllSettledPromiseValue(tenancyResult, {
        data: {},
      });

      const customPageTenancy = getAllSettledPromiseValue(
        customPageTenancyResult,
        { data: {} },
      );

      if (tenancy) api.defaults.baseURL = `${host}/api/v1`;

      return {
        ...appProps,
        query,
        tenancy: tenancy?.data,
        customPageTenancy,
        cookies: request?.cookies,
        pageName: capitalizeFirstLetter(pathname || ""),
        rawPathname: pathname,
      };
    }
  } catch (error) {
    console.log("[_app]: an error occurred in MyApp.getInitialProps().");
    Sentry.captureException(error);
  }
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  