import React from "react";

export const Check = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="9.5" r="6" fill="white" />
      <path
        d="M9.99935 1.66797C5.40435 1.66797 1.66602 5.4063 1.66602 10.0013C1.66602 14.5963 5.40435 18.3346 9.99935 18.3346C14.5943 18.3346 18.3327 14.5963 18.3327 10.0013C18.3327 5.4063 14.5943 1.66797 9.99935 1.66797ZM8.33352 13.6788L5.23935 10.5913L6.41602 9.4113L8.33185 11.3238L12.7435 6.91214L13.9219 8.09047L8.33352 13.6788Z"
        fill="#23A840"
      />
    </svg>
  );
};
