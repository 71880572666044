import React, { ChangeEvent, InputHTMLAttributes } from "react";

import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageInputContainer, ContainerInputMyDocuments } from "./styles";

interface IConsolePageInputsProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isInvalid?: boolean;
  value: string;
  required: boolean;
  error?: string;
}

export const InputMyDocuments = ({
  label,
  isInvalid,
  required,
  error,
  ...props
}: IConsolePageInputsProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props?.onChange) props.onChange(event);
  };

  const customId = removeSpacesAndAccentsFromString(label);

  return (
    <ContainerInputMyDocuments>
      <ConsolePageInputContainer
        shouldMoveFloatingLabel={!!props?.value}
        isInvalid={isInvalid}
      >
        <label>
          {label}
          {required && <span className="required-flag">*</span>}
        </label>
        <input
          onChange={handleChange}
          value={props?.value}
          required={required}
          autoComplete="off"
          id={customId}
          {...props}
        />
      </ConsolePageInputContainer>
      {error && <span className="error">{error}</span>}
    </ContainerInputMyDocuments>
  );
};
