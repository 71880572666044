import styled from "styled-components";

export const ErrorPageContainer = styled.div`
  max-width: 500px;
  margin: 1rem auto;

  text-align: center;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .relatory-text {
    color: ${({ theme }) =>
      theme.mode === "dark" ? "#bdbdbd" : "#616161"} !important;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;

    margin-top: 1rem;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;

    margin-bottom: 1rem;

    a {
      border: none;
      border-radius: 4px;
      padding: 8px 12px;
      height: 36px;

      white-space: nowrap;

      opacity: 0.9;

      color: ${({ theme }) => theme.colors.textMasterColor} !important;
      background-color: ${({ theme }) => theme.colors.primary} !important;

      transition: 0.3s opacity;

      :hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
`;
