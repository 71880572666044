/* eslint-disable react-hooks/rules-of-hooks */
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { formatErrorMessageToLog } from "shared/utils/formatErrorMessageToLog";

import { captureException } from "@sentry/nextjs";

import sendLogToSlack from "./slackAPI";

const ErrorHandler = async (error: AxiosError) => {
  const errorResponseStatus = error?.response?.status;

  const requestWasCanceled = error?.message === "canceled";
  if (requestWasCanceled) return;

  const myGroupsRouteNotExists =
    error.request.responseURL.includes("my-groups");
  if (myGroupsRouteNotExists) return;

  const nexusRouteHasError =
    error.request.responseURL.includes("nexus/bindings");
  if (nexusRouteHasError) return;

  const getGroupPostsSentIncorrectly =
    error.request.responseURL.includes("groups//posts");
  if (getGroupPostsSentIncorrectly) return;

  const getCollectionsIncorrectly =
    error.request.responseURL.includes("collection");
  if (getCollectionsIncorrectly) return;

  const responseData = error?.response?.data as any;

  switch (errorResponseStatus) {
    case 401:
      Cookies.remove("username");
      Cookies.remove("token");
      window.location.href = "/401";
      break;

    case 422:
      const isInAuthenticationPage =
        window.location.pathname === "/authentication" &&
        // eslint-disable-next-line no-prototype-builtins
        responseData?.hasOwnProperty("email");

      const emailAlreadyInUse =
        responseData?.email &&
        responseData?.email[0] === "O atributo e-mail já foi escolhido.";

      const shouldShowEmailAlreadyInUseToast =
        isInAuthenticationPage && emailAlreadyInUse;

      if (shouldShowEmailAlreadyInUseToast)
        toast(responseData?.email[0], { type: "error" });
      break;

    case 500:
      const isInLocalhost = window?.location?.hostname?.includes("localhost");
      if (isInLocalhost) return;

      const errorMessageFormatted = formatErrorMessageToLog(error);
      sendLogToSlack(errorMessageFormatted);
      captureException(error);

      break;

    // eslint-disable-next-line no-fallthrough
    default:
      break;
  }
};

export default ErrorHandler;
