import { detect } from "detect-browser";

import { captureException, captureMessage } from "@sentry/nextjs";

export const getUserSO = () => {
  let browserOS = "";

  try {
    const browse = detect();

    browserOS = browse?.os ?? "Não encontrado";
  } catch (error) {
    console.log("Erro (getUserSO): ", error);
    captureException(error);
    captureMessage("Erro (getUserSO)");
  }

  return browserOS;
};
