import React, { createContext } from "react";

import GlobalContextInitialState from "./GlobalContextInitialState";
import GlobalContextType from "./GlobalContextType";

type GlobalContextProps = {
  state: GlobalContextType;
  setState?: React.Dispatch<React.SetStateAction<GlobalContextType>>;
};

const GlobalContext = createContext<GlobalContextProps>(
  GlobalContextInitialState,
);

export default GlobalContext;
