import { FaExternalLinkAlt } from "react-icons/fa";

import { InternalSectionOptionPageSidebar } from "pages/[tenant]/console/groups/[group_slug]/modules/components/organisms/InternalSectionOptionPageSidebar";

import { AccountIcon } from "pages/[tenant]/console/assets/AccountIcon";
import { CentralHelpIcon } from "pages/[tenant]/console/assets/CentralHelpIcon";
import { ConfigsIcon } from "pages/[tenant]/console/assets/ConfigsIcon";
import { DropdownArrow } from "pages/[tenant]/console/assets/DropdownArrow";
import { EnsinioSteamIcon } from "pages/[tenant]/console/assets/EnsinioSteamIcon";
import { GamificationIcon } from "pages/[tenant]/console/assets/GamificationIcon";
import { NewsIcon } from "pages/[tenant]/console/assets/NewsIcon";
import { PeopleIcon } from "pages/[tenant]/console/assets/PeopleIcon";
import { PlanLinkIcon } from "pages/[tenant]/console/assets/icons/PlanLinkIcon";
import { AnalyticsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/analytics/AnalyticsIcon";
import { AppStoreIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/appstore/AppStoreIcon";
import { GroupsAndContentIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/GroupsAndContentIcon";
import { SalesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/SalesIcon";
import { ActiveSectionIndicator } from "pages/[tenant]/console/components/atoms/ActiveSectionIndicator";
import { EnsinioLogo } from "pages/[tenant]/console/components/atoms/EnsinioLogo";
import { Options } from "pages/[tenant]/console/components/atoms/Options";
import { SideBarButton } from "pages/[tenant]/console/components/atoms/SideBarButton";
import { SideBarLink } from "pages/[tenant]/console/components/atoms/SideBarLink";
import { SideBarSection } from "pages/[tenant]/console/components/molecules/SideBarSection";
import {
  consolePageMobileBreakpoint,
  useConsolePageConfig,
} from "pages/[tenant]/console/hooks/useConsolePageConfig";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { SwitchTheme } from "shared/providers/Layout/components/NewTopBar/components/atoms/SwitchTheme";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import {
  BottomButtons,
  ConsolePageSideBarContainer,
  SectionsDropdownButton,
  SideBarContentContainer,
  TopButtons,
} from "./styles";

export const ConsolePageSideBar = () => {
  const {
    optionsList,
    activeSectionLabel,
    sectionInfo,
    activeSectionPosition,
    isMobileSectionOptionsDropdownOpen,
    closeMobileSectionOptionsDropdown,
    openMobileSectionOptionsDropdown,
  } = useConsolePageContext();
  const {
    isInConsoleInternalPage,
    isInInternalSectionOptionPage,
    shouldShowConsolePageSideBarOptions,
  } = useConsolePageConfig();
  const shouldShowOptions =
    isInConsoleInternalPage &&
    optionsList &&
    shouldShowConsolePageSideBarOptions;

  const { width } = useWindowSize();
  const isMobile = width < consolePageMobileBreakpoint;

  const handleClick = () =>
    isMobileSectionOptionsDropdownOpen
      ? closeMobileSectionOptionsDropdown()
      : openMobileSectionOptionsDropdown();

  return (
    <>
      <ConsolePageSideBarContainer
        showMobileDropdown={isMobileSectionOptionsDropdownOpen}
      >
        <SectionsDropdownButton
          onClick={handleClick}
          shouldRotateIcon={isMobileSectionOptionsDropdownOpen}
        >
          <DropdownArrow />
          {isInConsoleInternalPage ? activeSectionLabel : "Página inicial"}
        </SectionsDropdownButton>

        <SideBarContentContainer>
          <EnsinioLogo />

          <TopButtons>
            <SideBarSection
              label={sectionInfo.groupsAndContent.label}
              icon={<GroupsAndContentIcon />}
              options={sectionInfo.groupsAndContent.options}
              subSections={sectionInfo.groupsAndContent.subSections}
            />

            <SideBarSection
              label={sectionInfo.people.label}
              icon={<PeopleIcon />}
              options={sectionInfo.people.options}
            />

            <SideBarSection
              label={sectionInfo.sales.label}
              icon={<SalesIcon />}
              options={sectionInfo.sales.options}
              subSections={sectionInfo.sales.subSections}
            />

            <SideBarSection
              label={sectionInfo.analytics.label}
              icon={<AnalyticsIcon />}
              options={sectionInfo.analytics.options}
            />

            <SideBarSection
              label={sectionInfo.appStore.label}
              icon={<AppStoreIcon />}
              options={sectionInfo.appStore.options}
              subSections={sectionInfo.appStore.subSections}
            />

            <SideBarLink
              icon={<EnsinioSteamIcon />}
              label="Ensinio Stream"
              path=""
              shouldHide
            />

            <SideBarSection
              label={sectionInfo.gamification.label}
              icon={<GamificationIcon />}
              options={sectionInfo.gamification.options}
              subSections={sectionInfo.gamification.subSections}
            />

            <SideBarLink
              icon={<AccountIcon />}
              label="Sua conta"
              path=""
              shouldHide
            />

            <SideBarSection
              label={sectionInfo.configs.label}
              icon={<ConfigsIcon />}
              options={sectionInfo.configs.options}
              subSections={sectionInfo.configs.subSections}
            />

            <ActiveSectionIndicator
              activeSectionPosition={activeSectionPosition}
            />
          </TopButtons>

          <BottomButtons>
            <SideBarLink
              icon={<FaExternalLinkAlt />}
              path="/browse"
              label="Ir até a plataforma"
              target="_blank"
            />

            <SideBarButton label="Modo Escuro / Claro">
              <div className="switch-toggle-label">
                {isMobile && "Modo Escuro / Claro"}
              </div>
              <SwitchTheme withLabel={false} />
            </SideBarButton>

            <SideBarLink
              icon={<PlanLinkIcon />}
              path="/console/billing"
              label="Plano"
            />

            <SideBarLink
              icon={<CentralHelpIcon />}
              path="https://ajuda.ensinio.com/"
              label="Central de Ajuda"
              target="_blank"
            />

            <SideBarButton label="Novidades" data-canny-changelog>
              <NewsIcon />
            </SideBarButton>
          </BottomButtons>
        </SideBarContentContainer>
      </ConsolePageSideBarContainer>

      {shouldShowOptions && (
        <Options currentSectionOptions={optionsList[activeSectionLabel]} />
      )}

      {isInInternalSectionOptionPage && <InternalSectionOptionPageSidebar />}
    </>
  );
};
