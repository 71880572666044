import styled from "styled-components";

export const OptionContainer = styled.li`
  padding: 1rem;

  transition: 0.3s all;

  :hover:not([data-disabled="true"]) {
    cursor: pointer;
    background-color: ${({ theme }) =>
      theme.console.sidebar.optionHoverBackgroundColor};
  }

  &[data-disabled="true"] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  color: ${({ theme }) => theme.console.inputColor};
`;
