import { InputHTMLAttributes } from "react";

import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageSwitchContainer, SwitchLabelContainer } from "./styles";

interface IConsolePageSwitchProps
  extends InputHTMLAttributes<HTMLButtonElement> {
  handleChange: (checked: boolean) => void;
  checked: boolean;
  isInvalid?: boolean;
  label?: string;
}

export const ConsolePageSwitch = ({
  handleChange,
  isInvalid,
  label,
  checked,
  ...props
}: IConsolePageSwitchProps) => {
  const handleCustomChange = () => {
    const isDisabled = props?.disabled;
    if (!isDisabled) handleChange(!checked);
  };

  const customId = removeSpacesAndAccentsFromString(
    label || "console-page-switch",
  );

  return (
    <SwitchLabelContainer
      onClick={handleCustomChange}
      data-testid="console-page-switch"
      id={customId}
    >
      <ConsolePageSwitchContainer
        {...props}
        isOn={checked}
        isInvalid={isInvalid}
        type="button"
      >
        <div className="toggle-circle"></div>
      </ConsolePageSwitchContainer>

      {label && <div className="label">{label}</div>}
    </SwitchLabelContainer>
  );
};
