import React from "react";

export const SelectArrow = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="select-arrow"
    >
      <path
        d="M1.5 1.25L5 4.75L8.5 1.25"
        stroke="#C8C8C9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
