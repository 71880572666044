import React, { useState } from "react";

import { IRadio } from "pages/[tenant]/console/types";

import { Radio } from "./Radio";

interface IConsolePageRadioGroupProps {
  radioGroupName: string;
  defaultSelectedValue?: string;
  handleChange: (selectedRadioValue: any) => void;
  options: IRadio[];
  disabled?: boolean;
  withRadioCircle?: boolean;
}

export const ConsolePageRadioGroup = ({
  options,
  radioGroupName,
  defaultSelectedValue,
  handleChange,
  disabled,
  withRadioCircle,
}: IConsolePageRadioGroupProps) => {
  const [currentOptionsSelectedId, setCurrentOptionsSelectedId] =
    useState(null);

  const handleClickRadio = ({ target }: any) => {
    const radioIsDisabled = disabled;
    if (radioIsDisabled) return;

    const selectedOptionId = +(target as HTMLLIElement).getAttribute(
      "data-radio-id",
    );

    const optionAlreadySelected = selectedOptionId === currentOptionsSelectedId;
    if (optionAlreadySelected) {
      setCurrentOptionsSelectedId(null);
      handleChange({ value: "" });
      return;
    }

    setCurrentOptionsSelectedId(selectedOptionId);
    const selectedOption = options?.find(
      (option) => option.id === selectedOptionId,
    );

    handleChange(selectedOption);
  };

  return (
    <>
      {options.map((option) => (
        <Radio
          key={option.id}
          radio={option}
          radioGroupName={radioGroupName}
          handleClickRadio={handleClickRadio}
          defaultSelectedValue={defaultSelectedValue}
          radioGroupIsDisabled={disabled}
          checked={currentOptionsSelectedId === option.id}
          withRadioCircle={withRadioCircle} 
        />
      ))}
    </>
  );
};
