import { IGetFromSessionStorageAlreadySendLogToSlackReturn } from "shared/types/monitoringInfiniteReloadingTypes";
import {
  getPageTimeLogsFromSessionStorage,
  getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance,
  timeInMilisecondsOfToleranceToConsiderThatPageKeepsReloading,
  timesOfReloadingsToConsiderThatPageKeepsReloading,
} from "shared/utils/monitoringInfiniteReloadingUtils";
import { sendPageKeepsReloadingLogToSlack } from "shared/utils/sendPageKeepsReloadingLogToSlack";

import { captureException, captureMessage } from "@sentry/nextjs";

export const getFromSessionStorageAlreadySendConsecutiveReloadingLogToSlack =
  (): IGetFromSessionStorageAlreadySendLogToSlackReturn =>
    (sessionStorage?.getItem(
      "alreadySendConsecutiveReloadingLogToSlack",
    ) as IGetFromSessionStorageAlreadySendLogToSlackReturn) ?? "false";

export const setInSessionStorageAlreadySendConsecutiveReloadingLogToSlack =
  (): void =>
    sessionStorage?.setItem(
      "alreadySendConsecutiveReloadingLogToSlack",
      "true",
    );

export const verifyIfCurrentPageKeepsReloading = async () => {
  try {
    const currentPagePath = window?.location?.href;
    const pageLogsFromSessionStorage = getPageTimeLogsFromSessionStorage();
    const timeOfEachPageRealodedBetweenMilisecondsOfTolerance =
      getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance({
        pageLogs: pageLogsFromSessionStorage,
        timeOfTolerance:
          timeInMilisecondsOfToleranceToConsiderThatPageKeepsReloading,
      });

    const timesOfCurrentPageReloaded =
      timeOfEachPageRealodedBetweenMilisecondsOfTolerance[currentPagePath];

    const pageKeepsReloading =
      timesOfCurrentPageReloaded >=
      timesOfReloadingsToConsiderThatPageKeepsReloading;
    if (!pageKeepsReloading) return;

    const consecutiveReloadingLogWasSendedToSlack =
      await sendPageKeepsReloadingLogToSlack(currentPagePath);

    if (consecutiveReloadingLogWasSendedToSlack)
      setInSessionStorageAlreadySendConsecutiveReloadingLogToSlack();
  } catch (error) {
    console.log("Error (verifyIfCurrentPageKeepsReloading): ", error);
    captureException(error);
    captureMessage("Error (verifyIfCurrentPageKeepsReloading)");
  }
};
