import styled from "styled-components";

export const ConsolePagePasswordInputContainer = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-right: 3rem;
  }

  button {
    background-color: transparent;
    border: none;
    font-size: 0;

    position: absolute;
    top: 50%;
    right: 0rem;
    transform: translateY(-50%);

    height: 100%;
    padding-right: 0.8rem;

    :hover:not(:disabled) {
      cursor: pointer;
    }

    :disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    color: #b2b8b8;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
