import { useEffect, useState } from "react";

import { IPageLog } from "shared/types/monitoringInfiniteReloadingTypes";
import { handleSendLogToSlack } from "shared/utils/handleSendLogToSlack";
import { isInLocalhost } from "shared/utils/isInLocalhost";
import {
  getFromSessionStorageAlreadySendLogToSlack,
  getPageTimeLogsFromSessionStorage,
  savePageTimeLogsInSessionStorage,
} from "shared/utils/monitoringInfiniteReloadingUtils";
import {
  getFromSessionStorageAlreadySendConsecutiveReloadingLogToSlack,
  verifyIfCurrentPageKeepsReloading,
} from "shared/utils/verifyIfCurrentPageKeepsReloading";

import { captureException, captureMessage } from "@sentry/nextjs";

export const useMonitoringInfiniteReloading = () => {
  const [shouldStartMonitoring, setShouldStartMonitoring] = useState(false);

  const startMonitoringForInifiniteReloading = () =>
    setShouldStartMonitoring(true);

  useEffect(() => {
    if (typeof window === "undefined") return;

    if (!shouldStartMonitoring) return;

    if (isInLocalhost()) return;

    let pageLogsFromSessionStorage: IPageLog;

    try {
      pageLogsFromSessionStorage = getPageTimeLogsFromSessionStorage();

      const firstReloadingsLogWasSendedToSlack =
        getFromSessionStorageAlreadySendLogToSlack() === "true";
      if (!firstReloadingsLogWasSendedToSlack)
        handleSendLogToSlack(pageLogsFromSessionStorage);

      const consecutiveReloadingsLogWasSendedToSlack =
        getFromSessionStorageAlreadySendConsecutiveReloadingLogToSlack() ===
        "true";
      if (!consecutiveReloadingsLogWasSendedToSlack)
        verifyIfCurrentPageKeepsReloading();
    } catch (error) {
      console.log(
        "Error (useMonitoringInfiniteReloading | useEffect): ",
        error,
      );
      captureException(error);
      captureMessage("Error (useMonitoringInfiniteReloading | useEffect)");
    }

    window.onbeforeunload = () => {
      try {
        const currentPath = window?.location?.href;
        const nowTimeInMiliseconds = new Date().getTime();

        const prevLogs = pageLogsFromSessionStorage
          ? pageLogsFromSessionStorage[currentPath] ?? []
          : [];

        savePageTimeLogsInSessionStorage({
          ...pageLogsFromSessionStorage,
          [currentPath]: [...prevLogs, nowTimeInMiliseconds],
        });
      } catch (error) {
        console.log(
          "Error (useMonitoringInfiniteReloading | window.onbeforeunload): ",
          error,
        );
        captureException(error);
        captureMessage(
          "Error (useMonitoringInfiniteReloading | window.onbeforeunload)",
        );
      }
    };
  }, [shouldStartMonitoring]);

  return { startMonitoringForInifiniteReloading };
};
