import sendLogToSlack from "shared/infra/services/slackAPI";
import { IPageErrorBoundary } from "shared/providers/PageErrorBoundary/types";
import {
  formatErrorMessage,
  retryPageLoading,
} from "shared/providers/PageErrorBoundary/utils";

import * as Sentry from "@sentry/nextjs";

import { PageErrorBoundaryContainer } from "./styles";

class PageErrorBoundary extends IPageErrorBoundary {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    retryPageLoading();
    console.error(error);

    const isInLocalhost = window.location.hostname.includes("localhost");
    if (isInLocalhost) return;

    const formatedError = formatErrorMessage(error);

    sendLogToSlack(formatedError);
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <PageErrorBoundaryContainer>
        <h1>Algo deu errado :(</h1>

        <p>Lamentamos o inconveniente.</p>
        <p>
          Geramos automaticamente um relatório sobre o ocorrido, e trabalharemos
          para corrigir o quanto antes.
        </p>

        <button
          className="reload-button"
          onClick={() => window.location.reload()}
          id="reload-page-button"
        >
          Recarregar página
        </button>
      </PageErrorBoundaryContainer>
    ) : (
      <>{children}</>
    );
  }
}

export default PageErrorBoundary;
