import { AuthenticationIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/AuthenticationIcon";
import { DesignIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/DesignIcon";
import { DomainIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/DomainIcon";
import { MenusIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/MenusIcon";
import { PermissionIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/PermissionIcon";
import { SEOIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/config/SEOIcon";
import { BillboardIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/pages/BillboardIcon";
import { TagsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/pages/TagsIcon";
import { SalesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/SalesIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useConfigsOptions = (): IOptionsHook => {
  const sectionLabel = "Configurações";
  const optionsSubSections: ISubSection[] = [
    {
      label: "Definições",
      options: [
        {
          id: 1,
          label: "Plataforma",
          description:
            "Defina as configurações gerais da sua plataforma e como ela deve ser apresentada ao público.",
          path: "/console/platform",
          icon: <TagsIcon />,
        },
        {
          id: 2,
          label: "Aparência e design",
          description:
            "Personalize sua plataforma, configure sua identidade visual e reforce sua marca.",
          path: "/console/design",
          icon: <DesignIcon />,
        },
        {
          id: 3,
          label: "Vendas",
          description:
            "Configure a comunicação e comportamento das vendas realizadas através da Ensinio Pay.",
          path: "/console/sales",
          icon: <SalesIcon />,
        },
        {
          id: 4,
          label: "Domínios",
          path: "/console/domains",
          icon: <DomainIcon />,
        },
        {
          id: 5,
          label: "Billboard",
          description:
            "Configure sua vitrine e organize suas seções e banners de destaques",
          path: "/console/billboard",
          icon: <BillboardIcon />,
        },
        {
          id: 6,
          label: "Menus",
          description:
            "Defina seus menus públicos e configure a navegação da sua plataforma",
          path: "/console/menus",
          icon: <MenusIcon />,
        },
        {
          id: 7,
          label: "Permissões",
          path: "/console/permissions",
          icon: <PermissionIcon />,
          shouldHide: true,
        },
        {
          id: 8,
          label: "Autenticação",
          path: "/console/auth",
          icon: <AuthenticationIcon />,
          shouldHide: true,
        },
        {
          id: 9,
          label: "SEO",
          path: "/console/seo",
          icon: <SEOIcon />,
          shouldHide: true,
        },
      ],
    },
  ];

  return {
    label: sectionLabel,
    subSections: optionsSubSections,
  };
};
