import { IPagesToHide, IPagesToShow } from "pages/[tenant]/console/types";

export const mobileFeatureNotInPlanTemplateBreakpoint = 550;

export const pagesToHideConsolePageSideBar: IPagesToHide = [
  "/404",
  "/401",
] as IPagesToHide;

export const pagesToHideConsolePageSideBarOptions: IPagesToHide = [
  "/billing",
  "/setup"
] as IPagesToHide;

export const pagesToShowInInternalModulePage: IPagesToShow = [
  "/[tenant]/console/groups/[group_slug]/modules",
  "/[tenant]/console/groups/[group_slug]/modules/[module_slug]/lesson-types",
  "/[tenant]/console/groups/[group_slug]/modules/[module_slug]/lesson-types/[lesson_slug]",
] as IPagesToShow;
