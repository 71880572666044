export interface IErrorMap {
  id: number;
  message: string;
}

export enum ERROR_MESSAGES_FROM_VERCEL {
  APPLICATION_ERROR = "exception has occurred",
  PAGE_NOT_FOUND = "this page could not be found",
  ERROR_BUNDARY_MESSAGE = "algo deu errado",
  CUSTOM_PAGE_NOT_FOUND = "ops! Essa página não existe",
}
