import styled from "styled-components";

interface IContainerAlertMyDocuments {
  status: string;
}

export const ContainerAlertMyDocuments = styled.div<IContainerAlertMyDocuments>`
  display: flex;
  justify-content: space-between;

  grid-row: 1;

  max-height: 50px;

  max-width: 74rem;
  width: 100%;
  padding: 0.5rem;

  border-radius: 0.25rem;

  margin: 0 1rem;

  background-color: ${(props) =>
    props.status === "update" ? "#FF4848" : "#BEAD1F"};

  p {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
  }

  .alert-message-my-documents {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
`;
