import { useMemo } from "react";

import { usePathname } from "next/navigation";

import { templatesRoutesMap } from "pages/[tenant]/console/components/templates/ConsoleLayout/utils/consoleLayoutUtils";
import { GenericTemplateFeatureNotInUserPlan } from "pages/[tenant]/console/components/templates/FeatureNotInUserPlan";
import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import { IOption } from "pages/[tenant]/console/types";

export const useLayoutOptions = () => {
  const {
    getCurrentSectionInfoByCurrentPath,
    getActiveOptionInView,
    optionsList,
  } = useConsolePageContext();
  const pathname = usePathname();

  const currentPath = pathname.split("/console")[1];
  const TemplateFeatureNotInPlan =
    templatesRoutesMap[currentPath] || GenericTemplateFeatureNotInUserPlan;

  const activeOption = useMemo<IOption>(() => {
    if (!optionsList) return;

    const { currentSectionOptions } =
      getCurrentSectionInfoByCurrentPath(pathname);

    return getActiveOptionInView(currentSectionOptions);
  }, [pathname, optionsList]);

  const pageIsDisabled = activeOption?.shouldDisable;
  const pageIsHidden = activeOption?.shouldHide;
  const isNotInUserPlan = activeOption?.isNotInUserPlan;

  return {
    pageIsDisabled,
    pageIsHidden,
    isNotInUserPlan,
    activeOption,
    currentPath,
    TemplateFeatureNotInPlan,
  };
};
