import { usePathname } from "next/navigation";

import { usePagesToShowInInternalModulePage } from "pages/[tenant]/console/groups/hooks/usePagesToShowInInternalModulePage";
import {
  pagesToHideConsolePageSideBar,
  pagesToHideConsolePageSideBarOptions,
} from "pages/[tenant]/console/utils/layoutUtils";

import { captureException, captureMessage } from "@sentry/nextjs";

export const consolePageMobileBreakpoint = 850;

export const useConsolePageConfig = () => {
  const pathname = usePathname();
  const consolePageConfig = {
    shouldShowConsolePageSideBar: false,
    isInConsolePage: false,
    isInConsoleInternalPage: false,
    isInInternalSectionOptionPage: false,
    shouldShowConsolePageSideBarOptions: false,
  };

  const CONSOLE_PATH = "/console";
  const UNAUTHORIZED_PATH = "/401";

  try {
    if (!pathname) return consolePageConfig;

    const urlPathname = pathname ?? "";
    const sectionFromPathname = urlPathname?.split("/")?.reverse()[0];
    const isUnauthorizedPage = urlPathname?.includes(UNAUTHORIZED_PATH);

    const pagesToShowInInternalModulePage =
      usePagesToShowInInternalModulePage();

    const isPageInInternalModule = pagesToShowInInternalModulePage?.some(
      (page) => urlPathname?.includes(page),
    );

    const shouldHideSideBar = pagesToHideConsolePageSideBar?.some((page) =>
      urlPathname?.includes(page),
    );

    consolePageConfig.shouldShowConsolePageSideBarOptions =
      pagesToHideConsolePageSideBarOptions.every(
        (pageUrlToHideSidebar) => !urlPathname.includes(pageUrlToHideSidebar),
      );

    consolePageConfig.isInConsolePage = urlPathname?.includes(CONSOLE_PATH);

    consolePageConfig.isInConsoleInternalPage =
      sectionFromPathname !== "console" && consolePageConfig.isInConsolePage;

    consolePageConfig.isInInternalSectionOptionPage =
      isPageInInternalModule && !isUnauthorizedPage;

    consolePageConfig.shouldShowConsolePageSideBar =
      consolePageConfig.isInConsolePage &&
      !shouldHideSideBar &&
      !isUnauthorizedPage;

    return consolePageConfig;
  } catch (error) {
    console.error(error);
    captureException(error);
    captureMessage("Custom hook error ~ useConsolePageConfig");
    return consolePageConfig;
  }
};
