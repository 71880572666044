import React from "react";

import useTranslation from "next-translate/useTranslation";

import { Image, PasswordInvalidContainer, Text } from "./styles";

const PasswordInvalid = () => {
  const { t } = useTranslation();

  return (
    <PasswordInvalidContainer>
      <Image />

      <Text>Sua senha deve ter mais de 6 carecteres</Text>
    </PasswordInvalidContainer>
  );
};

export default PasswordInvalid;
