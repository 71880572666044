const setVerticalSettings = (
  vertical: boolean,
): { width: number; height: number } => {
  if (vertical) {
    return {
      width: 336,
      height: 471.77,
    };
  }

  return {
    width: 334,
    height: 176,
  };
};

export { setVerticalSettings };
