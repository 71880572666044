import styled from "styled-components";

import { IConsolePageButtonVariants } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/types";
import { consolePageButtonVariants } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/utils";

interface IConsolePageButtonContainerProps {
  variant: IConsolePageButtonVariants;
}

export const ConsolePageButtonStyles = styled.button`
  all: unset;
  display: block;

  padding: 0.5rem 1rem;

  border-radius: 4px;

  font: 600 0.75rem/1.125rem "Inter";

  text-align: center;
  &,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    opacity: 0.9;
  }

  a {
    color: currentColor;
  }

  transition: 0.3s all;

  white-space: nowrap;
  :not(:disabled) {
    :hover {
      &,
      a {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  :disabled {
    &,
    a {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  svg {
    pointer-events: none;
  }
`;

export const ConsolePageButtonContainer = styled(
  ConsolePageButtonStyles,
)<IConsolePageButtonContainerProps>`
  ${({ variant }) => consolePageButtonVariants[variant]};
`;
