import styled, { css } from "styled-components";

interface ContainerProps {
  isSlug: boolean;
}

export const SingleGroupContainer = styled.li<ContainerProps>`
  cursor: pointer;
  display: flex;

  &,
  a {
    width: 100%;
  }

  a {
    padding: 0.5rem 1rem;
    display: block;
  }

  transition: all 0.3s;

  .data-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.groups.primaryTextTopbarGroups};
    margin-bottom: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    max-width: 14rem;
    word-wrap: break-word;
    word-break: break-all;
  }

  img {
    border-radius: 4px;
  }

  ${({ isSlug }) =>
    isSlug &&
    css`
      background: ${({ theme }) => theme.groups.backgroundOptionsMenuTopBar};
    `}

  &:hover {
    background: ${({ theme }) => theme.groups.backgroundOptionsMenuTopBar};
  }
`;

interface IColorFullBackgroundProps {
  color?: string;
}

export const ColorFullBackground = styled.div<IColorFullBackgroundProps>`
  width: 3.75rem;
  height: 2.125rem;
  border-radius: 0.25rem;

  background-color: ${(props) => props.color};
`;
