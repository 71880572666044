import styled from "styled-components";

export const ClassworkPageImersiveLayoutPageContainer = styled.div`
  margin-top: 7rem;
  color: white;

  background-color: ${({ theme }) => theme.classwork.imsersive.backgroundColor};

  display: grid;
  grid-template-areas: "content";
  align-items: start;

  padding-bottom: 7rem;
`;

export const ClassworkPageImersiveLayoutPageContent = styled.div`
  width: 100%;
  margin: 0 auto;

  grid-area: content;
  z-index: 1;

  max-width: 1460px;

  padding: 0 1rem;

  @media (max-width: 1600px) {
    max-width: 1100px;
  }
`;
