import { ITenantTheme } from "shared/styles/Theme";
import { getColorsFromSidebarThemes } from "shared/utils/getColorsFromSidebarThemes";
import { getColorsFromTopbarThemes } from "shared/utils/getColorsFromTopbarThemes";

const light = (tenantTheme: ITenantTheme) => {
  const setStyleProperty = (property: keyof ITenantTheme) =>
    tenantTheme ? tenantTheme[property] : "#FFFFFF";

  return {
    settings: {
      themeMainMenu: tenantTheme?.themeMainMenu,
    },
    colors: {
      gray100: "#1A1A1A",
      gray200: "#404040",
      gray300: "#666666",
      gray400: "#8C8C8C",
      gray500: "#B2B2B2",
      gray600: "#D9D9D9",
      gray700: "#F2F2F2",

      textMasterColor: setStyleProperty("themeMasterTextColor"),
      primary: setStyleProperty("themeMasterColor"),
      secondary: setStyleProperty("themeDarkModeMasterColor"),
      topbar: getColorsFromTopbarThemes(tenantTheme).background,
      topbarText: getColorsFromTopbarThemes(tenantTheme).color,
      sidebar: setStyleProperty("themeTopBarBackgroundColor"),
      sidebarBackgroudColor: getColorsFromSidebarThemes(tenantTheme).background,
      sidebarItemColor: getColorsFromSidebarThemes(tenantTheme).itemColor,
      sidebarColapseIconColor:
        getColorsFromSidebarThemes(tenantTheme).colapseIconColor,
      backgroundColor: "#f5f5f5",
      secondaryBackgroundColor: "#f5f5f5",
      textColor: "#303030",
      btnTextColor: "#f7f7f7",
      blogTextColorPrimary: "#1A1A1A",
      blogTextColorSecondary: "#404040",
      blogTextColorTertiary: "#8C8C8C",
      cardBlog: "#ffffff;",
      inputBackgroundColor: "#f5f5f5",
      modalBackgroundColor: "#fefefe",
      headerBackgroundColor:
        tenantTheme?.themeHeaderStyle === "automatic"
          ? setStyleProperty("themeMasterColor")
          : setStyleProperty("themeHeaderBackgroundColor"),
      headerTextColor:
        tenantTheme?.themeHeaderStyle === "automatic"
          ? "#f7f7f7"
          : setStyleProperty("themeHeaderTextColor"),
      borderColor: "#E5E5E5",
      collectionsInputBorderColor: "#6a6a6a",
      followButtonColor: "#ffffff",
      dividerColor: "#ffffff",
      globalSearchInputBackgroundColor:
        tenantTheme?.themeTopBar === "dark"
          ? "#323232"
          : tenantTheme?.themeTopBar === "light"
          ? "#cccccc"
          : "",
      enrollmentCardTitleBackground: "#CACACA",
      customSwitchBackground: "#404040",
      textColorInstallments: "#d1cdcd",

      globalTopbar: {
        linearGradient:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 73.96%)",
        solidBackground: "#FFF",
        primaryBackground: "#F3F4F6",
        secondaryBackground: "#FCFCFC",
        textColor: "#050505bf",
        textHoverColor: "#050505",
        borderColor: "#E9EBF4",
        userMenuBackground: "#f3f4f6",
        menuItensColor: "#414143",
        menuItensHoverColor: "#f2f2f2",
        bottomIconsActiveColor: "#050505",
        bottomIconsColor: "#050505bf",
        iconsColor: "#050505",
      },

      searchPage: {
        inputColor: "#8C8C8C",
        inputFocusColor: "#303030",
        cardHoverBorderColor: "#8C8C8C",
        mobileBottomNavigationInputColor: "#3c3c3c",
        mobileBottomNavigationInputBackground: "#dadee5",
      },
    },
    checkoutOnePage: {
      textColor: "#1a1a1a",
      placeholderColor: "#666666",
      inputBorderColor: "#D9D9D9",
      inputBackground: "#ffffff",
      buttonBackgroundDisabled: "#B3B3B3",

      leftSideBoxShadow: "0.5rem 0rem 3.5rem rgba(41, 41, 41, 0.08)",

      leftSideBackgroud: "#fcfcfc",
      rightSideBackground: "#fafafa",

      green: "#01B210",
      blue: "#0073E5",
      red: "#FF0000",
    },
    groups: {
      backgroundColor: "#F6F6FC",

      iconsTopBar: "#262626",
      primaryText: "#262626",
      secondaryText: "#404040",
      nexusLinkTextColor: "#050505",
      borderGroups: "#F9F9F9",
      groupFeedTextColor: "#262626",
      groupFeedPostedAtColor: "#8C8C8C",
      groupFeedBackgroundColor: "#F9F9F9",
      groupFeedCommentCorderColor: "#E1E3ED",
      groupFeedCommentCorderColorHover: "#E1E3EDcc",
      groupFeedCommentBackgroundColor: "#F2F2F2",
      backgroundGroups: "#F3F4F6",
      backgroundHoverGroups: "#F9F9F9",
      borderGroupSelected: "#F9F9F9",
      topBarGroupsBackground: "#FFFFFF",
      backgroundComponentsMural: "#FFFFFF",
      borderColorComponentsMural: "#E9EBF4",
      backgroundOptionsMenuTopBar: "#E6E6E6",
      backgroundOptionsMenuTopBarHover: "#E9E9F3",
      primaryTextTopbarGroups: "#000",
      descriptionTextTopbarGroups: "#000000cc",
      secondaryTextTopbarGroups: "#6B6B6B",
      thirdTextTopbarGroups: "#1A1A1A",
      strokeOptionsMenu: "#000000",
      offerItemBorder: "#c0c0c0",
      layoutOptionBackground: "#ffffff",
      layoutOptionBackgroundHover: "#ffffffcc",
      layoutImageColor1: "#eaeaea",
      layoutImageColor2: "#f4f4f4",

      activitiesInputBackgroundColor: "#F3F3F3",
      activitiesModalBackgroundColor: "#FFFFFF",

      backgroundColorDetail: "#c0c0c0",
      backgroundColorInteraction: "#F1F1F1",
      backgroundButtonPublishFeed: "rgba(0,0,0,0.4)",
      backgroundModeration: "#F3F4F6",

      backgroundEditButtonModeration: "#E6E6E6",

      dropdownModeration: "#fcfcfc",
      backgroundDropdownModeration: "#F7F7F7",
      inputBackgroundModeration: "#FFFFFF",

      backgroundDeleteGroup: "#FFFFFF",
      backgroundSideMenuModeration: "#FFFFFF",
      backgroundMobileMenuModeration: "#F3F4F6",

      moderationTopicLessonBackground: "#EEEEEE",
      moderationSectionTopbarBackground: "#FcFcFc",
      moderationContainerBorder: "#EAEDF8",

      textFeedDialog: "#666666",
      borderFooterDialog: "#DFDFDF",
      groupModerationCards: "#FFFFFF",

      activeSectionModeration: "rgba(0, 0, 0, 0.05)",
      boxShadowModeration: "rgba(0, 0, 0, 0.08)",
      iconsMenuFooterMobile: "#262626",

      createLessonButton: "#FFFFFF",
      borderTitle: "#fcfcfc",
      lessonStatusBorder: "#E6E6E6",
      lessonHover: "#ffffff",
      customSwitchBall: "#666666",

      singleOfferBackgroundHover: "#e0e0e0",
      singleOfferBackgroundChecked: "#ffffff",
      listOffersBorder: "#ffffff",

      listOffersScrollbarTrack: "#ffffff0a",
      listOffersScrollbarThumb: "#ffffff",
      listOffersScrollbarThumbBorder: "#ffffff2f",

      createOfferModalPlaceholder: "#d7d7d7",
      createOfferModalBlur: "#ffffff7f",
      createOfferModalBackground: "#f7f7f7",
      createOfferModalSectionHeaderIcon: "#ffffff",
      createOfferModalSectionBorderBottom: "#f0f0f0",
      createOfferModalSelectOptionHover: "#e1e1e1",
      createOfferModalCheckboxBorder: "#f0f0f0",
      createOfferModalBorder: "#e1e1e1",
      createOfferModalColorContainerBorderColor: "#000000",
      createOfferModalInputColorBorderColor: "#ffffff",

      tooltipBackground: "#FFF",
      tooltipText: "#B3B3B3",

      questionsStatement: "#FFFFFF",
      questionsContentHover: "#FFFFFF",
      questionsContent: "#F7F7F7BF",
      questionsborderBottom: "#E6E6E6",
      questionsInput: "#FFFFFF",
      questionDificulty: "#FFFFFF",
      questionDificultyHover: "#FFFFFFCC",
      questionBorder: "#d9d9d9",

      groupTopbarTabColor: "#414143",
      groupTopbarTabActiveColor: "#303030",

      contextualMenuBackgroundColor: "#FFF",
      contextualMenuIconColor: "#303030",

      trailIndexNumberStroke: "#bbbbbb",
      trailIndexNumberFill: "rgba(0, 0, 0, 0.05)",

      lives: {
        inputBackgroundColor: "#EAEDF8",
        modalBackgroundColor: "#ffffff",
      },
      commissioning: {
        backgroundColorComponents: "#FFFFFF",
        primaryText: "#262626",
        secondaryText: "#262626cc",
        backgroundSwitch: "#acacac",
        switch: "#FFFFFF",
        border: "#8C8C8C",
        borderActive: "#1a1a1a",
        input: "#E6E6E6",
        textActive: "#1a1a1a",
        textInactive: "#8C8C8C",
        textHeaderTable: "#262626cc",
        TopBarDescription: "#F3F4F6",
      },

      nexus: {
        boxBackgroundColor: "#FFF",
        titleColor: "#050505",
        borderColor: "#eaedf8",
        selectBackgroundColor: "#E9EBF4",
        selectTextColor: "#6C6C71",
      },

      gamification: {
        cardBorder: "#E9EBF4",
        cardBackground: "#FFFFFF",
        inputBackground: "#E9EBF4",
        acceptMissionButtonBackground: "#1E1F23",
        acceptMissionButtonColor: "#ffffff",
        pageBackgroundColor: "#ffffff",
      },

      ranking: {
        cardBackgroundColor: "#FCFCFC",
        cardHoverBackgroundColor: "#F6F6F6",
        cardBorderColor: "#E9EBF4",
        cardNameColor: "#050505",
        cardExpColor: "#DBAB00",
        cardPositionColor: "#050505",
        cardPositionBackgroundColor: "#E0E2EB",
        lastTimeUpdatedCardBackgroundColor: "#FCFCFC",
        lastTimeUpdatedCardBorderColor: "#E9EBF4",
        lastTimeUpdatedCardTextColor: "#363636",
      },

      drip: {
        topicBackground: "#FFFFFF",
        iconContainer: "#F3F4F6",
        inputIconContainer: "#F3F4F6",
        inputBorder: "#E8E8E8",
      },
    },
    classwork: {
      backgroundColor: "#FFFFFF",
      primaryColor: "#E2E3E8",
      lesson: {
        backgroundColor: "#ffffff",
        buttonBackgroundColor: "#F3F4F6",
        inputBackgroundColor: "#F3F4F6",
        videoDetailsBackground: "#F3F4F6",
        navigationHover: "#F3F4F6",
        borderColor: "#E9EBF4",
      },
      imsersive: {
        backgroundColor: "#F6F6FC",
        buttonTextColor: "#050505",
        buttonBackgroundColor: "#FFFFFF",
        tagTextColor: "#000000",
        tagBackgroundColor: "#4949491F",
        dropdownTitle: "#000000",
        dripContentFlagTextColor: "#050505",
        dripContentFlagBackgroundColor: "#D2D4DF",
      },
    },
    orders: {
      cardBackground: "#FFF",
      modalBackground: "#F3F4F6",
      cardBorder: "#E9EBF4",
    },
    controlPanel: {
      backgroundControlPanel: "#F3F4F6",
      backgroundModalControlPanel: "#FFFFFF",
      backgroundModalControlPanelAdminConsole: "#FFF",
      backgroundSectionButtonModal: "#F3F4F6",
      backgroundColorTolltip: "#FFFFFF",
      secondaryBackgroundControlPanel: "#FFFFFF",
      backgroundColorTagsInfo: "#FFFFFF",
      backgroundButtonActive: "#FFFFFF",
      secondaryTextControlPanel: "#8C8C8C",
      borderTableControlPanel: "#F2F2F2",
      activeButtonText: "#303030",
      backgroundShadowModal: "rgb(255, 255, 255, 0.8)",
      backgroundColorRowTable: "#F2F2F2",
      backgroundDetailsControlPanel: "#F2F2F2",
      backgroundInputControlPanel: "#F3F4F6",
      bordersControlPanel: "#F2F2F2",
      backgroundButtonInactive: "#DFDFDF",
      tertiaryTextControlPanel: "#B3B3B3",
      backgroundTableModal: "#F3F4F5",
      backgroundInputValue: "#808080",
      textControlPanel: "#404040",
      iconsControlPanel: "#1a1a1a",
      secondaryButton: "#262626",
      firstTableBackground: "#FFFFFF",
      secondTableBackground: "#EBEBEC",
      primaryTextTable: "#666666",
      secondaryTextTable: "#262626",
      searchInputText: "#666666",
      textButtonWithdraw: "#ACACAC",
      borderButtonSectionStatement: "#E7E7E7",
      hoverButtonSectionStatement: "#E6E6E6",
      billboardBackgroundListItems: "#ffffff",
      billboardBackgroundButtonConfig: "#f2f2f2",
      disabledButtonText: "#C1C1C1",
      borderSecondaryButton: "#CBCBCB",
      backgroundIcon: "#EBEBEC",
      backgroundFilledInput: "#EBEBEC",
      textFilledInput: "#262626",
      borderCheckbox: "#8C8C8C",
      borderCharts: "#E9EBF4",
      borderChartsConsole: "#DADADA",
      primaryColorTableDetails: "#F2F2F2",
      secondaryColorTableDetails: "#FFFFFF",
      backgroundCard: "#FFFFFF",
      backgroundFilter: "#FFFFFF",
      backgroundSoonCard: "rgb(247, 247, 247)",
      buttonSeeOnEnsinio1: "#23A840",
      cardBorder: "#373940",
      buttonSeeOnEnsinio1Hover: "rgba(35, 168, 64, 0.8)",
      secondaryTopBarButton: "#ffffff",
      secondaryTopBarButtonHover: "#F0F0F0cc",
      backgroundTooltip: "#F2F2F2",
      backgroundImageFallback: "#d1d1d1",
      borderColorModeration: "#DADADA",

      groups: {
        containerBackground: "#FFFFFF",
        headerBackground: "#FFFFFF",
        inputColor: "#8c8c8c",
        titleRowColor: "#222222",
        textRowColor: "#262626",
        inputDetailsBackground: "#F3F4F6",
        inputTableBackground: "#FFFFFF",
        inputFocusColor: "#0E0E0E",
        moderatorTagBackground: "#F3F4F6",
        tableRowHoverColor: "#F3F4F6",
      },
      videos: {
        dateInputColor: "#222222",
        dateInputBackgroundColor: "#F3F4F6",
        videoInfoInSettingsPageColor: "#222222",
        videoCardNumbersColor: "#353535",
        videoCardTitleColor: "#222222",
        videoCardHoverBackgroundColor: "#FFFFFF",
        deleteVideoModalBackgroundColor: "#FFFFFF",
      },
      menu: {
        inputColor: "#8c8c8c",
        inputFocusColor: "#0E0E0E",
        inputBackgroundColor: "#F3F4F6",
        inputBackgroundColorDisabled: "#F2F2F2",
        boxBorderColor: "#F2F2F2",
        boxBackgroundColor: "#ffffff",
        textColor: "#303030",
        descriptionColor: "#8c8c8c",
        boxDescriptionColor: "#8c8c8c",
        boxLinkColor: "#6d6d6d",
        switchBackground: "#bfc2c7",
        pageBackgroundColor: "#f2f2f2",
      },
      tools: {
        containerBorderColor: "#E9EBF4",
        modalBackgroundColor: "#F2F2F2",
        modalBorderColor: "#E9EBF4",
        modalBackgroundColorHover: "#00000013",
        pixelInputColor: "#FFF",
        placeHolderColor: "#B3B3B3",
      },
    },
    console: {
      backgroundColor: "#FFF",
      cardHoverColor: "#EEF4FA",
      cardDescriptionColor: "#404040",
      pageTitleColor: "#050505",
      pageSubtitleColor: "#555",
      disabledFeatureButtonTextColor: "#FAFAFA",
      inputBorderColor: "#E9EBF4",
      inputColor: "#000",
      secondaryButtonBackground: "#F2F2F2",
      secondaryButtonTextColor: "#202020",
      tableBorderColor: "#E9EBF4",
      tableDotsOptionHover: "#F5F5F5",
      tableOptionsBackground: "#ffffff",
      tableOptionsBorder: "#EEF4FA",
      modalHover: "#00000013",
      modalBackground: "#E9EBF4",
      helpVideoHover: "#EFEFEF",
      setupGuideButton: "#EFEFEF",
      tableCoupons: "#FFF",
      tableCouponsHover: "#F5F5F5",
      dataCalendarBox: "#F3F4F6",
      infoBox: "#605CFF",
      sidebar: {
        backgroundColor: "#FFF",
        titleColor: "#050505",
        subSectionTitleColor: "#959595",
        borderColor: "#E9EBF4",
        tooltipBackgroundColor: "#333131",
        tooltipTextColor: "#FFF",
        iconButtonActiveBackgroundColor: "#EFEFEF",
        optionTextColor: "#050505",
        optionHoverBackgroundColor: "#EFEFEF",
        mobileSectionTextColor: "#000",
      },
      groups: {
        overview: {
          cardDescriptionColor: "#000000d9",
        },
      },
      design: {
        imageBackgroundColor: "#e9ebf4",
      },
    },
    trails: {
      backgroundColor: "#FFF",
      hoverContent: "#F2F2F2",
      cardsDescription: "#404040",
    },
    affiliates: {
      background: "#F3F4F6",
    },
    lexicalEditor: {
      fontPrimaryColor: "#050505",
      fontSecondaryColor: "#B2B8B8",
      modalBackground: "#EEF4FA",
      borderColor: "#E9EBF4",
    },
    myDocuments: {
      primaryDescription: "#050505",
      secondaryDescription: "#434343",

      border: "#E9EBF4",
      backgroundCards: "#FFFFFF",
      backgroundModal: "#FFFFFF",

      backgroundImageDocuments: "#EBEBEB",
      linesImageDocuments: "#C1C1C1",

      buttonRemove: "#F5F5F5",
    },
    commentsFeed: {
      editorBorder: "#E9EBF4",
      editorBackgroundColor: "#ffffff",
      fontSecondaryColor: "#B2B8B8",
      privateFontColor: "#654D10",
    },
  };
};

export default light;
