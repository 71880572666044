import React from "react";

import { GetServerSideProps } from "next";

import { parseCookies } from "nookies";

import { consoleService } from "pages/[tenant]/console/utils/service";

const CheckboxArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91869 9.71136L3.17719 7.0464L2 8.19072L5.91869 12L14 4.14432L12.8228 3L5.91869 9.71136Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { checkUserCredentials } = consoleService;
  const { username } = parseCookies(ctx);

  const { userIsAdmin, redirectUrl } = await checkUserCredentials(username);
  if (!userIsAdmin)
    return {
      redirect: {
        destination: redirectUrl,
        permanent: false,
      },
      props: {},
    };

  return {
    props: {},
  };
};
export default CheckboxArrow;
