import { useCallback } from "react";

import { setCookie } from "nookies";

import api from "shared/infra/services/api";
import { getCloudEndpoint } from "shared/utils/entities/tenant/getCloudEndpoint";
import useIsomorphicLayoutEffect from "shared/utils/hooks/useIsomorphicLayoutEffect";

import { captureException, captureMessage } from "@sentry/nextjs";

interface IUseCloudEndpoint {
  slug: string;
}

const useCloudEndpoint = ({ slug }: IUseCloudEndpoint) => {
  const THIRTY_DAYS = 30 * 24 * 60 * 60;
  const isAbleToHandleCloudEndpoint =
    slug && slug !== "undefined" && slug !== "g" && !slug.includes("browse");

  const handleCloudEndpoint = useCallback(() => {
    try {
      if (!isAbleToHandleCloudEndpoint) return;

      setCookie(null, "slug", slug, { maxAge: THIRTY_DAYS });

      if (slug !== "dev") {
        const host = getCloudEndpoint(slug);
        api.defaults.baseURL = `${host}/api/v1`;
      }
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that updates the tenant uuid in cookies [setCookie].";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [THIRTY_DAYS, isAbleToHandleCloudEndpoint, slug]);

  useIsomorphicLayoutEffect(() => {
    handleCloudEndpoint();
  }, [handleCloudEndpoint]);
};

export default useCloudEndpoint;
