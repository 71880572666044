import styled from "styled-components";

export const ModalDocumentsContainer = styled.div`
  @media screen and (min-width: 850px) {
    display: grid;
    grid-template-columns: 290px 1fr;

    align-items: center;
    justify-items: center;
    overflow: hidden;
  }
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  height: 100vh;
  width: 100vw;
  align-items: center;
`;

export const ModalDocumentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.myDocuments?.border};

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.controlPanel?.textControlPanel};
    cursor: pointer;
  }

  p {
    font-size: 1rem;
    font-weight: 500;

    margin-bottom: 0;
  }

  @media screen and (max-width: 800px) {
    .icon-close-modal {
      margin-right: 0.5rem;
      svg {
        font-size: 1rem;
      }
    }
  }

  .description-steps {
    display: flex;
    gap: 0.5rem;
  }
`;

interface IModalDocumentsContentProps {
  stepSmall: boolean;
}

export const ModalDocumentsContent = styled.div<IModalDocumentsContentProps>`
  grid-column: 2;

  max-width: ${(props) => (props.stepSmall ? "30rem" : "50rem")};

  min-width: 22rem;
  width: 100%;
  height: auto;
  background: ${(props) => props.theme?.myDocuments?.backgroundModal};
  top: 2rem;

  border-radius: 0.5rem;

  .content-documents {
    padding: 1.5rem;

    @media (max-height: 900px) {
      max-height: 500px;
    }

    @media (max-height: 750px) {
      max-height: 400px;
    }

    overflow-y: auto;
  }

  @media screen and (max-width: 850px) {
    margin-top: 5rem;
  }
`;

export const WrapperButtonsDocuments = styled.div`
  border-top: 1px solid ${({ theme }) => theme.myDocuments?.border};

  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  padding: 0.75rem 1.5rem;
`;
