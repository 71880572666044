import styled from "styled-components";

import { ConsolePageButtonStyles } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/styles";

import { consolePageMobileBreakpoint } from "@Console/hooks/useConsolePageConfig";

interface ITopFlagContainerProps {
  backgroundColor: string;
}

export const TopFlagContainer = styled.div<ITopFlagContainerProps>`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  height: fit-content;

  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1rem 1.5rem;

  @media (max-width: ${consolePageMobileBreakpoint}px) {
    flex-direction: column;
  }

  button {
    color: ${({ backgroundColor }) => backgroundColor};
  }

  p {
    margin: 0;

    color: white !important;
    font: 400 1rem/1.5rem "Inter";
  }

  b {
    color: white !important;
    font: 600 1rem/1.5rem "Inter";
  }

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  .left-text,
  .plan-info {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
`;

export const SeePlansButton = styled(ConsolePageButtonStyles)`
  background-color: white;
`;
