import * as Sentry from "@sentry/nextjs";

export enum STATUS {
    FULFILLED = 'fulfilled',
    REJECTED = 'rejected'
}

export const getAllSettledPromiseValue = <T>(
    result: PromiseSettledResult<T>,
    defaultValue: any,
  ): T => {
    if (result.status === STATUS.REJECTED) {
      console.log(result.reason);

      Sentry.captureException(result.reason);

      return defaultValue;
    }
    
    return result.value as T;
  };