import React, { useEffect, useMemo, useRef } from "react";

import Link from "next/link";

import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import { IOption, ISubSection } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { SideBarSectionContainer } from "./styles";

interface ISideBarSectionProps {
  label: string;
  icon: JSX.Element;
  options: IOption[];
  shouldHide?: boolean;
  shouldDisable?: boolean;
  subSections?: ISubSection[];
}

export const SideBarSection = ({
  label,
  icon,
  shouldHide,
  shouldDisable,
  options,
  subSections,
}: ISideBarSectionProps) => {
  const {
    activeSectionLabel,
    handleChangeActiveSection,
    setInfoToMoveActiveSectionIndicator,
    closeMobileSectionOptionsDropdown,
    clearOptionPathInRealTime,
  } = useConsolePageContext();

  const linkIsActive = activeSectionLabel === label;
  const className = linkIsActive ? "active" : "";
  const linkRef = useRef(null);
  const firstOptionThatIsNotHidden = useMemo(
    () =>
      options?.find((option) => !option.shouldHide)?.path ||
      subSections?.reduce(
        (acc, subSection) => [
          ...acc,
          subSection?.options?.find((option) => !option.shouldHide)?.path,
        ],
        [],
      )[0],
    [options, subSections],
  );
  const hasAtLeastOneOptionToShow = !!firstOptionThatIsNotHidden;

  useEffect(() => {
    const shouldMoveActiveSectionIndicatorWhenPageLoads =
      linkRef.current && linkIsActive;

    if (shouldMoveActiveSectionIndicatorWhenPageLoads)
      setInfoToMoveActiveSectionIndicator(linkRef.current);
  }, [linkRef, activeSectionLabel]);

  const handleClick = (e) => {
    if (shouldDisable) return e.preventDefault();

    handleChangeActiveSection(e);
    closeMobileSectionOptionsDropdown();
    clearOptionPathInRealTime();
  };

  const customId = removeSpacesAndAccentsFromString(label);

  if (shouldHide || !hasAtLeastOneOptionToShow) return null;
  return (
    <SideBarSectionContainer
      tooltipLabel={label}
      disabled={shouldDisable}
      id={customId}
    >
      <Link
        onClick={handleClick}
        data-label={label}
        className={className}
        href={firstOptionThatIsNotHidden}
        ref={linkRef}
      >
        {icon}
      </Link>
    </SideBarSectionContainer>
  );
};
