import React, { useContext, useEffect } from "react";

import { useRouter } from "next/router";

import { useConsolePageConfig } from "pages/[tenant]/console/hooks/useConsolePageConfig";
import DropDownGroups from "pages/[tenant]/g/components/ContextTopbar/components/DropDownGroups";
import { GroupNavigation } from "pages/[tenant]/g/components/GroupNavigation";

import BoxSetupGuide from "shared/components/atoms/BoxSetupGuide";
import { ComponentErrorBoundary } from "shared/components/atoms/ComponentErrorBoundary";
import { GlobalTopbarHeader } from "shared/providers/Layout/components/NewTopBar/components/atoms/GlobalTopbarHeader";
import { Logo } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo";
import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { Hamburguermenu } from "shared/providers/Layout/components/NewTopBar/components/molecules/HamburguerMenu";
import { Options } from "shared/providers/Layout/components/NewTopBar/components/molecules/Options";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import AuthContext from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import useDebounce from "shared/utils/hooks/useDebounce";
import { useFeature } from "shared/utils/hooks/useFeature";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import { TopBarCheckout } from "@payment/components/TopbarCheckout";

import {
  NewTopBarContainer,
  SideOptionsContainer,
  TopBarBackground,
} from "./styles";

export const NewTopBar = () => {
  const { isInConsolePage } = useConsolePageConfig();
  const { setScrollIsOnTop, scrollIsOnTop, pageIsLoading } = useTopbarContext();

  const { asPath, pathname } = useRouter();
  const currentPath = asPath.split("/")[1];

  const { width } = useWindowSize();
  const isMobile = width <= 850;

  const { state } = useContext(GlobalContext);
  const { loggedUser } = useContext(AuthContext);
  const isMaster = loggedUser?.id === 2;
  const completedGuide = state?.tenancy?.has_completed_guide;

  const isSetupGuideEnabled = useFeature("f_setup_guide");

  const isInGroupRoute = pathname.includes("/[tenant]/g/[group_slug]");
  const isInAboutGroup = pathname.includes("/[tenant]/g/[group_slug]/about");

  const isCheckoutPage = pathname.includes("/payment");

  const shouldShowGroupNavigationComponents = isInGroupRoute && !isInAboutGroup;

  const debouncedFunction = useDebounce();
  const checkIfScrollIsInTop = () => {
    const scrollLimitToTrigger = 130;
    const scrollIsBelowTop = window.scrollY <= scrollLimitToTrigger;

    if (scrollIsBelowTop) setScrollIsOnTop(true);
    else setScrollIsOnTop(false);
  };

  const handleScroll = () => debouncedFunction(checkIfScrollIsInTop, 10);

  useEffect(() => {
    if (!isMobile && !isInConsolePage)
      window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const pageHaveMedia = currentPath === "browse";

  if (isInConsolePage) return null;

  if (isCheckoutPage) return <TopBarCheckout />;

  return (
    <>
      {!completedGuide && isSetupGuideEnabled && isMaster && (
        <ComponentErrorBoundary>
          <BoxSetupGuide isInConsolePage={isInConsolePage || pageHaveMedia} />
        </ComponentErrorBoundary>
      )}

      <TopBarBackground
        shouldHaveBackground={!scrollIsOnTop || !pageHaveMedia}
        setupGuide={!completedGuide && isMaster && isSetupGuideEnabled}
        isMobile={isMobile}
      >
        <NewTopBarContainer
          shouldHaveBackground={scrollIsOnTop}
          pageHaveMedia={pageHaveMedia}
          isMobile={isMobile}
          pageIsLoading={pageIsLoading}
        >
          {isMobile ? (
            <ComponentErrorBoundary>
              {shouldShowGroupNavigationComponents ? (
                <DropDownGroups />
              ) : (
                <GlobalTopbarHeader />
              )}
            </ComponentErrorBoundary>
          ) : (
            <>
              <SideOptionsContainer scrollIsOnTop={scrollIsOnTop}>
                <ComponentErrorBoundary>
                  <Hamburguermenu />
                </ComponentErrorBoundary>

                <ComponentErrorBoundary>
                  <MenusList shouldLimitMenuItensLength={true} />
                </ComponentErrorBoundary>
              </SideOptionsContainer>

              <ComponentErrorBoundary>
                <Logo />
              </ComponentErrorBoundary>

              <ComponentErrorBoundary>
                <Options />
              </ComponentErrorBoundary>
            </>
          )}
        </NewTopBarContainer>
      </TopBarBackground>
      {shouldShowGroupNavigationComponents && (
        <ComponentErrorBoundary>
          <GroupNavigation />
        </ComponentErrorBoundary>
      )}
    </>
  );
};
