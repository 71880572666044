import styled from "styled-components";

import { setVerticalSettings } from "shared/utils/setVerticalSettings";

const { height, width } = setVerticalSettings(false);

export const AliceNotFound = styled.div`
  height: ${height}px;
  width: ${width}px;

  display: grid;
  place-items: center;

  border-radius: 4px;
  border: 3px solid transparent;

  transition: all 0.2s ease-in-out;

  margin: 1rem 0 1rem 52px;

  :hover {
    border: 3px solid ${({ theme }) => theme.colors.textColor};
  }

  svg {
    height: 3rem;
    width: 3rem;

    * {
      fill: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
