import React from "react";

export const UnplishedPlatformIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0004 63.3326C43.1538 63.3326 46.0337 62.9893 48.6604 62.3959L42.8037 56.5392C41.8937 56.6092 40.9704 56.6659 40.0004 56.6659C22.1638 56.6659 15.2537 43.8459 13.5804 39.9992C14.8369 37.1953 16.5339 34.6104 18.6071 32.3426L13.9471 27.6826C8.82042 33.2392 6.87708 38.8359 6.84042 38.9459C6.61049 39.6305 6.61049 40.3714 6.84042 41.0559C6.91042 41.2759 14.5571 63.3326 40.0004 63.3326ZM40.0004 16.6659C33.8771 16.6659 28.8471 17.9859 24.6538 19.9359L12.3571 7.64258L7.64375 12.3559L67.6437 72.3559L72.3571 67.6426L61.2938 56.5793C70.0071 50.0759 73.1171 41.1959 73.1637 41.0559C73.3937 40.3714 73.3937 39.6305 73.1637 38.9459C73.0904 38.7226 65.4437 16.6659 40.0004 16.6659ZM56.5737 51.8592L48.9738 44.2592C49.6071 42.9592 50.0004 41.5292 50.0004 39.9992C50.0004 34.5292 45.4704 29.9992 40.0004 29.9992C38.4704 29.9992 37.0404 30.3926 35.7437 31.0292L29.7171 25.0026C33.0255 23.8673 36.5028 23.3026 40.0004 23.3326C57.8371 23.3326 64.7471 36.1526 66.4204 39.9992C65.4138 42.3059 62.5338 47.8059 56.5737 51.8592Z"
        fill="currentColor"
      />
    </svg>
  );
};
