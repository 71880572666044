import { IErrorMap } from "shared/types/monitoringPageTypes";
import { getPreviousURLFromSessionStorage } from "shared/utils/handleURLInSessionStorage";

import { captureException, captureMessage } from "@sentry/nextjs";

const errorMessagesFromVercel = [
  "exception has occurred",
  "this page could not be found",
  "deu errado",
  "ops! essa página não existe",
] as const;

export const getWhichErrorIsInPage = (): string => {
  try {
    const htmlDocumentInString = getHTMLDocumentInString();

    const errorMap: IErrorMap[] = errorMessagesFromVercel.reduce(
      (acc, message, index) => [
        ...acc,
        { id: index, message: message?.toLowerCase() },
      ],
      [],
    );

    const errorInScreen = errorMap.find((error) =>
      htmlDocumentInString?.includes(error?.message),
    );

    return errorInScreen?.message;
  } catch (error) {
    console.log("Error (getWhichErrorIsInPage): ", error);
    captureException(error);
    captureMessage("Error (getWhichErrorIsInPage)");

    return "";
  }
};

const getHTMLDocumentInString = (): string => {
  try {
    if (typeof window === "undefined") return;

    return document?.body?.querySelector("#__next")?.innerHTML?.toLowerCase();
  } catch (error) {
    console.log("Error (getHTMLDocumentInString): ", error);
    captureException(error);
    captureMessage("Error (getHTMLDocumentInString)");

    return "";
  }
};

const checkIfStringHasSomeErrorMessage = (string: string): boolean =>
  errorMessagesFromVercel.some((errorMessage) =>
    string?.includes(errorMessage),
  );

export const checkIfHTMLDocumentHasSomeErrorMessage = (): boolean => {
  const documentHTMLInString = getHTMLDocumentInString();

  const documentHTMLHasSomeErrorMessage =
    checkIfStringHasSomeErrorMessage(documentHTMLInString);

  return documentHTMLHasSomeErrorMessage;
};

export const getPrevURL = (): string => {
  try {
    if (typeof window === "undefined") return;

    return getPreviousURLFromSessionStorage();
  } catch (error) {
    console.log("Error (getPrevURL): ", error);
    captureException(error);
    captureMessage("Error (getPrevURL)");

    return "";
  }
};

export const getCurrentPageHref = (): string => {
  try {
    if (typeof window === "undefined") return;

    return window?.location?.href;
  } catch (error) {
    console.log("Error (getCurrentPageHref): ", error);
    captureException(error);
    captureMessage("Error (getCurrentPageHref)");

    return "";
  }
};
