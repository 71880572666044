import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthContext from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import { useFeature } from "shared/utils/hooks/useFeature";

import {
  getAllCollectionsRequest,
  getFilterRequest,
  getMoreCollectionsRequest,
  getSingleCollectionRequest,
  removeCollectionRequest,
  removeItemCollectionsRequest,
  setCollectionItemPositionRequest,
  setCollectionPositionRequest,
  setCollectionRequest,
  setItemsCollectionsRequest,
  setPrivateCollectionsRequest,
  updateCollectionRequest,
} from "../services";

export interface CollectionContextData {
  loader: boolean;
  collection: any;
  collections: any;
  entityType: any;
  setCollection: any;
  setCollections: any;
  resourceOrder: any;
  newContentAdded: any;
  collectionsMeta: any;
  removeCollection: any;
  updateCollection: any;
  createCollection: any;
  notificationType: string;
  setCollectionType: any;
  setItemCollections: any;
  loadMoreCollections: any;
  setNotificationType: any;
  getSingleCollection: (id: any, slug: string | string[]) => void;
  setPrivateCollection: (id: any) => void;
  isCreatingCollection: boolean;
  removeItemCollections: any;
  dynamicCollectionFilter: any;
  setCollectionPosition: any;
  setCollectionItemPosition: any;
  getAllCollections: () => void;
}

interface CollectionProviderProps {
  children: ReactNode;
}

export const CollectionContext = createContext({} as CollectionContextData);

export function CollectionProvider({ children }: CollectionProviderProps) {
  const { state } = useContext(GlobalContext);
  const { loggedUser } = useContext(AuthContext);
  const [loader, setLoader] = useState(true);
  const [collections, setCollections] = useState<any>([]);
  const [collectionsMeta, setCollectionsMeta] = useState(null);
  const [collection, setCollection] = useState(null);
  const [notificationType, setNotificationType] = useState("none");
  const [resourceOrder, setResourceOrder] = useState({});
  const [entityType, setEntityType] = useState({});
  const [newContentAdded, setNewContentAdded] = useState<any>(null);

  const [isCreatingCollection, setIsCreatingCollection] = useState(false);

  const isCollectionsEnabled = useFeature("f_collections");

  const isNewProduct =
    state?.tenancy?.settings?.site?.ensinioNewFeaturesEnable ||
    state?.tenancy?.settings?.others?.ensinioNewFeaturesEnable;

  async function getAllCollections() {
    setLoader(true);

    const results = await getAllCollectionsRequest();
    setCollections(results?.data);
    setCollectionsMeta(results?.meta);
    setNewContentAdded(null);
    setLoader(false);
  }

  async function getSingleCollection(id: any, slug: string) {
    setLoader(true);
    const result = await getSingleCollectionRequest(id, slug);
    setCollection(result);
    setLoader(false);
  }

  async function dynamicCollectionFilter(options: any) {
    setLoader(true);
    const results = await getFilterRequest(options);
    setCollections(results?.data);
    setCollectionsMeta(results?.meta);
    setLoader(false);
  }

  async function updateCollection(id: any, name: string, description: string) {
    await updateCollectionRequest(id, name, description);
  }

  async function removeCollection(id: any) {
    const newList = collections.filter(
      (collection: any) => collection.id !== id,
    );

    setCollections(newList);
    await removeCollectionRequest(id);
    setNotificationType("success");
  }

  async function setPrivateCollection(id: number) {
    await setPrivateCollectionsRequest(id);
  }

  async function setCollectionItemPosition(idArrays: any, idCollection: any) {
    setResourceOrder({ idCollection, idArrays });
    await setCollectionItemPositionRequest(idArrays, idCollection);
  }

  async function setCollectionPosition(idArrays: any) {
    await setCollectionPositionRequest(idArrays);
  }

  async function setItemCollections({ className, item, collections }: any) {
    const content = await setItemsCollectionsRequest({
      item,
      className,
      collections,
    });

    setNewContentAdded(content);
  }

  async function removeItemCollections({ className, item, collections }: any) {
    await removeItemCollectionsRequest({ className, item, collections });
    setNewContentAdded(null);
  }

  async function setCollectionType(entityType: string, id: any) {
    setEntityType({ entityType, id });
  }

  async function createCollection({ name, description }: any) {
    setIsCreatingCollection(true);
    const newCollection = await setCollectionRequest({ name, description });

    setCollections((prevState) => [newCollection, ...prevState]);
    setIsCreatingCollection(false);
  }

  async function loadMoreCollections(page: any) {
    const results = await getMoreCollectionsRequest(page);

    if (results?.data) {
      setCollections([...collections, ...results?.data]);
      setCollectionsMeta(results?.meta);
    }
  }

  useEffect(() => {
    if (loggedUser && isCollectionsEnabled && !isNewProduct) {
      getAllCollections();
    }
  }, [loggedUser]);

  return (
    <CollectionContext.Provider
      value={{
        loader,
        collection,
        entityType,
        collections,
        resourceOrder,
        setCollection,
        setCollections,
        newContentAdded,
        collectionsMeta,
        updateCollection,
        removeCollection,
        createCollection,
        notificationType,
        setCollectionType,
        getAllCollections,
        setItemCollections,
        setNotificationType,
        getSingleCollection,
        loadMoreCollections,
        setPrivateCollection,
        isCreatingCollection,
        removeItemCollections,
        dynamicCollectionFilter,
        setCollectionPosition,
        setCollectionItemPosition,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
}

export default CollectionContext;
