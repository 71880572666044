import axios from "axios";

const response = { data: [] };

type TResponseType = typeof response;

export const getCustomScripts = async (
  endpoint: string,
): Promise<TResponseType> => {
  const { data } = await axios.get(`${endpoint}/api/v1/custom-script`);

  if (data?.data) response.data = data?.data;
  else response.data = data;

  return response;
};
