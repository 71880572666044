import api from "shared/infra/services/api";

const params = {
  "order[0][index]": "created_at",
  "order[0][value]": "desc",
};

export const getUser = async ({ userId, headers }) => {
  try {
    const response = await api.get(`/console/users/${userId}`, { headers });
    return response.data.data;
  } catch (error) {
    console.log("getUser:", error);
    return null;
  }
};

export function getCurrentURL() {
  const actualURL = window.location.href;
  if (actualURL.includes("/console/users/active")) {
    return "/active";
  } else if (actualURL.includes("/console/users/disabled")) {
    return "/inactive";
  } else {
    return "";
  }
}

export const getUserGroupsPagination = async (userId, page) => {
  try {
    const response = await api.get(
      `/console/users/${userId}/groups?perPage=15&page=${page + 1}`,
      { params },
    );
    return response.data;
  } catch (error) {
    console.log("getUser:", error);
    return [];
  }
};

export const getUserGroups = async (userId) => {
  try {
    const response = await api.get(`/console/users/${userId}/groups`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log("getUser:", error);
    return [];
  }
};

export const getUserTransaction = async (userId) => {
  try {
    const response = await api.get(`/console/users/${userId}/transactions`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.log("getUser:", error);
    return [];
  }
};

export const getUserTransactionPagination = async (userId, page) => {
  try {
    const response = await api.get(
      `/console/users/${userId}/transactions?perPage=15&page=${page + 1}`,
      {
        params,
      },
    );
    return response.data;
  } catch (error) {
    console.log("getUser:", error);
    return [];
  }
};

export const isEmail = (string: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(string);
};

export const formatDocument = (value) => {
  const ArrayOfStr = value.split("");
  const newValueStr = ArrayOfStr.reduce(
    (acc, elem) =>
      acc.length === 3 || acc.length === 7
        ? acc + "."
        : acc.length === 11
        ? acc + "-"
        : (acc += elem),
    "",
  );
  return newValueStr;
};

export const formatCNPJ = (v) => {
  let formatedValue = v;
  formatedValue = formatedValue?.replace(/\D/g, "");
  formatedValue = formatedValue?.replace(/^(\d{2})(\d)/, "$1.$2");
  formatedValue = formatedValue?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  formatedValue = formatedValue?.replace(/\.(\d{3})(\d)/, ".$1/$2");
  formatedValue = formatedValue?.replace(/(\d{4})(\d)/, "$1-$2");
  return formatedValue;
};

export const formatCPF = (v) => {
  let formatedValue = v;
  formatedValue = formatedValue?.replace(/\D/g, "");
  formatedValue = formatedValue?.replace(/(\d{3})(\d)/, "$1.$2");
  formatedValue = formatedValue?.replace(/(\d{3})(\d)/, "$1.$2");
  formatedValue = formatedValue?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return formatedValue;
};

export const formatDate = (v) => {
  let formatedValue = v;
  formatedValue = formatedValue?.replace(/\D/g, "");
  formatedValue = formatedValue?.replace(/(\d{2})(\d)/, "$1/$2");
  formatedValue = formatedValue?.replace(/(\d{2})(\d)/, "$1/$2");
  formatedValue = formatedValue?.replace(/(\d{4})(\d{1,2})$/, "$1/$2");
  return formatedValue;
};

export const formatCEP = (v) => {
  let formatedValue = v;
  formatedValue = formatedValue?.replace(/\D/g, "");
  formatedValue = formatedValue?.replace(/(\d{5})(\d{0,3})/, "$1-$2");
  return formatedValue;
};

export const validaCpfCnpj = (val) => {
  if (val?.length == 14) {
    let cpf = val?.trim();

    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace("-", "");
    cpf = cpf.split("");

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (let i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val?.length == 18) {
    let cnpj = val?.trim();

    cnpj = cnpj.replace(/\./g, "");
    cnpj = cnpj.replace("-", "");
    cnpj = cnpj.replace("/", "");
    cnpj = cnpj.split("");

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
