import { useContext } from "react";

import api from "shared/infra/services/api";
import AuthContext from "shared/providers/context/AuthContext";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

function convertDateFormat(dateString: string): string {
  const [day, month, year] = dateString.split("/");
  return `${year}/${month}/${day}`;
}

export const useSendDocuments = () => {
  const { loggedUser } = useContext(AuthContext);
  const { setLoading, setStatusSendDocuments } = useDocumentsContext();
  const id_update = loggedUser?.personal_documents?.id;
  const personalDocuments = loggedUser?.personal_documents;

  const sendDocuments = async (companyData: any) => {
    const formData = new FormData();
    for (const key in companyData) {
      formData.append(key, companyData[key]);
    }
    formData.append("birthday", convertDateFormat(companyData?.birthdate));
    formData.delete("birthdate");

    try {
      setLoading(true);

      let response;

      if (personalDocuments === null) {
        response = await api.post(`personal-document/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (personalDocuments?.status === "disapproved") {
        response = await api.post(`personal-document/${id_update}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      if (response.status === 200) {
        setStatusSendDocuments("success");
      }
      return response;
    } catch (err) {
      if (err?.response?.data?.errors) {
        setStatusSendDocuments("Error");
        const errors = err.response.data.errors;
        const errorMessages = Object.values(errors).flat();
        const errorMessage = errorMessages.join("\n");
        openNotificationWithIcon("error", errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    sendDocuments,
  };
};
