import React from "react";

export const UserBadge = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.988 22C18.5184 22 19.0271 21.7893 19.4022 21.4142C19.7773 21.0391 19.988 20.5304 19.988 20V4C19.988 3.46957 19.7773 2.96086 19.4022 2.58579C19.0271 2.21071 18.5184 2 17.988 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H17.988ZM9 5H15V7H9V5ZM14.25 11.25C14.2476 11.8461 14.0098 12.4171 13.5884 12.8387C13.167 13.2603 12.5961 13.4984 12 13.501C10.765 13.501 9.75 12.486 9.75 11.25C9.75 10.014 10.765 9 12 9C12.596 9.00237 13.1669 9.24018 13.5884 9.66163C14.0098 10.0831 14.2476 10.654 14.25 11.25ZM7.5 18.188C7.5 16.524 9.528 14.813 12 14.813C14.472 14.813 16.5 16.524 16.5 18.188V18.751H7.5V18.188Z"
        fill="#C8C8C9"
      />
    </svg>
  );
};
