import styled, {css}from "styled-components";

type BoxProps = {
  $isInConsolePage?: boolean;
}

export const ContainerGuide = styled.div<BoxProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #042c44;
  padding: 1.125rem 1.25rem;
  z-index: 98;
  gap: 10px;

  :hover {
    cursor: pointer;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: white
  }

  button {
    font: 600 0.813rem "inter";
    color: #050505;
    padding: 0.375rem 1.25rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
   @media screen and (max-width: 600px) {
      cursor: pointer;
    }

    ${({ $isInConsolePage }) =>
      $isInConsolePage &&
      css`
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 5;
      `}
  
`;

export const InfoContainer = styled.div`
display: flex;
align-items: center;
gap: 1.25rem;

p {
  display: flex;
  gap: 0.5rem;
  font: 600 0.813rem "inter";
  color: #FFFFFF !important;
  margin: 0;
  @media screen and (max-width: 600px) {
      flex-direction: column;
    }
}

span {
  font: 400 0.813rem "inter";
  color: rgba(255, 255, 255, 0.75);
}
`
