import React from "react";

export const PlanLinkIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6.75C15.75 6.55109 15.671 6.36032 15.5303 6.21967C15.3897 6.07902 15.1989 6 15 6H12.75V5.25C12.75 3.18225 11.0677 1.5 9 1.5C6.93225 1.5 5.25 3.18225 5.25 5.25V6H3C2.80109 6 2.61032 6.07902 2.46967 6.21967C2.32902 6.36032 2.25 6.55109 2.25 6.75V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM6.75 5.25C6.75 4.0095 7.7595 3 9 3C10.2405 3 11.25 4.0095 11.25 5.25V6H6.75V5.25ZM3.75 7.5H5.25V9H6.75V7.5H11.25V9H12.75V7.5H14.25L14.2515 15H3.75V7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
