import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";
import { IdentificationCnpjPart } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/Content/CnpjIdentification/components/IdentificationCnpjPart";

import CircularLoader from "shared/components/atoms/CircularLoader";

import { MessageError } from "@Console/assets/MessageError";
import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import {
  ContainerAlertData,
  ContainerCnpjIdentification,
  ContentCnpjIdentification,
} from "./styles";

export const CnpjIdentification = () => {
  const {
    checkboxStepCNPJ,
    setCheckboxStepCNPJ,
    loading,
    isValidationCNPJ,
    cnpjData,
  } = useDocumentsContext();

  const handleChange = () => {
    setCheckboxStepCNPJ(true);
  };

  return (
    <ContainerCnpjIdentification>
      {loading ? (
        <CircularLoader />
      ) : isValidationCNPJ ? (
        <>
          <DescriptionModalDocuments
            title="Identificação do CNPJ"
            description="Os dados abaixo correspondem com o CNPJ informado?"
          />
          <ContainerAlertData>
            <MessageError />
            <span>
              Certifique-se que os dados estão corretos antes de continuar.
            </span>
          </ContainerAlertData>

          <ContentCnpjIdentification>
            <IdentificationCnpjPart
              title={"CNPJ"}
              identification={cnpjData?.ni}
            />
            <IdentificationCnpjPart
              title={"Endereço"}
              identification={
                cnpjData?.endereco?.tipoLogradouro +
                cnpjData?.endereco?.logradouro
              }
            />
            <IdentificationCnpjPart
              title={"Razão Social"}
              identification={cnpjData?.nomeEmpresarial}
            />
            <IdentificationCnpjPart
              title={"Número"}
              identification={cnpjData?.endereco?.numero}
            />
            <IdentificationCnpjPart
              title={"Nome Fantasia"}
              identification={cnpjData?.nomeFantasia}
            />
            <IdentificationCnpjPart
              title={"Estado"}
              identification={cnpjData?.endereco?.uf}
            />
            <IdentificationCnpjPart
              title={"País"}
              identification={cnpjData?.endereco?.pais?.descricao}
            />
            <IdentificationCnpjPart
              title={"Cidade"}
              identification={cnpjData?.endereco?.municipio?.descricao}
            />
            <IdentificationCnpjPart
              title={"Situação Cadastral"}
              identification="Ativo"
            />
            <IdentificationCnpjPart
              title={"Bairro"}
              identification={cnpjData?.endereco?.bairro}
            />
            <IdentificationCnpjPart
              title={"CEP"}
              identification={cnpjData?.endereco?.cep}
            />
          </ContentCnpjIdentification>
          <div className="checkbox-aware">
            <ConsolePageForm.Checkbox
              handleChange={handleChange}
              checked={checkboxStepCNPJ}
            />
            <span>
              Estou ciente que o recebimento das vendas será atrelado a uma
              conta bancária vinculada ao CNPJ informado
            </span>
          </div>
        </>
      ) : (
        <ContainerAlertData>
          <div>
            <MessageError />
          </div>
          <p>
            Não é possível prosseguir com o processo de cadastro devido à
            inadequação dos dados do CNPJ aos critérios estabelecidos para a
            conclusão do procedimento.
          </p>
        </ContainerAlertData>
      )}
    </ContainerCnpjIdentification>
  );
};
