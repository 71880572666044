const removeSpaceFromString = (string: string): string =>
  string?.replace(/\s+/g, "-");

const replaceCharactersWithAccentWithNormalCharacters = (
  stringWithAccents: string,
) => stringWithAccents?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");

export const removeSpacesAndAccentsFromString = (dryString: string): string => {
  const dryStringInLowerCase = `admin-console-${dryString?.toLowerCase()}`;
  const stringWithoutSpaces = removeSpaceFromString(dryStringInLowerCase);
  const cleanString =
    replaceCharactersWithAccentWithNormalCharacters(stringWithoutSpaces);

  return cleanString;
};
