import styled from "styled-components";

export const CustomButtonRedirect = styled.button`
  &.custom-button-redirect {
    padding: 0.38rem 0.75rem;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    @media (max-width: 1099px) {
      width: auto;
      padding-top: 0.5rem;
    }
    @media (max-width: 850px) {
      width: auto;
      margin-top: 0;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.primary}D9!important;
  }
`;
