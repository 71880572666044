import React from "react";

export const SadFaceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM7 10.5C7.00013 10.102 7.15835 9.72044 7.43984 9.43913C7.57922 9.29984 7.74467 9.18937 7.92675 9.11403C8.10882 9.03868 8.30395 8.99993 8.501 9C8.69805 9.00007 8.89315 9.03894 9.07518 9.11441C9.2572 9.18988 9.42258 9.30046 9.56187 9.43984C9.70116 9.57922 9.81163 9.74467 9.88697 9.92675C9.96232 10.1088 10.0011 10.304 10.001 10.501C10.0009 10.899 9.84265 11.2806 9.56116 11.5619C9.27967 11.8432 8.89796 12.0011 8.5 12.001C8.10204 12.0009 7.72044 11.8427 7.43913 11.5612C7.15783 11.2797 6.99987 10.898 7 10.5ZM8 17C8 17 9 14 12 14C15 14 16 17 16 17H8ZM15.493 11.986C15.2936 11.9911 15.0952 11.9562 14.9095 11.8834C14.7239 11.8106 14.5546 11.7014 14.4118 11.5621C14.269 11.4229 14.1556 11.2565 14.0781 11.0727C14.0007 10.8889 13.9608 10.6914 13.9609 10.492C13.9609 10.2925 14.0009 10.0951 14.0785 9.91138C14.1561 9.72764 14.2697 9.56129 14.4125 9.42214C14.5554 9.283 14.7247 9.17387 14.9105 9.1012C15.0962 9.02852 15.2946 8.99377 15.494 8.999C15.8834 9.00921 16.2534 9.1711 16.5252 9.45018C16.797 9.72926 16.949 10.1034 16.9489 10.493C16.9487 10.8825 16.7965 11.2566 16.5245 11.5355C16.2525 11.8144 15.8824 11.9761 15.493 11.986Z"
        fill="white"
      />
    </svg>
  );
};
