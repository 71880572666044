import { captureException } from "@sentry/nextjs";
import { parseCookies } from "nookies";

const getUsernameFromCookies = (): string | null => {
  try {
    const username = parseCookies(null).username;

    if (username) {
      const parseToken = JSON.parse(username);
      return parseToken;
    }
  } catch (error) {
    console.log("[getUsernameFromCookies]: an error occurred in the getUsernameFromCookies function.", error);
    captureException(error);
  }

  return null;
};

export default getUsernameFromCookies;
