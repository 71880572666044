import styled, { css } from "styled-components";

import { ConsolePageButtonStyles } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/styles";
import { consolePrimaryColor } from "pages/[tenant]/console/styles/consoleGlobalColors";

interface IConsolePageSwitchContainerProps {
  isOn: boolean;
  isInvalid: boolean;
}

export const ConsolePageSwitchContainer = styled(
  ConsolePageButtonStyles,
)<IConsolePageSwitchContainerProps>`
  padding: 0;
  position: relative;

  overflow: hidden;

  width: 54px;
  height: 28px;

  border-radius: 50px;
  box-shadow: inset 0px 0px 0px 2px #c8c8c9;

  &,
  .toggle-circle {
    transition: 0.3s all;
  }

  :active,
  :focus {
    scale: initial !important;
  }

  .toggle-circle {
    width: 18px;
    height: 18px;

    border-radius: 50%;

    background-color: #c8c8c9;

    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
  }

  ${({ isOn }) =>
    isOn &&
    css`
      box-shadow: inset 0px 0px 0px 2px ${consolePrimaryColor};

      .toggle-circle {
        left: 58%;
        background-color: ${consolePrimaryColor};
      }
    `}

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      box-shadow: inset 0px 0px 0px 2px red;

      .toggle-circle {
        background-color: red;
      }
    `}

  ~ .label {
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};
    font: 400 0.8125rem/1.21875rem "Inter";
  }
`;

export const SwitchLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  width: fit-content;

  :hover:not(:has(:disabled)) {
    cursor: pointer;
  }

  :has(:disabled) {
    cursor: not-allowed;
  }
`;
