import { InputMyDocuments } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/components/InputMyDocuments";

import { ContainerAddressFormMyDocuments } from "./styles";

interface UserData {
  [key: string]: any;
}

export const AddressDetails = ({
  setCompanyData,
  companyData,
  setErrors,
  errors,
}) => {
  const changeEditUserData = ({ key, value }: { key: string; value: any }) => {
    setCompanyData((prev: UserData) => ({
      ...prev,
      [key]: value,
    }));

    if (
      (companyData?.residencial_street?.length > 4,
      companyData?.residencial_neighborhood?.length >= 3,
      companyData?.residencial_city?.length >= 3,
      companyData?.residencial_uf?.length >= 2)
    ) {
      setErrors((prevErrors) => {
        const { residencial_cep, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };
  return (
    <ContainerAddressFormMyDocuments>
      <div className="address-company">
        <div className="info-company">
          <InputMyDocuments
            label="Rua"
            value={companyData?.residencial_street}
            onChange={(e) =>
              changeEditUserData({
                key: "residencial_street",
                value: e.target.value,
              })
            }
            required
            id="residencial_street"
            autoComplete="off"
            isInvalid={"residencial_cep" in errors}
          />

          <InputMyDocuments
            label="Bairro"
            value={companyData?.residencial_neighborhood}
            onChange={(e) =>
              changeEditUserData({
                key: "residencial_neighborhood",
                value: e.target.value,
              })
            }
            required
            id="residencial_neighborhood"
            isInvalid={"residencial_cep" in errors}
          />
        </div>

        <div className="info-company">
          <InputMyDocuments
            label="Cidade"
            value={companyData?.residencial_city}
            onChange={(e) =>
              changeEditUserData({
                key: "residencial_city",
                value: e.target.value,
              })
            }
            required
            id="residencial_city"
            isInvalid={"residencial_cep" in errors}
          />

          <InputMyDocuments
            label="Estado"
            value={companyData?.residencial_uf}
            onChange={(e) =>
              changeEditUserData({
                key: "residencial_uf",
                value: e.target.value,
              })
            }
            required
            id="residencial_uf"
            isInvalid={"residencial_cep" in errors}
          />
        </div>
      </div>
    </ContainerAddressFormMyDocuments>
  );
};
