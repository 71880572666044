import styled, { css } from "styled-components";

import { consolePrimaryColor } from "pages/[tenant]/console/styles/consoleGlobalColors";

interface IConsolePageTextareaContainerProps {
  isInvalid: boolean;
}

export const ConsolePageTextAreaContainer = styled.div<IConsolePageTextareaContainerProps>`
  position: relative;
  width: 100%;

  label {
    position: absolute;
    display: flex;
    align-items: center;

    pointer-events: none;

    font: 600 0.8125rem/1.21875rem "Inter";
    color: #6c6c71;

    width: 100%;
    left: 0;
    top: 0;

    background-color: ${({ theme }) => theme.console.backgroundColor};

    border: 1px solid ${({ theme }) => theme.console.inputBorderColor};
    border-bottom: 0;

    border-radius: 4px 4px 0px 0px;
    padding: 0.5rem 1rem;

    transition: 0.3s all;

    .required-flag {
      color: #ec3434;
      font: 600 0.625rem/0.01563rem "Inter";
      margin-left: 0.2rem;
    }
  }

  textarea {
    padding: 0rem 1rem;
    padding-top: 2rem;

    min-height: 4rem;
    height: 11rem;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.console.backgroundColor};

    width: 100%;

    color: ${({ theme }) => theme.console.inputColor};
    transition: 0.3s opacity, 0.3s border, 0.3s box-shadow;

    font: 600 0.875rem/1.3125rem "Inter";

    height: 48px;

    border: 1px solid ${({ theme }) => theme.console.inputBorderColor};
  }

  :has(textarea:disabled) {
    textarea {
      cursor: not-allowed;
      opacity: 0.7;
    }

    label {
      border-color: transparent;
    }
  }

  ${({ isInvalid }) =>
    isInvalid
      ? css`
          textarea {
            box-shadow: 0px 0px 0px 2px red;
          }

          label {
            color: red !important;
          }

          ::before {
            color: red !important;
          }
        `
      : css`
          :has(textarea:is(:focus, :active):not(:disabled)) {
            label {
              color: ${consolePrimaryColor};
            }

            textarea {
              box-shadow: 0px 0px 0px 2px ${consolePrimaryColor};
            }
          }
        `}
`;
