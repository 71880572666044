import React, { MouseEvent, useState } from "react";

import { useRouter } from "next/router";

import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { CustomButtonRedirect } from "./styles";

interface ICustomButtonCTA {
  triggerOnLinkClick?: () => void;
}

export const CustomButtonCTA = ({ triggerOnLinkClick }: ICustomButtonCTA) => {
  const { loggedUser } = useAuthContext();
  const { state } = useGlobalContext();
  const router = useRouter();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const subscribeNowCta = loggedUser
    ? loggedUser?.subscribe_now_cta
    : state?.tenancy?.subscribe_now_cta;

  const subscribeNowCtaEnable = subscribeNowCta?.show;

  const groupRedirect = subscribeNowCta?.group_slug;
  const customButtonRedirectEnable = subscribeNowCtaEnable && groupRedirect;

  const handleRedirect = async () => {
    if (isRedirecting) {
      return;
    }

    setIsRedirecting(true);

    try {
      await router.push(`/g/${groupRedirect}`);
      setIsRedirecting(false);
    } catch (error) {
      console.error("Erro durante o redirecionamento:", error);
      setIsRedirecting(false);
    }
  };

  const handleClickCTA = (e) => {
    if (triggerOnLinkClick) {
      triggerOnLinkClick();
    }

    handleRedirect();
  };

  return (
    <>
      {customButtonRedirectEnable && (
        <CustomButtonRedirect
          className="custom-button-redirect"
          onClick={handleClickCTA}
        >
          {subscribeNowCta?.text}
        </CustomButtonRedirect>
      )}
    </>
  );
};

export default CustomButtonCTA;
