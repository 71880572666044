import { useEffect } from "react";

import { useRouter } from "next/navigation";

import { UnplishedPlatformIcon } from "pages/[tenant]/console/assets/icons/UnplishedPlatformIcon";
import { UserIcon } from "pages/[tenant]/console/assets/icons/UserIcon";
import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";

import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { UnpublishedPlatformContainer } from "./styles";

export const UnpublishedPlatform = () => {
  const { state, setState } = useGlobalContext();
  const { loggedUser } = useAuthContext();
  const { push } = useRouter();

  const shouldRedirectToAdminConsole = !state?.tenancy?.active && !!loggedUser;

  useEffect(() => {
    if (shouldRedirectToAdminConsole) push("/console/billing");
  }, [shouldRedirectToAdminConsole]);

  const handleOpenAuthModal = () =>
    setState((prev) => ({
      ...prev,
      modal: {
        open: true,
        page: "login",
      },
    }));

  return (
    <UnpublishedPlatformContainer>
      <UnplishedPlatformIcon />

      <h1>Esta plataforma está registrada, mas ainda não foi publicada.</h1>

      <p>
        Você é o proprietário desta plataforma? Então entre em sua conta abaixo
        com o e-mail e senha definidos durante a criação para continuar a
        configuração da plataforma.
      </p>

      <ConsolePageForm.Button
        variant="primary"
        onClick={handleOpenAuthModal}
        id="login-in-button"
      >
        <UserIcon /> Entrar na minha conta
      </ConsolePageForm.Button>
    </UnpublishedPlatformContainer>
  );
};
