/* eslint-disable @next/next/no-img-element */
import { Component, ErrorInfo, ReactNode } from "react";

import sendLogToSlack from "shared/infra/services/slackAPI";

import { captureException } from "@sentry/nextjs";

import ErrorImage from "./assets/images/error-image.webp";
import { ComponentErrorBoundaryContainer } from "./styles";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ComponentErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Erro (ComponentErrorBoundary):", error);

    sendLogToSlack(errorInfo);
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComponentErrorBoundaryContainer>
          <img
            src={ErrorImage.src}
            alt="Algo deu errado =/"
            width={50}
            height={50}
          />
          Oops, algo errado aconteceu =/
        </ComponentErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}
