import { ConsolePageButton } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton";
import { ConsolePageCheckbox } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageCheckbox";
import { ConsolePageDatePicker } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageDatePicker";
import { ConsolePageInput } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageInput";
import { InputWithIcon } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageInput/atoms/InputWithIcon";
import { ConsolePagePasswordInput } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePagePasswordInput";
import { ConsolePageRadioGroup } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageRadioGroup";
import { ConsolePageSelect } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageSelect";
import { ConsolePageSwitch } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageSwitch";
import { ConsolePageTextArea } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageTextArea";

export const ConsolePageForm = {
  Input: ConsolePageInput,
  InputWithIcon: InputWithIcon,
  PasswordInput: ConsolePagePasswordInput,
  Button: ConsolePageButton,
  Checkbox: ConsolePageCheckbox,
  Switch: ConsolePageSwitch,
  Select: ConsolePageSelect,
  RadioGroup: ConsolePageRadioGroup,
  DatePicker: ConsolePageDatePicker,
  TextArea: ConsolePageTextArea,
};
