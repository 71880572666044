import React from "react";

export const IdentityBack = () => {
  return (
    <svg
      width="156"
      height="113"
      viewBox="0 0 156 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4063_21136)">
        <rect
          x="0.415225"
          y="0.827334"
          width="154.847"
          height="111.319"
          rx="5.91428"
          fill="#212328"
          stroke="#494953"
          strokeWidth="0.607794"
        />
        <rect
          x="12.5449"
          y="12.9531"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="20.7188"
          width="55.9636"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="91.4766"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="99.2422"
          width="55.9636"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="37.0664"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="44.832"
          width="130.582"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="61.1562"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="68.9219"
          width="130.582"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="12.5449"
          y="75.1445"
          width="130.582"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="87.1699"
          y="20.7188"
          width="55.9636"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="87.1699"
          y="12.9531"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
        <rect
          x="87.1699"
          y="99.2422"
          width="55.9636"
          height="3.10909"
          rx="1.55455"
          fill="#494953"
        />
        <rect
          x="87.1699"
          y="91.4766"
          width="37.3091"
          height="4.66364"
          rx="2.33182"
          fill="#494953"
        />
      </g>
      <defs>
        <clipPath id="clip0_4063_21136">
          <rect
            width="113"
            height="156"
            fill="white"
            transform="translate(156) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
