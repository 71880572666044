import { captureException } from "@sentry/nextjs";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

interface LayoutContextData {
  isVisibleMenus: boolean;
  setIsVisibleMenus: any;
  sidebarIsRendered: boolean;
  sidebarIsCollapsed: boolean;
  browserDoesNotSuportObserver: boolean;
  setSidebarIsCollapsed: () => void;
  setBrowserNotSuportObserver: () => void;
}

interface LayoutProviderProps {
  children: ReactNode;
}

const LayoutContext = createContext({} as LayoutContextData);

export function LayoutProvider({ children }: LayoutProviderProps) {
  const { state } = useGlobalContext();
  const themeMainMenu = state.tenancy?.settings["general-theme"]?.themeMainMenu;

  const [isVisibleMenus, setIsVisibleMenus] = useState(true);
  const [sidebarIsCollapsed, setStateSidebarIsCollapsed] = useState(true);
  const [sidebarIsRendered, setStateSidebarIsRendered] = useState(true);
  const [browserDoesNotSuportObserver, setStateBrowserNotSuportObserver] =
    useState(false);

  const setBrowserNotSuportObserver = useCallback(() => {
    setStateBrowserNotSuportObserver(true);
  }, []);

  const setSidebarIsCollapsed = useCallback(() => {
    setStateSidebarIsCollapsed(!sidebarIsCollapsed);
  }, [sidebarIsCollapsed]);

  useEffect(() => {
    try {
      if (
        themeMainMenu === "on_top_bar" ||
        location?.pathname?.includes("/payment") ||
        location?.pathname?.includes("/authentication") ||
        location?.pathname?.includes("/browse")
      ) {
        setStateSidebarIsRendered(false);
      } else {
        setStateSidebarIsRendered(true);
      }
    } catch (error) {
      console.log("[LayoutProvider]: an error occurred in the useEffect that calls 'setStateSidebarIsRendered'.");
      captureException(error);
    }
  }, [location?.pathname, themeMainMenu]);

  return (
    <LayoutContext.Provider
      value={{
        isVisibleMenus,
        setIsVisibleMenus,
        sidebarIsRendered,
        sidebarIsCollapsed,
        setSidebarIsCollapsed,
        setBrowserNotSuportObserver,
        browserDoesNotSuportObserver,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContext;
