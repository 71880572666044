import Link from "next/link";
import { useRouter } from "next/router";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import ChevronRight from "shared/assets/icons/ChevronRight";
import { ConfigIcon } from "shared/providers/Layout/components/NewTopBar/assets/ConfigIcon";
import { HomeIcon } from "shared/providers/Layout/components/NewTopBar/assets/HomeIcon";
import { LogoutIcon } from "shared/providers/Layout/components/NewTopBar/assets/LogoutIcon";
import { MenuIcon } from "shared/providers/Layout/components/NewTopBar/assets/MenuIcon";
import { NotificationsIcon } from "shared/providers/Layout/components/NewTopBar/assets/NotificationsIcon";
import { SearchIcon } from "shared/providers/Layout/components/NewTopBar/assets/SearchIcon";
import { UserIcon } from "shared/providers/Layout/components/NewTopBar/assets/UserIcon";
import CustomButtonCTA from "shared/providers/Layout/components/NewTopBar/components/atoms/CustomButtonCTA";
import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { Notifications } from "shared/providers/Layout/components/NewTopBar/components/atoms/Notifications";
import { SwitchTheme } from "shared/providers/Layout/components/NewTopBar/components/atoms/SwitchTheme";
import { UserAvatar } from "shared/providers/Layout/components/NewTopBar/components/atoms/UserAvatar";
import FooterMenuMyGroupList from "shared/providers/Layout/components/NewTopBar/components/molecules/FooterMenuMyGroupList";
import { Search } from "shared/providers/Layout/components/NewTopBar/components/molecules/Search";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useFeature } from "shared/utils/hooks/useFeature";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import useMainPage from "shared/utils/hooks/useMainPage";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import {
  ContentItem,
  FullViewMenu,
  MobileFooterNavigationContainer,
} from "./styles";

export const MobileFooterNavigation = () => {
  const { loggedUser: user, signOut } = useAuthContext();
  const { setState } = useGlobalContext();
  const mainPage = useMainPage();
  const isAdmin = user?.admin || user?.superadmin;
  const userIsLogged = !!user;

  const { pathname } = useRouter();

  const isCheckoutPage = pathname.includes("/payment");

  const handleOpenAuthModal = () =>
    setState((prev) => ({
      ...prev,
      modal: {
        open: true,
        page: "login",
      },
    }));

  const {
    closeFooterMobileMenu,
    openFooterMobileMenu,
    footerMobileMenuIsOpen,
    notificationsIsOpen,
    openNotifications,
    closeNotifications,
    searchIsOpen,
    closeSearch,
    openSearch,
    isContentMenuOpen,
    closeContentMenu,
    openContentMenu,
  } = useTopbarContext();

  const { fetchMyGroups } = useGroupContext();

  const handleClickMenu = async () => {
    if (footerMobileMenuIsOpen) {
      closeFooterMobileMenu();
    } else {
      openFooterMobileMenu();
    }
    await fetchMyGroups();
  };

  const isSearchEnabled = useFeature("f_search");

  const { width } = useWindowSize();
  const isMobile = width <= 850;

  if (!isMobile) return null;
  if (isCheckoutPage) return null;

  return (
    <>
      <MobileFooterNavigationContainer isSearchEnabled={isSearchEnabled}>
        <ul>
          <li>
            <Link href={`/${mainPage}`} onClick={closeFooterMobileMenu}>
              <HomeIcon />
            </Link>
          </li>
          {isSearchEnabled && (
            <li>
              <button
                onClick={searchIsOpen ? closeSearch : openSearch}
                className={`${searchIsOpen && "active"}`}
              >
                <SearchIcon />
              </button>
            </li>
          )}

          <li>
            <button
              onClick={handleClickMenu}
              className={`${footerMobileMenuIsOpen && "active"}`}
            >
              <MenuIcon />
            </button>
          </li>
          <li>
            <button
              onClick={
                notificationsIsOpen ? closeNotifications : openNotifications
              }
              className={`${notificationsIsOpen && "active"}`}
              disabled={!userIsLogged}
            >
              <NotificationsIcon />
            </button>
          </li>
          <li>
            {userIsLogged ? (
              <Link
                href={`/user/${user?.username}`}
                onClick={closeFooterMobileMenu}
              >
                <UserAvatar />
              </Link>
            ) : (
              <button onClick={handleOpenAuthModal}>
                <UserIcon />
              </button>
            )}
          </li>
        </ul>
      </MobileFooterNavigationContainer>

      <FullViewMenu show={footerMobileMenuIsOpen}>
        <MenusList rightArrowIcon triggerOnLinkClick={closeFooterMobileMenu} />

        <div className="CTA-container">
          <CustomButtonCTA triggerOnLinkClick={closeFooterMobileMenu} />
        </div>

        {userIsLogged && (
          <ContentItem
            onClick={isContentMenuOpen ? closeContentMenu : openContentMenu}
          >
            Meus conteúdos
            <ChevronRight />
          </ContentItem>
        )}

        {isAdmin && (
          <Link href="/console" onClick={closeFooterMobileMenu}>
            Admin Console <ConfigIcon />
          </Link>
        )}

        <SwitchTheme />

        {userIsLogged ? (
          <button onClick={signOut}>
            Sair da conta <LogoutIcon />
          </button>
        ) : (
          <button onClick={handleOpenAuthModal}>
            Entrar / Criar conta <UserIcon />
          </button>
        )}
      </FullViewMenu>

      <FooterMenuMyGroupList />
      <Notifications />
      <Search />
    </>
  );
};
