import { useCallback, useEffect } from "react";

import { useMonitoringInfiniteReloading } from "shared/utils/hooks/useMonitoringInfiniteReloading";

import { captureException, captureMessage } from "@sentry/nextjs";

const useMonitoringAppInfinityReloading = () => {
  const { startMonitoringForInifiniteReloading } =
    useMonitoringInfiniteReloading();

  const monitoringInfiniteReload = useCallback(() => {
    try {
      startMonitoringForInifiniteReloading();
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that calls 'startMonitoringForInifiniteReloading'.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [startMonitoringForInifiniteReloading]);

  useEffect(() => {
    monitoringInfiniteReload();
  }, []);

  return {};
};

export default useMonitoringAppInfinityReloading;
