import { createContext, useEffect, useMemo, useRef, useState } from "react";

import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import { getMyGroups, getMyTrail } from "pages/[tenant]/g/utils/fetchGroupSlug";
import { removeDuplicatedObjects } from "pages/[tenant]/g/utils/removeDuplicatedObjects";

import api from "shared/infra/services/api";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useFeature } from "shared/utils/hooks/useFeature";

import {
  GroupContextProps,
  IGroup,
  IGroupContextProviderProps,
  IModerators,
  ITopic,
  IUserLessonDetails,
  Post,
  UserSituation,
} from "../@types";

export const GroupContext = createContext({} as GroupContextProps);

export const GroupContextProvider = ({
  children,
  groupInitialData,
}: IGroupContextProviderProps) => {
  const [group, setGroup] = useState(groupInitialData);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [groupSlugInRealTime, setGroupSlugInRealTime] = useState("");
  const [groupTitleInRealTime, setGroupTitleInRealTime] = useState("");
  const [members, setMembers] = useState([]);
  const [pendencyMembers, setPendencyMembers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [moderators, setModerators] = useState<IModerators[]>();
  const [userSituation, setUserSituation] = useState<UserSituation>();
  const userSituationRef = useRef(userSituation);
  const [userLessonDetails, setUserLessonDetails] =
    useState<IUserLessonDetails>();
  const [section, setSection] = useState("");

  const [listSection, setListSection] = useState("allMembers");

  const [allowSectionChange, setAllowSectionChange] = useState(true);

  const [myGroups, setMyGroups] = useState<IGroup[]>();
  const [isFetchingMyGroups, setIsFetchingMyGroups] = useState(false);
  const [hasErrorInMyGroupsRequest, setHasErrorInMyGroupsRequest] =
    useState(false);

  const [hasNoActiveTrail, setHasNoActiveTrail] = useState(false);
  const [myTrailData, setMytrailData] = useState();

  const [loading, setLoading] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalLessons, setTotalLessons] = useState(group?.lesson_count || 0);
  const [enableInfinityScroll, setEnableInfinityScroll] = useState(true);
  const [failedToLoadImgBanner, setFailedToLoadImgBanner] = useState(false);
  const [scrollBarIsOnTop, setScrollBarIsOnTop] = useState(false);
  const [errorRequestMembers, setErrorRequestMembers] = useState(false);
  const [fetchInProgress, setFetchInProgress] = useState(false);

  const [activeGroupNexus, setActiveGroupNexus] = useState<IGroup[]>([]);
  const activeGroupHasNexus = useMemo(
    () => !!activeGroupNexus?.length,
    [activeGroupNexus],
  );

  const nexusIsEnabled = useFeature("f_nexus");
  const { isAuthenticated } = useAuthContext();
  const rankingFeatureFlag = useFeature("f_ranking");
  const rankingIsEnabled = useMemo(
    () =>
      rankingFeatureFlag &&
      Boolean(group?.enable_gamification) &&
      Boolean(group?.enable_ranking),
    [rankingFeatureFlag, group?.enable_gamification, group?.enable_ranking],
  );

  const fetchMyGroups = async () => {
    if (!isAuthenticated) return;
    isAuthenticated && setHasErrorInMyGroupsRequest(false);
    const myGroupsAlreadyLoaded = myGroups?.length;
    if (myGroupsAlreadyLoaded || isFetchingMyGroups) return;

    setIsFetchingMyGroups(true);
    const response = await getMyGroups({});
    setIsFetchingMyGroups(false);
    if (typeof response === "number") return setHasErrorInMyGroupsRequest(true);

    setMyGroups(removeDuplicatedObjects(response));
  };

  const fetchMyTrail = async () => {
    if (!isAuthenticated) return;
    if (!!myTrailData || hasNoActiveTrail) return;
    const response = await getMyTrail();
    if (response === 404) setHasNoActiveTrail(true);
    else setMytrailData(response);
  };

  const loadActiveGroupNexus = async ({ groupId }: { groupId: number }) => {
    if (!nexusIsEnabled) return;

    try {
      const { data } = await api.get(`/nexus/bindings/${groupId}/targets`);
      const grouNexusWithoutEmptyItens = data.data.filter(
        (data) => Object.keys(data).length,
      );

      setActiveGroupNexus(grouNexusWithoutEmptyItens);
    } catch (error) {
      console.log("Error (loadActiveGroupNexus): ", error);
    }
  };

  const handleToggleMenuMobile = () => setShowMenuMobile(!showMenuMobile);
  const isModerator = group?.member_info?.is_moderator;

  const returnGroupInitialPage = (groupData) => {
    const groupSlug = groupData?.slug;
    const initialPage = groupData?.group_shortcut?.initial_page;

    const firstTopicSlug = groupData?.first_lesson?.topic_slug;
    const firstLessonSlug = groupData?.first_lesson?.lesson_slug;

    const lastTopicSlug = groupData?.continue_watching?.topic_slug;
    const lastLessonSlug = groupData?.continue_watching?.lesson_slug;

    switch (initialPage) {
      case "mural" || "Mural":
        return `/g/${groupSlug}/community`;
      case "Mural":
        return `/g/${groupSlug}/community`;
      case "lessons":
        return `/g/${groupSlug}/classwork`;
      case "first_lesson":
        return `/g/${groupSlug}/classwork/${firstTopicSlug}/${firstLessonSlug}`;
      case "continue_watching":
        return `/g/${groupSlug}/classwork/${lastTopicSlug}/${lastLessonSlug}`;
    }
  };

  const resetGroupStates = () => {
    setGroup(null);
    setPosts([]);
    setPage(1);
    setIsLastPage(false);
    setEnableInfinityScroll(true);
    setTopics([]);
    setActiveGroupNexus([]);
    setMembers([]);
    setModerators(null);
  };

  const params = useSearchParams();
  const { query } = useRouter();
  const group_slug = params.get("group_slug") || query?.group_slug;

  useEffect(() => {
    if (group) resetGroupStates();
    if (group_slug) setGroupSlugInRealTime(group_slug as string);
  }, [group_slug]);

  async function fetchPosts({ setIsFeedLoading }) {
    const params = {
      "order[0][index]": "created_at",
      "order[0][value]": "desc",
      perPage: 20,
      page,
    };
    if (isLastPage) return setEnableInfinityScroll(false);

    try {
      setIsFeedLoading(true);
      const response = await api.get(`groups/${groupSlugInRealTime}/posts`, {
        params,
      });

      setPage(page + 1);

      if (response.data.meta.current_page >= response.data.meta.last_page)
        setIsLastPage(true);

      setPosts((prev) =>
        removeDuplicatedObjects([...prev, ...response.data.data]),
      );

      if (isLastPage) setEnableInfinityScroll(false);
    } catch (error) {
      console.log("Error (fetchPosts): ", error);

      if (!!error?.response?.status && error?.response?.status === 404) {
        setErrorRequestMembers(true);
      }
    } finally {
      setIsFeedLoading(false);
    }
  }

  const updateGroupInfo = (group: IGroup) => {
    setGroupTitleInRealTime(group.title);
    setGroup((prev) => ({
      ...prev,
      ...group,
      member_info: {
        ...prev?.member_info,
        ...group?.member_info,
      },
    }));
  };

  const addSingleGroupToGroupsList = (newGroup: IGroup) =>
    setGroups((prev) => [...prev, newGroup]);

  const addMultipleGroupsToGroupsList = (newGroup: IGroup[]) =>
    setGroups((prev) => [...prev, ...newGroup]);

  const removeGroupFromList = (groupId: number) =>
    setGroups((prev) => prev.filter((group) => group.id !== groupId));

  return (
    <GroupContext.Provider
      value={{
        group,
        setGroup,
        posts,
        setPosts,
        members,
        setMembers,
        pendencyMembers,
        setPendencyMembers,
        subscriptions,
        setSubscriptions,
        moderators,
        setModerators,
        userSituation,
        setUserSituation,
        userSituationRef,
        userLessonDetails,
        setUserLessonDetails,
        section,
        setSection,
        isLastPage,
        setIsLastPage,
        enableInfinityScroll,
        setEnableInfinityScroll,
        loading,
        setLoading,
        page,
        setPage,
        myGroups,
        setMyGroups,
        showMenuMobile,
        setShowMenuMobile,
        handleToggleMenuMobile,
        isModerator,
        setFailedToLoadImgBanner,
        failedToLoadImgBanner,
        scrollBarIsOnTop,
        setScrollBarIsOnTop,
        topics,
        setTopics,
        groupSlugInRealTime,
        setGroupSlugInRealTime,
        groupTitleInRealTime,
        setGroupTitleInRealTime,
        returnGroupInitialPage,
        hasErrorInMyGroupsRequest,
        setHasErrorInMyGroupsRequest,

        hasNoActiveTrail,
        myTrailData,

        activeGroupNexus,
        activeGroupHasNexus,
        loadActiveGroupNexus,
        fetchMyGroups,
        fetchMyTrail,
        isFetchingMyGroups,
        totalLessons,
        setTotalLessons,
        rankingIsEnabled,
        fetchPosts,
        resetGroupStates,
        updateGroupInfo,
        errorRequestMembers,
        setErrorRequestMembers,
        listSection,
        setListSection,
        groups,

        allowSectionChange,
        setAllowSectionChange,

        addSingleGroupToGroupsList,
        addMultipleGroupsToGroupsList,
        removeGroupFromList,
        fetchInProgress,
        setFetchInProgress,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupContextProvider;
