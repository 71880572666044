import { ChangeEvent, useState } from "react";

import { InputMyDocuments } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/components/InputMyDocuments";
import {
  formatCPF,
  formatDate,
  validaCpfCnpj,
} from "pages/[tenant]/console/users/utils/userUtils";

import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";

import { ContainerLegalRepresentative } from "./styles";

export const LegalRepresentative = ({
  setCompanyData,
  companyData,
  errors,
  setErrors,
  personType,
}) => {
  const [documentPersonalFormatted, setDocumentPersonalFormatted] =
    useState(null);
  const [birthday, setBirthday] = useState(null);

  const changeEditUserData = ({ key, value }) => {
    setCompanyData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeErrors = ({ key, value }) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const OnBlurDocument = () => {
    const isValid = validaCpfCnpj(documentPersonalFormatted);

    if (!isValid) {
      changeErrors({
        key: "cpf",
        value: "Este documento não é um documento válido",
      });
    }
  };

  const OnBlurBirthday = () => {
    if (typeof birthday !== "string") return;
    const [dayStr, monthStr, yearStr] = birthday.split("/");

    const day = parseInt(dayStr, 10);
    const month = parseInt(monthStr, 10);
    const year = parseInt(yearStr, 10);

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    const limitYear = today.getFullYear() - 101;

    const isValid =
      birthDate.getDate() === day &&
      birthDate.getMonth() === month - 1 &&
      birthDate.getFullYear() === year;

    const minimumAge = new Date(birthDate);
    minimumAge.setFullYear(minimumAge.getFullYear() + 18);

    if (!isValid || birthDate.getFullYear() <= limitYear) {
      changeErrors({
        key: "birthdate",
        value: "Esta não é uma data válida",
      });
      return;
    }

    if (today <= minimumAge) {
      changeErrors({
        key: "birthdate",
        value: "Data de nascimento incompatível com a idade mínima",
      });
      return;
    }
  };

  const handleCheckName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const name = target.value.trim();
    changeEditUserData({ key: "name", value: target.value });
    if (
      /^([a-zA-ZÀ-ÖØ-öø-ÿ]{2,}\s[a-zA-ZÀ-ÖØ-öø-ÿ]{2,}'?-?[a-zA-ZÀ-ÖØ-öø-ÿ]{0,}\s?([a-zA-ZÀ-ÖØ-öø-ÿ]{0,})?)/.test(
        name,
      )
    ) {
      changeEditUserData({ key: "name", value: name });
      setErrors((prevErrors) => {
        const { name, ...restErrors } = prevErrors;
        return restErrors;
      });
    } else {
      changeErrors({
        key: "name",
        value: "Informe nome e sobrenome",
      });
    }
  };

  const handleDate = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const birthday = formatDate(target.value);
    changeEditUserData({ key: "birthdate", value: birthday });
    setBirthday(birthday);

    setErrors((prevErrors) => {
      const { birthdate, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  const handleCheckDocument = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const documentPersonal = formatCPF(target.value);
    setErrors((prevErrors) => {
      const { cpf, ...restErrors } = prevErrors;
      return restErrors;
    });
    changeEditUserData({ key: "cpf", value: documentPersonal });

    setDocumentPersonalFormatted(documentPersonal);
  };

  return (
    <ContainerLegalRepresentative>
      <DescriptionModalDocuments
        title="Dados do representante legal"
        description="Precisamos de algumas informações do representante legal da empresa para continuar."
      />

      <InputMyDocuments
        label="Nome completo"
        value={companyData?.name || ""}
        onChange={handleCheckName}
        required
        id="name"
        error={errors.name}
        isInvalid={!!errors.name}
      />

      <div className="info-company">
        <InputMyDocuments
          label="CPF"
          value={companyData?.cpf || ""}
          onChange={handleCheckDocument}
          required
          id="personal_document"
          onBlur={OnBlurDocument}
          maxLength={14}
          error={errors.cpf}
          isInvalid={!!errors.cpf}
        />
        <InputMyDocuments
          label="Data de nascimento"
          value={companyData?.birthdate || ""}
          onChange={handleDate}
          required
          id="birthday"
          maxLength={10}
          onBlur={OnBlurBirthday}
          error={errors.birthdate}
          isInvalid={!!errors.birthdate}
        />
      </div>
    </ContainerLegalRepresentative>
  );
};
