import styled from "styled-components";

export const UnpublishedPlatformContainer = styled.div`
  max-width: 27rem;
  margin: 5rem auto 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;

  @media (max-width: 850px) {
    margin: 2rem auto 0 auto;
  }

  > svg path {
    fill: ${({ theme }) => theme.console.sidebar.titleColor};
  }

  h1 {
    color: ${({ theme }) => theme.console.sidebar.titleColor};
    font: 500 1.375rem/2.0625rem "Inter";
  }

  p {
    color: ${({ theme }) => theme.console.pageSubtitleColor};
    font: 400 1rem/1.5rem "Inter";
  }

  h1,
  p {
    margin: 0;
  }
`;
