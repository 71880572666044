import { useCallback, useEffect } from "react";

import { useMonitoringPageErrors } from "shared/utils/hooks/useMonitoringPageErrors";

import { captureException, captureMessage } from "@sentry/nextjs";

const useMonitoringErrorsForVercel = () => {
  const { startMonitoringForVercelErrorsInPage } = useMonitoringPageErrors();

  const monitoringErrorsForVercel = useCallback(() => {
    try {
      startMonitoringForVercelErrorsInPage();
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that calls 'startMonitoringForVercelErrors'.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [startMonitoringForVercelErrorsInPage]);

  useEffect(() => {
    monitoringErrorsForVercel();
  }, []);

  return {};
};

export default useMonitoringErrorsForVercel;
