import React from "react";

export const ClockIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2C6.736 2 2.25 6.486 2.25 12C2.25 17.514 6.736 22 12.25 22C17.764 22 22.25 17.514 22.25 12C22.25 6.486 17.764 2 12.25 2ZM18 13H11.25V6H13.25V11H18V13Z"
        fill="white"
      />
    </svg>
  );
};
