import { parseCookies } from "nookies";

import sendLogToSlack from "shared/infra/services/slackAPI";
import {
  IGetFromSessionStorageAlreadySendLogToSlackReturn,
  IGetTimesOfEachPageReloadedBetweenMilisecondsOfToleranceReturn,
  IGetTimesOfEachPageReloadedBetweenMilisecondsOfToleranceprops,
  IPageLog,
} from "shared/types/monitoringInfiniteReloadingTypes";
import { getBrowserInfo } from "shared/utils/getBrowserInfo";
import { getUserSO } from "shared/utils/getUserSO";
import { makeObjectWithTimeOfEachPageReloaded } from "shared/utils/makeObjectWithTimeOfEachPageReloaded";

import { captureException, captureMessage } from "@sentry/nextjs";

export const timeInMilisecondsOfTolerance = 1000 * 5; // 5 seconds;
export const maxTimesThatPageCanReloadBetweenTolerance = 6;

export const timesOfReloadingsToConsiderThatPageKeepsReloading = 12;
export const timeInMilisecondsOfToleranceToConsiderThatPageKeepsReloading =
  1000 * 60 * 2; // 2 minutes;

export const getPageTimeLogsFromSessionStorage = (): IPageLog =>
  JSON?.parse(sessionStorage?.getItem("pageTimeLogs")) ?? {};

export const savePageTimeLogsInSessionStorage = (
  pageTimeLogs: IPageLog,
): void =>
  sessionStorage?.setItem("pageTimeLogs", JSON?.stringify(pageTimeLogs));

export const getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance = ({
  pageLogs = {},
  timeOfTolerance,
}: IGetTimesOfEachPageReloadedBetweenMilisecondsOfToleranceprops): IGetTimesOfEachPageReloadedBetweenMilisecondsOfToleranceReturn => {
  if (!pageLogs) return;
  let timeOfEachPageRealodedBetweenMilisecondsOfTolerance = {};

  try {
    timeOfEachPageRealodedBetweenMilisecondsOfTolerance =
      makeObjectWithTimeOfEachPageReloaded({
        pageLogs,
        timeOfTolerance,
      });
  } catch (error) {
    console.log(
      "Error (getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance): ",
      error,
    );
    captureException(error);
    captureMessage(
      "Error (getTimesOfEachPageReloadedBetweenMilisecondsOfTolerance)",
    );
  }
  return timeOfEachPageRealodedBetweenMilisecondsOfTolerance;
};

export const getFromSessionStorageAlreadySendLogToSlack =
  (): IGetFromSessionStorageAlreadySendLogToSlackReturn =>
    (sessionStorage?.getItem(
      "alreadySendLogToSlack",
    ) as IGetFromSessionStorageAlreadySendLogToSlackReturn) ?? "false";

export const setInSessionStorageAlreadySendLogToSlack = (): void =>
  sessionStorage?.setItem("alreadySendLogToSlack", "true");

export const sendTimeOfPageReloadedToSlack = async ({
  path,
}): Promise<boolean> => {
  try {
    const { username } = parseCookies();

    const logText = [
      `\n *:repeat: Houveram reloads excessivos na página:* ${path}.`,
      `\n *Usuário:* ${
        username ? username?.replaceAll('"', "") : "Não existe usuário logado"
      }.`,
      `\n *Navegador:* ${getBrowserInfo()}.`,
      `\n *Sistema Operacional:* ${getUserSO()}.`,
      `\n Essa página foi recarregada pelo menos *${maxTimesThatPageCanReloadBetweenTolerance}x* em 1 minuto, com intervalos inferiores a *${
        timeInMilisecondsOfTolerance / 1000
      }* segundos entre cada carregamento.`,
    ].reduce((currentText, currentLine) => currentText + currentLine, "");

    await sendLogToSlack(logText);
    return true;
  } catch (error) {
    console.log("Error (sendTimeOfPageReloadedToSlack): ", error);
    captureException(error);
    captureMessage("Error (sendTimeOfPageReloadedToSlack)");
    return false;
  }
};
