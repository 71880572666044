import styled from "styled-components";

export const InputWithIconContainer = styled.div`
  position: relative;

  button:disabled {
    cursor: default !important;
  }

  label {
    left: 2.8rem;
  }

  .icon-container {
    position: absolute;
    left: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 0;
    height: 3rem;
  }

  input {
    padding-left: 2.8rem;
  }
`;
