import styled, { css } from "styled-components";

import { consolePrimaryColor } from "pages/[tenant]/console/styles/consoleGlobalColors";

interface IConsolePageInputContainerProps {
  shouldMoveFloatingLabel: boolean;
  isInvalid: boolean;
}

const beforeElementStyles = css`
  label {
    transform: translateY(-130%);

    color: #6c6c71;
    font: 600 0.625rem/0.9375rem "Inter";
  }
`;

export const ConsolePageInputContainer = styled.div<IConsolePageInputContainerProps>`
  position: relative;
  width: 100%;

  label {
    position: absolute;
    display: flex;
    align-items: center;

    pointer-events: none;

    font: 600 0.8125rem/1.21875rem "Inter";
    color: #6c6c71;

    left: 1rem;
    top: 50%;
    transform: translateY(-50%);

    transition: 0.3s all;

    z-index: 1;

    .required-flag {
      color: #ec3434;
      font: 600 0.625rem/0.01563rem "Inter";
      margin-left: 0.2rem;
    }
  }

  input {
    padding: 0rem 1rem;
    padding-top: 1rem;

    border-radius: 4px;
    background-color: ${({ theme }) => theme?.console?.backgroundColor};

    width: 100%;

    color: ${({ theme }) => theme?.console?.inputColor};
    transition: 0.3s all;

    font: 600 0.875rem/1.3125rem "Inter";

    height: 48px;

    border: 1px solid ${({ theme }) => theme?.console?.inputBorderColor};

    :disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &[type="number"] {
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      [type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  :focus-within,
  :has(input.is-filled) {
    ${beforeElementStyles}
  }

  ${({ isInvalid }) =>
    isInvalid
      ? css`
          input {
            box-shadow: 0px 0px 0px 2px red;
          }

          label {
            color: red !important;
          }

          ::before {
            color: red !important;
          }
        `
      : css`
          :has(input:is(:focus, :active):not(:disabled)) {
            label {
              color: ${consolePrimaryColor};
            }

            input {
              box-shadow: 0px 0px 0px 2px ${consolePrimaryColor};
            }
          }
        `}

  ${({ shouldMoveFloatingLabel }) =>
    shouldMoveFloatingLabel &&
    css`
      ${beforeElementStyles}
    `}
`;
