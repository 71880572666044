export const formatErrorDate = (date: Date): string => {
  const formatConfig = new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  });

  const dataHoraFormatada = formatConfig.format(date);

  return dataHoraFormatada;
};
