import React from "react";

export const Identity = () => {
  return (
    <svg
      width="156"
      height="113"
      viewBox="0 0 156 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4063_21073)">
        <rect
          x="0.700413"
          y="1.23948"
          width="154.838"
          height="111.131"
          rx="5.90207"
          fill="#212328"
          stroke="#494953"
          strokeWidth="0.611764"
        />
        <rect
          x="75.0117"
          y="28.8711"
          width="55.8716"
          height="55.962"
          rx="3.10398"
          transform="rotate(90 75.0117 28.8711)"
          stroke="#494953"
          strokeWidth="1.16399"
        />
        <path
          d="M99.6374 76.9685C96.5608 78.4816 93.6538 75.6296 93.6538 72.201C93.6538 68.3079 93.6538 62.9472 93.6538 57.1934C93.6538 51.4397 93.6538 46.0789 93.6538 42.1858C93.6538 38.7573 96.5608 35.9053 99.6374 37.4183C106.005 40.5499 110.775 48.4142 110.775 57.1934C110.775 65.9726 106.005 73.8369 99.6374 76.9685Z"
          fill="#494953"
        />
        <ellipse
          cx="125.632"
          cy="57.1934"
          rx="11.5333"
          ry="11.5519"
          transform="rotate(90 125.632 57.1934)"
          fill="#494953"
        />
        <path
          d="M49.0439 74.532C48.8541 74.3553 48.841 74.0608 49.0177 73.8776C49.1028 73.7859 49.1813 73.6943 49.2664 73.6027C49.3907 73.4653 49.5805 73.4129 49.7572 73.4653C50.0452 73.5569 50.3462 73.6485 50.6604 73.7401C50.8632 73.799 50.9941 73.9823 50.9941 74.1852C50.9941 74.231 50.9876 74.2768 50.9745 74.3161C50.9025 74.5647 50.6407 74.7022 50.3986 74.6302C50.1826 74.5647 49.9732 74.4993 49.7703 74.4404C49.7507 74.46 49.731 74.4797 49.7114 74.4993C49.5216 74.6956 49.2271 74.7022 49.0439 74.532Z"
          fill="#494953"
        />
        <path
          d="M56.581 74.0831C55.1807 73.9064 53.7608 73.6185 52.2492 73.2192C52.0006 73.1538 51.8501 72.8986 51.9155 72.6499C51.9809 72.4012 52.2361 72.2506 52.4848 72.3161C53.9571 72.7088 55.3377 72.9771 56.6988 73.1538C56.8493 73.1734 56.9998 73.1931 57.1503 73.2062C57.3858 73.2323 57.5625 73.4352 57.5625 73.6708C57.5625 73.6905 57.5625 73.7035 57.5625 73.7232C57.5363 73.9784 57.3073 74.1617 57.0521 74.1355C56.8885 74.1224 56.7315 74.1028 56.581 74.0831Z"
          fill="#494953"
        />
        <path
          d="M47.0151 74.532C46.8253 74.7022 46.5308 74.6825 46.3606 74.4862C46.1904 74.2964 46.2101 74.0019 46.4064 73.8317C47.1001 73.2231 47.748 72.5555 48.324 71.8356C48.6316 71.4561 49.1486 71.3055 49.6132 71.4561C49.947 71.5608 50.2939 71.6786 50.6669 71.7898C50.8632 71.8487 50.9941 72.0385 50.9941 72.2349C50.9941 72.2807 50.9876 72.3265 50.9745 72.3723C50.896 72.6144 50.6407 72.7519 50.392 72.6799C50.019 72.5621 49.6656 72.4508 49.3253 72.3396C49.2206 72.3069 49.1093 72.3396 49.0439 72.4181C48.4287 73.1838 47.748 73.8906 47.0151 74.532Z"
          fill="#494953"
        />
        <path
          d="M34.4555 40.9285C34.4882 40.994 34.5078 41.0659 34.5078 41.1379C34.5078 41.3081 34.4162 41.4717 34.2526 41.5502C33.5654 41.8971 32.8782 42.2767 32.2172 42.6759C31.9947 42.8068 31.7133 42.7348 31.5759 42.5188C31.445 42.2963 31.517 42.0149 31.7329 41.8775C32.4136 41.4652 33.1204 41.079 33.8272 40.7191C34.0562 40.6078 34.3377 40.6994 34.4555 40.9285Z"
          fill="#494953"
        />
        <path
          d="M48.6311 70.1614C48.5198 70.1287 48.4021 70.168 48.3366 70.2596C47.1392 72.007 45.6342 73.4599 43.8544 74.5724C43.6384 74.7099 43.3505 74.6444 43.2131 74.4285C43.0757 74.2125 43.1411 73.9245 43.3571 73.7871C45.0256 72.74 46.439 71.3787 47.5645 69.736C47.8655 69.2975 48.4021 69.1143 48.9059 69.2779C49.0957 69.3368 49.3051 69.4088 49.5341 69.4808C52.7469 70.5344 56.9936 71.7125 61.3842 71.4703C61.6394 71.4572 61.8619 71.6536 61.875 71.9088C61.875 71.9153 61.875 71.9284 61.875 71.935C61.875 72.1771 61.6852 72.3866 61.4366 72.3996C56.8954 72.6549 52.5441 71.4441 49.2527 70.3643C49.0237 70.2923 48.8143 70.2203 48.6311 70.1614Z"
          fill="#494953"
        />
        <path
          d="M34.4489 43.018C34.4882 43.09 34.5078 43.162 34.5078 43.2405C34.5078 43.4041 34.4227 43.5678 34.2657 43.6463C30.7061 45.59 27.6699 48.1882 25.2292 51.3689C25.0722 51.5717 24.7843 51.611 24.5749 51.4539C24.372 51.2969 24.3328 51.0089 24.4898 50.7995C27.009 47.5207 30.1433 44.8374 33.8142 42.8348C34.0432 42.7104 34.3246 42.7955 34.4489 43.018Z"
          fill="#494953"
        />
        <path
          d="M44.6341 39.8211C44.6341 39.8407 44.6406 39.8669 44.6406 39.8865C44.6406 40.1156 44.4705 40.3119 44.2415 40.3447C41.2119 40.7766 38.2869 41.6667 35.5518 42.9887C35.3228 43.0999 35.0414 43.0017 34.9302 42.7727C34.8189 42.5436 34.9171 42.2622 35.1461 42.1509C37.9729 40.7831 40.9894 39.8669 44.1106 39.4219C44.3593 39.3891 44.5948 39.5658 44.6341 39.8211Z"
          fill="#494953"
        />
        <path
          d="M57.9941 41.2884C57.9941 41.3342 57.9876 41.38 57.9745 41.4324C57.896 41.6745 57.6343 41.812 57.3921 41.7334C54.1531 40.6929 50.5346 39.7832 46.0982 40.1366C45.843 40.1562 45.6205 39.9664 45.6009 39.7112C45.5812 39.4559 45.771 39.2334 46.0262 39.2138C50.6262 38.8473 54.3494 39.7832 57.6801 40.8499C57.8698 40.9088 57.9941 41.0921 57.9941 41.2884Z"
          fill="#494953"
        />
        <path
          d="M53.1585 69.1265C53.224 68.8778 53.4726 68.7273 53.7213 68.7862C57.5426 69.7482 60.9976 69.8922 64.2758 69.2181C64.5245 69.1658 64.7731 69.3294 64.8255 69.5781C64.832 69.6108 64.832 69.6435 64.832 69.6697C64.832 69.8857 64.6815 70.082 64.4591 70.1278C61.0368 70.8281 57.451 70.6841 53.4857 69.6893C53.2436 69.6304 53.0931 69.3752 53.1585 69.1265Z"
          fill="#494953"
        />
        <path
          d="M46.5299 67.9001C46.6019 67.7692 46.7066 67.6514 46.8244 67.5466C47.2693 67.1605 47.9171 67.0296 48.5126 67.2063C48.9314 67.3307 49.4745 67.5008 50.1223 67.7168C50.4298 67.8215 50.7635 67.9262 51.1169 68.0375C51.3132 68.1029 51.4375 68.2862 51.4375 68.4825C51.4375 68.5283 51.431 68.5741 51.4179 68.6265C51.3393 68.8686 51.0776 69.0061 50.8355 68.9275C50.4822 68.8163 50.1484 68.705 49.8344 68.6003C49.1931 68.3909 48.6631 68.2207 48.2508 68.0964C47.9564 68.0113 47.6423 68.0637 47.4395 68.2469C47.3937 68.2862 47.3544 68.332 47.3348 68.3713C45.5746 71.3883 42.7609 73.5807 39.4107 74.5428C39.162 74.6148 38.9068 74.4708 38.8348 74.2221C38.7629 73.9734 38.9068 73.7182 39.1555 73.6462C42.2701 72.7496 44.8941 70.7142 46.5299 67.9001Z"
          fill="#494953"
        />
        <path
          d="M30.2996 47.4467C30.4959 47.2831 30.7903 47.3093 30.9539 47.5121C31.0259 47.5972 31.0586 47.7019 31.0586 47.8067C31.0586 47.9375 30.9997 48.075 30.8885 48.1666C28.4543 50.1758 26.4193 52.5645 24.8423 55.274C24.646 55.6078 24.4628 55.9612 24.2992 56.308C24.1945 56.5436 23.9132 56.6418 23.6841 56.5371C23.4486 56.4258 23.3504 56.151 23.4551 55.9219C23.6318 55.5423 23.8281 55.1693 24.0375 54.8093C25.6733 52.0017 27.7803 49.5279 30.2996 47.4467Z"
          fill="#494953"
        />
        <path
          d="M37.7713 43.5199C37.7975 43.5788 37.8105 43.6443 37.8105 43.7032C37.8105 43.8864 37.7059 44.0566 37.5292 44.1286C35.5858 44.9597 33.7405 46.0134 32.0523 47.2634C31.8429 47.4139 31.555 47.3747 31.4045 47.1652C31.254 46.9558 31.2933 46.6679 31.5027 46.5173C33.2498 45.2281 35.1605 44.1351 37.1693 43.2778C37.3918 43.1731 37.6666 43.2843 37.7713 43.5199Z"
          fill="#494953"
        />
        <path
          d="M39.0818 42.5385C41.3655 41.7531 43.7473 41.2557 46.1683 41.0659C50.4085 40.7256 53.955 41.6222 56.8603 42.545C57.0566 42.6104 57.1875 42.7937 57.1875 42.99C57.1875 43.0358 57.181 43.0817 57.1679 43.1275C57.0893 43.3696 56.8276 43.5071 56.5855 43.4285C53.6213 42.4926 50.3103 41.668 46.2469 41.9887C43.9043 42.1785 41.601 42.6563 39.3893 43.4154C39.1472 43.5005 38.879 43.3696 38.8004 43.1275C38.7088 42.8853 38.8397 42.6235 39.0818 42.5385Z"
          fill="#494953"
        />
        <path
          d="M59.3106 42.858C59.1797 43.0805 58.8983 43.159 58.6758 43.0281C58.4533 42.8972 58.3748 42.6158 58.5057 42.3933C58.6169 42.2035 58.7282 42.0006 58.8525 41.7847C58.9965 41.5229 59.1536 41.2676 59.3106 41.0189C59.4481 40.803 59.736 40.7375 59.952 40.8815C60.0894 40.9731 60.168 41.1171 60.168 41.2742C60.168 41.3593 60.1418 41.4443 60.096 41.5229C59.9455 41.7519 59.808 41.9941 59.6706 42.2362C59.5397 42.4587 59.4219 42.6616 59.3106 42.858Z"
          fill="#494953"
        />
        <path
          d="M52.2788 66.9461C52.3508 66.6975 52.606 66.56 52.8546 66.632C56.1918 67.6071 61.2564 68.6608 66.7071 66.5927C66.9492 66.5011 67.2175 66.6255 67.3091 66.8611C67.3287 66.9134 67.3418 66.9723 67.3418 67.0247C67.3418 67.2145 67.2306 67.3912 67.0408 67.4566C61.3284 69.6229 56.0609 68.5299 52.5994 67.5221C52.3508 67.4501 52.2068 67.1948 52.2788 66.9461Z"
          fill="#494953"
        />
        <path
          d="M44.9223 66.9708C45.1382 66.5978 45.4327 66.2705 45.7991 65.9957C46.7087 65.3085 47.9388 65.0991 49.0905 65.4459C49.5158 65.5703 50.0589 65.747 50.7067 65.9564C50.903 66.0218 51.0273 66.2051 51.0273 66.3949C51.0273 66.4407 51.0208 66.493 51.0012 66.5389C50.9226 66.781 50.6609 66.9184 50.4123 66.8334C49.771 66.6239 49.2344 66.4538 48.8222 66.3294C47.9519 66.0677 47.0358 66.2182 46.3619 66.7287C46.0936 66.9315 45.8842 67.1671 45.7272 67.4355C44.1633 70.1187 41.6506 72.0297 38.6537 72.8216C37.0244 73.2536 35.3623 73.3256 33.7199 73.0441C33.4647 72.9983 33.2946 72.7627 33.3404 72.5075C33.3862 72.2523 33.6218 72.0821 33.877 72.1279C35.3885 72.3897 36.9131 72.3177 38.4181 71.925C41.1729 71.1986 43.4893 69.4381 44.9223 66.9708Z"
          fill="#494953"
        />
        <path
          d="M41.176 43.835C41.4247 43.763 41.6799 43.9069 41.7519 44.1556C41.7649 44.1949 41.7715 44.2407 41.7715 44.28C41.7715 44.4829 41.6341 44.6727 41.4312 44.725C35.0448 46.5182 29.7316 50.5889 26.4598 56.2042C25.511 57.8338 25.0072 59.699 25.0072 61.5838C25.0072 61.839 24.7978 62.0484 24.5426 62.0484C24.2874 62.0484 24.078 61.839 24.078 61.5838C24.078 59.5288 24.6211 57.5065 25.655 55.733C29.0445 49.9214 34.5541 45.6936 41.176 43.835Z"
          fill="#494953"
        />
        <path
          d="M57.5298 45.2028L57.5757 45.0589L57.6738 44.7578L57.5298 45.2028Z"
          fill="#494953"
        />
        <path
          d="M46.3864 43.8466C45.4834 43.9186 44.5804 44.0364 43.6905 44.2065C43.4353 44.2524 43.1932 44.0887 43.1474 43.8335C43.1016 43.5848 43.2652 43.3361 43.5204 43.2903C44.4365 43.1202 45.3787 42.9958 46.3144 42.9238C50.9406 42.5508 54.6769 43.7812 57.6738 44.7628L57.5757 45.0639L57.3859 45.6464C54.461 44.6908 50.8294 43.4932 46.3864 43.8466Z"
          fill="#494953"
        />
        <path
          d="M60.4537 44.5753C60.3162 44.7912 60.0348 44.8632 59.8123 44.7258C59.5963 44.5949 59.5243 44.3069 59.6618 44.0844C59.917 43.6656 60.1984 43.1878 60.4798 42.6773C60.6369 42.3894 60.8136 42.108 60.9903 41.8396C61.1343 41.6237 61.4223 41.5713 61.6382 41.7087C61.7691 41.8004 61.8477 41.9444 61.8477 42.0949C61.8477 42.1865 61.8215 42.2716 61.7691 42.3501C61.6055 42.5988 61.4419 42.8606 61.2979 43.1224C61.0034 43.659 60.722 44.1433 60.4537 44.5753Z"
          fill="#494953"
        />
        <path
          d="M50.9953 65.0745C50.5831 64.9371 49.9549 64.7342 49.3725 64.5575C47.9657 64.1387 46.4476 64.3808 45.3156 65.1989C44.8183 65.5588 44.4126 66.0039 44.1181 66.5078C43.117 68.2225 41.6447 69.5772 39.8649 70.4149C39.6359 70.5261 39.3545 70.428 39.2433 70.1924C39.132 69.9633 39.2302 69.6819 39.4657 69.5706C41.0754 68.8115 42.4038 67.5876 43.3133 66.0366C43.6732 65.4148 44.1639 64.8847 44.7659 64.4397C46.127 63.4515 47.946 63.1635 49.6277 63.6609C50.2232 63.8376 50.8579 64.047 51.2767 64.1845C54.7381 65.3232 60.5291 67.2211 66.6799 64.5902C67.223 64.3612 67.753 64.0994 68.27 63.818C68.4924 63.6936 68.7804 63.7787 68.8981 64.0078C68.9374 64.0798 68.957 64.1517 68.957 64.2303C68.957 64.3939 68.872 64.5575 68.7149 64.636C68.1784 64.9306 67.6156 65.1989 67.0463 65.441C60.5814 68.2225 54.5811 66.2525 50.9953 65.0745Z"
          fill="#494953"
        />
        <path
          d="M37.6866 70.1855C37.7716 70.1658 37.8501 70.1462 37.9352 70.1266C38.1839 70.0611 38.4391 70.2116 38.5045 70.4603C38.5176 70.4996 38.5176 70.5389 38.5176 70.5781C38.5176 70.781 38.3802 70.9708 38.1708 71.0297C38.0792 71.0559 37.9876 71.0755 37.896 71.0951C35.4618 71.6645 32.9295 71.2849 30.7701 70.0218C30.5477 69.8909 30.4757 69.6095 30.6 69.387C30.7309 69.1645 31.0123 69.0925 31.2347 69.2169C33.1978 70.3556 35.488 70.7025 37.6866 70.1855Z"
          fill="#494953"
        />
        <path
          d="M27.2529 56.6788C28.2933 54.8921 29.5693 53.2429 31.0481 51.7834C31.2313 51.6002 31.5258 51.6067 31.7024 51.7834C31.794 51.8751 31.8398 51.9929 31.8398 52.1107C31.8398 52.2285 31.794 52.3528 31.7024 52.4379C30.2825 53.845 29.0589 55.4287 28.0577 57.1434C27.2725 58.4916 26.8603 60.0296 26.8603 61.5872C26.8603 64.0676 27.9138 66.4498 29.759 68.1317C29.9488 68.3019 29.9619 68.5964 29.7917 68.7862C29.6216 68.976 29.3272 68.9891 29.1374 68.8189C27.1024 66.9668 25.9311 64.3293 25.9311 61.5872C25.9246 59.866 26.3892 58.1709 27.2529 56.6788Z"
          fill="#494953"
        />
        <path
          d="M61.0925 46.9872C61.0074 47.105 60.9289 47.2097 60.8569 47.3013C60.34 47.9623 59.4828 48.2372 58.6714 48.0016C57.9975 47.8053 57.2581 47.5631 56.8 47.4126C54.0387 46.5029 50.6034 45.3772 46.5399 45.7045C41.5603 46.1037 36.9407 47.9754 33.1717 51.1168C32.9754 51.2804 32.6809 51.2542 32.5173 51.0579C32.3537 50.8616 32.3799 50.5671 32.5762 50.4034C36.4957 47.1377 41.2986 45.194 46.4679 44.7751C50.7212 44.4348 54.2546 45.5932 57.101 46.5291C57.5394 46.673 58.2723 46.9152 58.9266 47.105C59.3716 47.2359 59.8427 47.0854 60.1241 46.7254C60.1961 46.6403 60.268 46.5422 60.34 46.4374C60.8504 45.7372 61.4459 44.7817 62.1002 43.5971C62.2376 43.3419 62.3947 43.0932 62.5517 42.8576C62.6957 42.6416 62.9836 42.5892 63.1995 42.7332C63.3304 42.8248 63.4023 42.9688 63.4023 43.1194C63.4023 43.211 63.3762 43.3026 63.3238 43.3811C63.1799 43.5971 63.0425 43.8262 62.9116 44.0552C62.2376 45.266 61.6225 46.2542 61.0925 46.9872Z"
          fill="#494953"
        />
        <path
          d="M61.4309 64.4856C61.3981 64.2303 61.5879 64.0013 61.8431 63.9686C63.263 63.8049 64.6502 63.4384 65.9655 62.8756C67.0255 62.424 68.0397 61.8612 68.982 61.2002C69.1914 61.0562 69.4793 61.102 69.6298 61.318C69.6887 61.3965 69.7148 61.4947 69.7148 61.5863C69.7148 61.7303 69.6429 61.8808 69.5185 61.9659C68.5239 62.6596 67.4508 63.2552 66.3319 63.733C64.9316 64.3351 63.4593 64.7212 61.9478 64.8913C61.686 64.9241 61.457 64.7408 61.4309 64.4856Z"
          fill="#494953"
        />
        <path
          d="M42.5191 65.5697C42.3882 65.7922 42.1069 65.8642 41.8844 65.7399C41.6619 65.609 41.5899 65.3275 41.7143 65.105C42.9248 63.0304 45.2019 61.6626 47.7997 61.4597C48.6961 61.3877 50.3058 61.9178 51.8697 62.4283C53.8065 63.0631 56.2145 63.855 58.9431 64.0383C59.1852 64.0579 59.375 64.2608 59.375 64.5029C59.375 64.516 59.375 64.5226 59.375 64.5357C59.3554 64.7909 59.1329 64.9872 58.8777 64.9676C56.0313 64.7778 53.5644 63.9663 51.5752 63.3118C50.2404 62.8733 48.5783 62.3301 47.8716 62.3825C45.5814 62.5592 43.5791 63.7503 42.5191 65.5697Z"
          fill="#494953"
        />
        <path
          d="M40.5659 67.2295C40.6313 67.3146 40.6641 67.4127 40.6641 67.5109C40.6641 67.6483 40.5986 67.7923 40.4808 67.8839C39.6564 68.5122 38.7207 68.9638 37.7064 69.2321C35.6649 69.7688 33.5383 69.4808 31.7126 68.4206C29.2916 67.007 27.7931 64.3957 27.7931 61.5946C27.7931 60.2006 28.1661 58.8263 28.8662 57.6155C30.8227 54.2582 33.6757 51.4571 37.1175 49.5199C37.34 49.3956 37.6279 49.4741 37.7522 49.6966C37.8766 49.9191 37.798 50.2071 37.5756 50.3314C34.2777 52.1901 31.5491 54.8734 29.6711 58.0867C29.0495 59.1535 28.7223 60.3642 28.7223 61.5946C28.7223 64.0685 30.0506 66.3721 32.1838 67.6221C33.7934 68.558 35.6714 68.8133 37.4709 68.3421C38.3673 68.1064 39.1918 67.7072 39.9181 67.1509C40.121 66.9873 40.4089 67.0266 40.5659 67.2295Z"
          fill="#494953"
        />
        <path
          d="M60.4694 50.0684C59.9001 50.3105 58.6961 49.9899 56.2162 49.1783C53.6119 48.321 50.3729 47.2608 46.6824 47.5553C44.1632 47.7582 41.729 48.3734 39.4453 49.3812C39.2098 49.4859 38.935 49.3812 38.8303 49.1456C38.7256 48.91 38.8303 48.6351 39.0658 48.5304C41.4476 47.4768 43.9865 46.8354 46.6039 46.626C50.4841 46.3184 53.8213 47.4113 56.5041 48.2948C57.5314 48.6351 59.6777 49.3354 60.0964 49.2111C61.4182 48.6482 63.1522 45.5199 63.7215 44.4924C63.8066 44.3354 63.8982 44.1848 63.9963 44.0278C64.1337 43.8118 64.4216 43.7529 64.6376 43.8903C64.775 43.982 64.8535 44.1259 64.8535 44.283C64.8535 44.3681 64.8273 44.4597 64.7815 44.5317C64.6965 44.6691 64.6114 44.8066 64.5394 44.9375C63.3943 47.0055 61.9024 49.4532 60.4694 50.0684Z"
          fill="#494953"
        />
        <path
          d="M52.1598 61.531C50.4389 60.9682 48.8096 60.4316 47.7299 60.5166C46.6241 60.6083 45.5575 60.8831 44.576 61.3347C44.3404 61.446 44.0656 61.3413 43.9609 61.1056C43.8496 60.87 43.9543 60.5952 44.1899 60.4905C45.2761 59.9865 46.4408 59.6855 47.6579 59.5873C48.9274 59.4891 50.6417 60.052 52.4543 60.6475C55.798 61.747 60.3718 63.2457 65.2336 61.1645C66.8891 60.4577 68.3745 59.4695 69.7682 58.1606C69.958 57.9839 70.2524 57.997 70.4225 58.1802C70.5076 58.2719 70.5469 58.3831 70.5469 58.5009C70.5469 58.6253 70.5011 58.7496 70.4029 58.8412C68.9306 60.2287 67.3537 61.2693 65.6 62.0219C60.4111 64.2405 55.6409 62.6763 52.1598 61.531Z"
          fill="#494953"
        />
        <path
          d="M37.2254 67.4347C35.668 67.847 34.0387 67.6245 32.645 66.8129C30.7932 65.7331 29.6481 63.7305 29.6481 61.5904C29.6481 60.5236 29.9295 59.47 30.4726 58.5472C30.6034 58.3247 30.8848 58.2527 31.1073 58.377C31.3297 58.5079 31.4017 58.7893 31.2774 59.0118C30.8194 59.7906 30.5838 60.6807 30.5838 61.5838C30.5838 63.3967 31.5588 65.0917 33.1226 66.0014C34.3005 66.6886 35.6811 66.8784 37.0029 66.525C38.3247 66.1781 39.4305 65.3339 40.1176 64.1559C40.6083 63.3182 41.2365 62.5655 41.9825 61.9176C42.1788 61.7475 42.4732 61.7736 42.6368 61.9634C42.7153 62.0485 42.748 62.1598 42.748 62.2645C42.748 62.3954 42.6957 62.5263 42.5845 62.6179C41.9105 63.2003 41.3478 63.8744 40.9159 64.6205C40.098 66.0341 38.7893 67.0289 37.2254 67.4347Z"
          fill="#494953"
        />
        <path
          d="M32.8778 56.6826C32.7143 56.8855 32.4263 56.9117 32.2235 56.7546C32.0207 56.591 31.9945 56.303 32.1515 56.1002C35.6784 51.7153 41.0048 48.9339 46.763 48.4758C47.3716 48.4299 48.0063 48.4169 48.641 48.443C48.8896 48.4561 49.0859 48.659 49.0859 48.9077C49.0859 48.9142 49.0859 48.9208 49.0859 48.9273C49.0729 49.1826 48.8569 49.3854 48.6017 49.3724C48.0063 49.3462 47.4108 49.3527 46.835 49.4051C41.332 49.8436 36.2412 52.5007 32.8778 56.6826Z"
          fill="#494953"
        />
        <path
          d="M61.2025 51.7674C59.8872 52.3302 58.0158 51.715 55.6405 50.9362C54.2403 50.4781 52.6567 49.9546 50.9358 49.6404C50.6806 49.5946 50.517 49.3525 50.5628 49.0972C50.6086 48.842 50.8507 48.6784 51.1059 48.7242C52.8858 49.0514 54.502 49.5815 55.935 50.0527C58.1205 50.7726 59.848 51.3355 60.8426 50.9166C62.5177 50.1967 64.0685 47.7229 65.3576 45.393C65.4819 45.1705 65.7632 45.0854 65.9923 45.2098C66.1428 45.2948 66.2344 45.4519 66.2344 45.6155C66.2344 45.6941 66.2147 45.766 66.1755 45.838C64.8014 48.3053 63.1393 50.9362 61.2025 51.7674Z"
          fill="#494953"
        />
        <path
          d="M52.7385 59.768C51.5476 59.3753 50.4156 59.0089 49.3751 58.806C49.12 58.7602 48.9564 58.5115 49.0087 58.2628C49.0545 58.0141 49.3032 57.8439 49.5518 57.8963C50.6511 58.1057 51.8093 58.4853 53.0264 58.8911C56.2588 59.9513 60.2765 61.2733 64.497 59.467C66.4143 58.6489 68.109 57.3662 69.6925 55.5599C69.8626 55.3635 70.1571 55.3439 70.3469 55.5141C70.4516 55.6057 70.5039 55.7366 70.5039 55.8675C70.5039 55.9787 70.4646 56.0834 70.3861 56.1751C68.711 58.0926 66.905 59.4473 64.8569 60.3243C60.3223 62.2615 56.1214 60.8806 52.7385 59.768Z"
          fill="#494953"
        />
        <path
          d="M48.0013 58.1993C48.0013 58.4414 47.8181 58.6443 47.5759 58.6639C44.0621 58.9454 40.9736 60.8236 39.305 63.6902C38.7423 64.6587 37.8393 65.3459 36.7596 65.6273C35.68 65.9153 34.5545 65.7582 33.5861 65.1954C32.3036 64.4493 31.5118 63.0619 31.5118 61.5763C31.5118 60.8367 31.7081 60.1103 32.0811 59.4689C32.5588 58.6443 33.1084 57.859 33.7169 57.1194C33.8805 56.9231 34.175 56.8904 34.3713 57.054C34.5676 57.2176 34.6003 57.5121 34.4367 57.7084C33.8609 58.4087 33.344 59.1613 32.8859 59.9401C32.598 60.4375 32.441 61.0003 32.441 61.5763C32.441 62.7281 33.0626 63.808 34.0572 64.3904C34.8097 64.8289 35.6865 64.9467 36.5241 64.7242C37.3616 64.5017 38.0683 63.965 38.5067 63.2189C40.3258 60.0906 43.6957 58.0422 47.5105 57.7346C47.7657 57.715 47.9882 57.9048 48.0078 58.16C48.0013 58.1731 48.0013 58.1862 48.0013 58.1993Z"
          fill="#494953"
        />
        <path
          d="M62.6629 51.9953C62.8723 51.8448 63.1602 51.8906 63.3107 52.0935C63.3696 52.1785 63.4023 52.2702 63.4023 52.3683C63.4023 52.5123 63.3369 52.6497 63.2126 52.7414C62.7938 53.049 62.3619 53.2977 61.9366 53.4809C59.9801 54.3186 57.8077 53.6053 55.0595 52.7021C52.658 51.9102 49.9294 51.0202 46.9849 51.2558C43.0064 51.5764 39.2047 53.1799 36.2994 55.7715C36.1097 55.9417 35.8152 55.9286 35.6451 55.7322C35.4749 55.5424 35.488 55.2479 35.6843 55.0778C38.7467 52.3487 42.7382 50.6602 46.9194 50.3264C50.0537 50.0777 52.8739 51.0005 55.3605 51.8186C57.9189 52.6563 59.9409 53.3238 61.5767 52.6236C61.9366 52.473 62.303 52.2571 62.6629 51.9953Z"
          fill="#494953"
        />
        <path
          d="M63.7933 51.6643C63.6101 51.4876 63.597 51.1931 63.7803 51.0098C64.6769 50.0674 65.5473 48.8305 66.5944 46.9915C66.7253 46.769 67.0067 46.6904 67.2292 46.8213C67.3798 46.9064 67.4648 47.0635 67.4648 47.2271C67.4648 47.3056 67.4452 47.3842 67.4059 47.4561C66.313 49.3606 65.4033 50.6564 64.4543 51.6512C64.2711 51.8345 63.9766 51.841 63.7933 51.6643Z"
          fill="#494953"
        />
        <path
          d="M64.1253 58.6158C60.9844 59.9574 57.9352 59.3815 55.6253 58.7336C55.3767 58.6616 55.2327 58.4063 55.3047 58.1576C55.3767 57.9089 55.6319 57.765 55.8805 57.837C58.1773 58.4849 60.9059 58.9823 63.7588 57.7584C65.7938 56.888 67.6064 55.35 69.3011 53.066C69.4516 52.8631 69.7461 52.8173 69.9489 52.9678C70.0732 53.0594 70.1387 53.1969 70.1387 53.3408C70.1387 53.439 70.106 53.5372 70.0471 53.6157C68.2542 56.0437 66.3173 57.6733 64.1253 58.6158Z"
          fill="#494953"
        />
        <path
          d="M53.3155 58.0015C51.2281 57.3143 49.252 56.6664 47.4264 56.8169C43.3106 57.1441 39.6724 59.3693 37.6963 62.7594C37.3822 63.296 36.8784 63.6756 36.2829 63.8392C35.6809 63.9963 35.0593 63.9112 34.5227 63.5971C33.4169 62.9491 33.0374 61.5224 33.6852 60.4164C35.9034 56.6075 39.7052 53.7868 44.1154 52.6677C44.3641 52.6022 44.6193 52.7527 44.6782 53.0014C44.7436 53.2501 44.5931 53.5054 44.3444 53.5643C40.1763 54.6179 36.5839 57.2881 34.49 60.8811C34.104 61.5421 34.3264 62.3994 34.9939 62.7855C35.3145 62.9753 35.694 63.0211 36.0539 62.9295C36.4138 62.8313 36.7148 62.6023 36.9046 62.2816C39.0312 58.6297 42.9442 56.2344 47.3675 55.881C49.3829 55.7174 51.4375 56.3981 53.623 57.1114L53.3155 58.0015Z"
          fill="#494953"
        />
        <path
          d="M60.2695 55.2453C60.2695 55.2518 60.2695 55.2518 60.2695 55.2584C60.263 55.5136 60.0471 55.7165 59.7919 55.71C58.1037 55.6576 56.35 55.0817 54.4851 54.473C52.136 53.7008 49.715 52.9023 47.1369 53.1118C46.8817 53.1314 46.6592 52.9416 46.6396 52.6864C46.6199 52.4311 46.8097 52.2086 47.0649 52.189C49.8262 51.9665 52.3454 52.7976 54.7796 53.5961C56.5725 54.1851 58.2672 54.7414 59.8246 54.7872C60.0667 54.7872 60.2695 54.9966 60.2695 55.2453Z"
          fill="#494953"
        />
        <path
          d="M62.6669 55.1768C62.4313 55.2749 62.1565 55.1702 62.0583 54.9346C61.9602 54.699 62.0649 54.4241 62.3004 54.326C64.198 53.5145 65.8601 51.7998 67.6988 48.7631C67.8296 48.5406 68.1175 48.4751 68.34 48.606C68.484 48.6911 68.5625 48.8482 68.5625 49.0053C68.5625 49.0903 68.5429 49.1689 68.4971 49.2474C67.1818 51.4071 65.3431 54.0315 62.6669 55.1768Z"
          fill="#494953"
        />
        <path
          d="M47.7129 54.5012C47.7129 54.7434 47.5297 54.9462 47.2876 54.9659C42.5632 55.3455 38.3819 57.9044 36.0983 61.8246C35.9674 62.0471 35.686 62.1191 35.4636 61.9947C35.2411 61.8638 35.1691 61.5824 35.2934 61.3599C37.7276 57.1714 42.1837 54.4358 47.209 54.0366C47.4642 54.0169 47.6867 54.2067 47.7063 54.4619C47.7129 54.475 47.7129 54.4881 47.7129 54.5012Z"
          fill="#494953"
        />
        <path
          d="M63.401 56.8991C60.1555 58.2931 56.9754 57.2459 53.9 56.2381C52.624 55.8192 51.3022 55.3873 50.0132 55.1451C49.758 55.0993 49.5944 54.8572 49.6402 54.6019C49.686 54.3467 49.9281 54.1831 50.1833 54.2289C51.5378 54.4841 52.8857 54.9292 54.1879 55.3546C57.2306 56.3559 60.1031 57.2983 63.0281 56.0483C65.0631 55.1779 66.8363 53.509 68.6161 50.7996C68.7601 50.5836 69.048 50.5247 69.2574 50.6687C69.3948 50.7603 69.4668 50.9043 69.4668 51.0548C69.4668 51.1399 69.4406 51.2315 69.3883 51.31C67.5103 54.1831 65.6062 55.9567 63.401 56.8991Z"
          fill="#494953"
        />
      </g>
      <defs>
        <clipPath id="clip0_4063_21073">
          <rect
            width="113"
            height="156"
            fill="white"
            transform="translate(156) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
