import { formatErrorDate } from "shared/utils/formatErrorDate";

export const formatErrorMessageToLog = (error): string => {
  const currentPageHref = window?.location?.href;

  const formattedDate = formatErrorDate(new Date());

  const errorMessage = error?.message;
  const errorMessageFromAPI = error?.response?.data?.message;

  const method = error?.config?.method.toUpperCase();
  const requestURL = error?.request?.responseURL;

  const lines = [
    `\n*Origem:* ${currentPageHref}`,
    `\n*Requisição:* ${method} --> ${requestURL}`,
    `\n*Horário da tentativa:* ${formattedDate}`,
    `\n*Informações:*`,
    `\`\`\`
      \nErro message from (errorHandler.ts): ${errorMessage}
      ${errorMessageFromAPI ? `\nAPI message: ${errorMessageFromAPI}` : ""}
    \`\`\``,
  ];

  const logText = lines.reduce(
    (currentText, currentLine) => currentText + currentLine,
    "",
  );

  return logText;
};
