import React from "react";

import { usePathname, useRouter } from "next/navigation";

import { OptionArrowIcon } from "shared/providers/Layout/components/NewTopBar/assets/OptionArrowIcon";
import { Logo } from "shared/providers/Layout/components/NewTopBar/components/atoms/Logo";

import { GlobalTopbarHeaderContainer } from "./styles";

const routesMap = {
  home: "Home",
  cp: "Painel de controle",
  user: "Meu perfil",
  account: "Minha conta",
  courses: "Cursos",
  console: "Admin Console",
  g: "Grupos",
  authentication: "Acesse sua conta",
  404: "Página não encontrada",
  401: "Usuário não logado",
  blog: "Blog",
  bundles: "Pacotes",
  categories: "Categorias",
  collections: "Coleções",
  myaccess: "Meus acessos",
  orders: "Pedidos",
  payment: "Checkout",
  plans: "Planos",
  search: "Pesquisa",
  subscriptions: "Assinaturas",
  userscommunity: "Usuários",
  c: "Certificado",
  confirm: "Confirmar conta",
  events: "Eventos",
  "first-access": "Primeiro acesso",
  media: "Mídia",
  pages: "Páginas",
  reset: "Recuperar senha",
  social: "Comunidade",
};

export const GlobalTopbarHeader = () => {
  const { back } = useRouter();
  const asPath = usePathname();
  const currentPath = asPath.split("/")[1];

  const pageHeader = routesMap[currentPath];

  const isInHomePage =
    currentPath === "browse" ||
    currentPath === "dashboard" ||
    currentPath === "";

  return (
    <GlobalTopbarHeaderContainer shouldAddAlignAndPadding={!isInHomePage}>
      {isInHomePage ? (
        <Logo />
      ) : (
        <>
          <button onClick={back}>
            <OptionArrowIcon />
          </button>
          {pageHeader}
        </>
      )}
    </GlobalTopbarHeaderContainer>
  );
};
