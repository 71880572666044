import styled from "styled-components";

export const CircularLoaderContainer = styled.div`
  display: flex;
  justify-content: center;

  .loader {
    color: ${({ theme }) => theme?.groups?.primaryText};
    animation: loading 0.5s linear infinite forwards;
    font-size: 16px;
    height: 2rem;
    width: 2rem;
  }

  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
    from {
      transform: rotate(0);
    }
  }
`;
