import styled from "styled-components";

export const SwitchThemeContainer = styled.div`
  background: transparent;
  border: none;

  width: 100%;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;

  :hover:not(:disabled) {
    cursor: pointer;
  }

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .sun-icon {
    position: relative;
    top: -1.5px;
    left: -1.5px;
  }
`;
