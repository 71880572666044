import { ButtonHTMLAttributes } from "react";

import { IConsolePageButtonVariants } from "pages/[tenant]/console/components/molecules/ConsolePageForm/atoms/ConsolePageButton/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { ConsolePageButtonContainer } from "./styles";

interface IConsolePageButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: IConsolePageButtonVariants;
  id: string;
}

export const ConsolePageButton = ({ ...props }: IConsolePageButtonProps) => {
  const customId = removeSpacesAndAccentsFromString(props.id);

  return <ConsolePageButtonContainer {...props} id={customId} />;
};
