import styled from "styled-components";

export const ContainerSendDocumentsImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  label {
    font-size: 0.813rem;
    font-weight: 500;
    text-align: left;
    color: ${({ theme }) => theme?.myDocuments?.primaryDescription};

    display: flex;
    gap: 0.5rem;
  }

  .section-document-type {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .radio-type-documents {
    display: flex;
    gap: 1.5rem;
  }

  input[type="radio"] {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border-radius: 50%;
    border: 2px solid #787878;
  }

  input[type="radio"]::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  input[type="radio"]:checked::before {
    background-color: #605cff;
  }
  input[type="radio"]:checked {
    border: 2px solid #605cff;
  }

  .choose-file {
    width: 100%;
    justify-content: space-between;

    display: flex;
    flex-direction: column;

    padding: 0 0 0.5rem 0;
  }
`;

export const ContainerAlertDocumentImage = styled.div`
  background: #0f6213;
  padding: 0.75rem;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  gap: 0.75rem;
  span,
  svg {
    color: #ffffff;
  }

  span {
    font-size: 0.813rem;
    font-weight: 500;
    text-align: left;
  }
`;

export const ContainerIdentity = styled.div`
  display: flex;
  gap: 1rem;
  svg rect {
    fill: ${({ theme }) => theme?.myDocuments?.backgroundImageDocuments};
    stroke: ${({ theme }) => theme?.myDocuments?.linesImageDocuments};
  }

  svg path {
    fill: ${({ theme }) => theme?.myDocuments?.linesImageDocuments};
  }

  svg ellipse {
    fill: ${({ theme }) => theme?.myDocuments?.linesImageDocuments};
  }

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

export const ContainerIdentityBack = styled.div`
  rect {
    fill: ${({ theme }) => theme?.myDocuments?.backgroundImageDocuments};
    stroke: ${({ theme }) => theme?.myDocuments?.linesImageDocuments};
  }

  display: flex;
  gap: 1rem;

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

export const ContainerSelfWithDocument = styled.div`
  .box-selfie {
    border: 1px solid ${({ theme }) => theme?.myDocuments?.linesImageDocuments};
    background: ${({ theme }) => theme?.myDocuments?.backgroundImageDocuments};

    width: 12.5rem;
    height: 10rem;

    border-radius: 0.5rem;

    display: flex;
    align-items: end;
  }
  @media (max-width: 550px) {
    flex-wrap: wrap;
  }

  display: flex;
  gap: 1rem;

  .input-choose-file {
    display: inline-block;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }

  .input-choose-file:hover {
    background-color: #0056b3;
  }

  .input-choose-file:active {
    background-color: #004b9e;
  }
`;

interface IContainerSendDocumentsImage {
  inputDisabled: boolean;
}

export const ContainerChooseFile = styled.div<IContainerSendDocumentsImage>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-top: 0.5rem;
  label {
    background: ${(props) =>
      props.inputDisabled === false ? "#605CFF" : "#23A840"};

    border-radius: 0.25rem;
    color: #f2f2f2;
    font-style: normal;
    gap: 0.5rem;
    padding: 0.375rem 1rem;
    line-height: 150%;
    transition: all 100ms ease-in-out;
    opacity: 0.9;

    font-size: 13px;
    font-weight: 600;
    text-align: center;

    opacity: 1;
    width: 8.625rem;

    input[type="file"] {
      display: none;
    }
  }
  span,
  a {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 13px;
    font-weight: 400;
    text-align: left;

    color: ${({ theme }) => theme?.myDocuments?.secondaryDescription};
  }

  a {
    color: #605cff;
  }
`;

export const ButtonRemove = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-align: center;

  background-color: ${({ theme }) => theme?.myDocuments?.buttonRemove};
  color: ${({ theme }) => theme?.myDocuments?.primaryDescription};

  padding: 0.375rem 1rem;
  border-radius: 0.25rem;
`;
