import styled from "styled-components";

export const ContainerDescriptionDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  p,
  span {
    margin-bottom: 0;
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme?.myDocuments?.primaryDescription};
  }

  span {
    font-size: 0.813rem;
    font-weight: 400;
    color: ${({ theme }) => theme?.myDocuments?.secondaryDescription};
  }

  #alert-required {
    color: #ec3434;
  }

  .title-required {
    display: flex;
    gap: 0.5rem;
  }
`;
