import React from "react";

import AppNotification from "shared/components/organisms/AppNotification";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";

import { NotificationsContainer } from "./styles";

export const Notifications = () => {
  const { notificationsIsOpen } = useTopbarContext();

  return (
    <NotificationsContainer show={notificationsIsOpen}>
      <AppNotification notificationsIsOpen={notificationsIsOpen} />
    </NotificationsContainer>
  );
};
