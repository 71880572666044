import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { FaCamera } from "react-icons/fa";

import { Identity } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/assets/Identity";
import { IdentityBack } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/assets/IdentityBack";
import { SelfWithDocument } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/assets/SelfWithDocument";

import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import {
  ButtonRemove,
  ContainerAlertDocumentImage,
  ContainerChooseFile,
  ContainerIdentity,
  ContainerIdentityBack,
  ContainerSelfWithDocument,
  ContainerSendDocumentsImage,
} from "./styles";

export const SendDocumentsImage = ({}) => {
  const {
    setHasImagesDocuments,
    setCompanyData,
    companyData,
    initialDataDocuments,
  } = useDocumentsContext();
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentBack, setDocumentBack] = useState<File | null>(null);
  const [selfieWithDocument, setSelfieWithDocument] = useState<File | null>(
    null,
  );

  const initialImages = initialDataDocuments?.images;
  const changeEditUserData = (dataToUpdate) => {
    setCompanyData((prev) => ({
      ...prev,
      ...dataToUpdate,
    }));
  };

  const [selectedOption, setSelectedOption] = useState<string>("");
  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);

    changeEditUserData({
      document_type: e.target.value,
    });
  };

  useEffect(() => {
    setSelectedOption(companyData?.document_type);
    setDocumentFront(initialImages?.front_of_document);
    setDocumentBack(initialImages?.back_of_document);
    setSelfieWithDocument(initialImages?.selfie_with_document);
  }, []);

  const inputFileFrontRef = useRef<HTMLInputElement>(null);
  const inputFileBackRef = useRef<HTMLInputElement>(null);
  const inputFileSelfieRef = useRef<HTMLInputElement>(null);

  const handleAddDocument = (
    event: ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<File | null>>,
    key: string,
  ) => {
    const file = event.target.files?.[0];
    if (file && /\.(jpe?g|png)$/i.test(file.name)) {
      setter(file);
      changeEditUserData({
        [key]: file,
      });
    }
  };

  const handleRemoveDocument = (
    setter: React.Dispatch<React.SetStateAction<File | null>>,
    key: string,
  ) => {
    setter(null);
    if (setter === setDocumentFront && inputFileFrontRef.current) {
      inputFileFrontRef.current.value = "";
    } else if (setter === setDocumentBack && inputFileBackRef.current) {
      inputFileBackRef.current.value = "";
    } else if (setter === setSelfieWithDocument && inputFileSelfieRef.current) {
      inputFileSelfieRef.current.value = "";
    }
    changeEditUserData({
      [key]: null,
    });
  };

  useEffect(() => {
    const hasAllImages = documentFront && documentBack && selfieWithDocument;
    const hasOptionSelected = selectedOption !== "";
    setHasImagesDocuments(hasAllImages && hasOptionSelected);
  }, [documentFront, documentBack, selfieWithDocument, selectedOption]);

  return (
    <ContainerSendDocumentsImage>
      <ContainerAlertDocumentImage>
        <div className="icon-camera">
          <FaCamera />
        </div>
        <span>
          Falta pouco, agora você enviará algumas imagens de algum documento,
          CNH ou RG. O documento não pode estar rasurado e as imagens precisam
          estar legíveis para o nosso entendimento.
        </span>
      </ContainerAlertDocumentImage>
      <div className="section-document-type">
        <DescriptionModalDocuments
          title="Tipo de documento "
          description="O documento selecionado será usado no envio de fotos."
          required={true}
        />
        <div className="radio-type-documents">
          <label>
            <input
              type="radio"
              value="cnh"
              checked={selectedOption === "cnh"}
              onChange={handleOptionChange}
            />
            CNH
          </label>
          <label>
            <input
              type="radio"
              value="rg"
              checked={selectedOption === "rg"}
              onChange={handleOptionChange}
            />
            RG
          </label>
        </div>
      </div>

      <ContainerIdentity>
        <div>
          <Identity />
        </div>
        <div className="choose-file">
          <DescriptionModalDocuments
            title="Frente do documento"
            description="Somente arquivos do tipo JPEG, JPG ou PNG."
            required={true}
          />
          <ContainerChooseFile inputDisabled={!!documentFront}>
            <label htmlFor="document-front">
              {!!documentFront ? "Arquivo anexado" : "Escolher arquivo"}
              <input
                ref={inputFileFrontRef}
                onChange={(e) =>
                  handleAddDocument(e, setDocumentFront, "front_of_document")
                }
                type="file"
                name="document-front"
                id="document-front"
                accept=".jpeg,.jpg,.png"
              />
            </label>
            {!!documentFront && (
              <ButtonRemove
                onClick={() =>
                  handleRemoveDocument(setDocumentFront, "front_of_document")
                }
              >
                Remover
              </ButtonRemove>
            )}
            {initialImages &&
              (typeof documentFront === "string" &&
              /^https:\/\//.test(documentFront) ? (
                <a
                  href={documentFront}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {documentFront}
                </a>
              ) : (
                !!documentFront && <span>{documentFront?.name}</span>
              ))}
          </ContainerChooseFile>
        </div>
      </ContainerIdentity>
      <ContainerIdentityBack>
        <div>
          <IdentityBack />
        </div>
        <div className="choose-file">
          <DescriptionModalDocuments
            title="Verso do documento"
            description="Somente arquivos do tipo JPEG, JPG ou PNG."
            required={true}
          />
          <ContainerChooseFile inputDisabled={!!documentBack}>
            <label htmlFor="document-back">
              {!!documentBack ? "Arquivo anexado" : "Escolher arquivo"}

              <input
                ref={inputFileBackRef}
                onChange={(e) =>
                  handleAddDocument(e, setDocumentBack, "back_of_document")
                }
                type="file"
                name="document-back"
                id="document-back"
                accept=".jpeg,.jpg,.png"
              />
            </label>
            {!!documentBack && (
              <ButtonRemove
                onClick={() =>
                  handleRemoveDocument(setDocumentBack, "back_of_document")
                }
              >
                Remover
              </ButtonRemove>
            )}
            {initialImages &&
              (typeof documentBack === "string" &&
              /^https:\/\//.test(documentBack) ? (
                <a
                  href={documentBack}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {documentBack}
                </a>
              ) : (
                !!documentBack && <span>{documentBack?.name}</span>
              ))}
          </ContainerChooseFile>
        </div>
      </ContainerIdentityBack>
      <ContainerSelfWithDocument>
        <div className="box-selfie">
          <SelfWithDocument />
        </div>
        <div className="choose-file">
          <DescriptionModalDocuments
            title="Selfie com documento "
            description="Precisamos de uma selfie sua segurando um documento e uma folha com a palavra 'Ensinio' e a data. sem acessórios, para confirmar sua identidade e titularidade"
            required={true}
          />
          <ContainerChooseFile inputDisabled={!!selfieWithDocument}>
            <label htmlFor="document-self">
              {!!selfieWithDocument ? "Arquivo anexado" : "Escolher arquivo"}

              <input
                ref={inputFileSelfieRef}
                onChange={(e) =>
                  handleAddDocument(
                    e,
                    setSelfieWithDocument,
                    "selfie_with_document",
                  )
                }
                type="file"
                name="document-self"
                id="document-self"
                accept=".jpeg,.jpg,.png"
              />
            </label>
            {!!selfieWithDocument && (
              <ButtonRemove
                onClick={() =>
                  handleRemoveDocument(
                    setSelfieWithDocument,
                    "selfie_with_document",
                  )
                }
              >
                Remover
              </ButtonRemove>
            )}
            {initialImages &&
              (typeof selfieWithDocument === "string" &&
              /^https:\/\//.test(selfieWithDocument) ? (
                <a
                  href={selfieWithDocument}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selfieWithDocument}
                </a>
              ) : (
                !!selfieWithDocument && <span>{selfieWithDocument?.name}</span>
              ))}
          </ContainerChooseFile>
        </div>
      </ContainerSelfWithDocument>
    </ContainerSendDocumentsImage>
  );
};
