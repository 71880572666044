import { ContainerCardPersonalType } from "./styles";

export const CardPersonalType = ({
  icon,
  title,
  description,
  onClick,
  selected,
}) => {
  return (
    <ContainerCardPersonalType onClick={onClick} selected={selected}>
      <div className="icon-person-type">{icon}</div>
      <div className="type-person">
        <h3 className="title-person-type">{title}</h3>
        <span className="description-person">{description}</span>
      </div>
    </ContainerCardPersonalType>
  );
};
