import { CompanyData } from "@Console/components/organisms/ModalSendDocuments/Content/CompanyData";
import { EntityType } from "@Console/components/organisms/ModalSendDocuments/Content/EntityType";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { AlertUpdateDocuments } from "./Content/AlertUpdateDocuments";
import { CnpjIdentification } from "./Content/CnpjIdentification";
import FinallyCreate from "./Content/FinallyCreate";
import { SendDocumentsImage } from "./Content/SendDocumentsImage";
import { ContainerSendDocuments } from "./styles";

export const ModalSendDocuments = () => {
  const { steps } = useDocumentsContext();

  const settingsSection = {
    0: <AlertUpdateDocuments />,
    1: <EntityType />,
    2: <CompanyData />,
    3: <CnpjIdentification />,
    4: <SendDocumentsImage />,
    5: <FinallyCreate />,
  };

  return (
    <ContainerSendDocuments>
      <div className="document-content">{settingsSection[steps]}</div>
    </ContainerSendDocuments>
  );
};
