import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import { ModulesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/individualGroup/ModulesIcon";
import { CustomGroupSectionLabel } from "pages/[tenant]/console/components/atoms/CustomGroupSectionLabel";
import { GroupOverviewIcon } from "pages/[tenant]/console/groups/[group_slug]/overview/assets/icons/GroupOverviewIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

export const useIndividualGroupOptions = (): IOptionsHook => {
  const pathname = usePathname();

  const { query } = useRouter();
  const group_slug = query?.group_slug;
  const module_slug = query?.module_slug;
  const lesson_slug = query?.lesson_slug;

  const sectionLabel = "Configuração do grupo";

  const individualGroupSubSection: ISubSection[] = [
    {
      label: "",
      options: [
        {
          id: 1,
          label: "Módulos",
          path: `/console/groups/${group_slug}/modules`,
          icon: <ModulesIcon />,
          shouldHide: pathname !== `/console/groups/${group_slug}/modules`,
        },
        {
          id: 2,
          label: "Módulos ",
          path: `/console/groups/${group_slug}/modules/${module_slug}/lesson-types`,
          icon: <ModulesIcon />,
          shouldHide:
            pathname !==
            `/console/groups/${group_slug}/modules/${module_slug}/lesson-types`,
        },
        {
          id: 3,
          label: "Módulos  ",
          path: `/console/groups/${group_slug}/modules/${module_slug}/lesson-types/${lesson_slug}`,
          icon: <ModulesIcon />,
          shouldHide:
            pathname !==
            `/console/groups/${group_slug}/modules/${module_slug}/lesson-types/${lesson_slug}`,
        },
        {
          id: 4,
          label: "Overview",
          path: `/console/groups/${group_slug}/overview`,
          icon: <GroupOverviewIcon />,
        },
      ],
    },
  ];

  return {
    label: sectionLabel,
    subSections: individualGroupSubSection,
    customSectionLabel: () => <CustomGroupSectionLabel />,
  };
};
