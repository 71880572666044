import styled, { css } from "styled-components";

import { BaseSideBarSectionButton } from "pages/[tenant]/console/styles/BaseSideBarSectionButton";

import { consolePrimaryColor } from "@Console/styles/consoleGlobalColors";

interface ISideBarLinkContainerProps {
  tooltipLabel: string;
  disabled: boolean;
}

export const SideBarLinkContainer = styled.div<ISideBarLinkContainerProps>`
  a {
    ${BaseSideBarSectionButton}
    color: ${({ theme }) => theme.console.sidebar.optionTextColor};

    &.active {
      background: ${consolePrimaryColor} !important;
      border-radius: 4px;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      a:hover {
        cursor: not-allowed !important;
      }
    `}
`;
