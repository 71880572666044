import { ChangeEvent, useState } from "react";

import { InputMyDocuments } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/components/InputMyDocuments";
import {
  formatCNPJ,
  validaCpfCnpj,
} from "pages/[tenant]/console/users/utils/userUtils";

import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { AddressFormMyDocuments } from "./components/AddressFormMydocuments";
import { LegalRepresentative } from "./components/LegalRepresentative";
import { ContainerCompanyData } from "./styles";

export const CompanyData = () => {
  const { setCompanyData, companyData, personType, errors, setErrors } =
    useDocumentsContext();
  const [documentFormatted, setDocumentFormatted] = useState(null);

  const changeEditUserData = ({ key, value }) => {
    setCompanyData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const changeErrors = ({ key, value }) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCheckDocumentEntity = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setErrors((prevErrors) => {
      const { cnpj, ...restErrors } = prevErrors;
      return restErrors;
    });

    let document;

    document = formatCNPJ(target.value);

    changeEditUserData({ key: "cnpj", value: document });

    setDocumentFormatted(document);
    setErrors((prevErrors) => {
      const { mainDocument, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  const OnBlurDocument = () => {
    const isValid = validaCpfCnpj(documentFormatted);
    if (!isValid) {
      changeErrors({
        key: "cnpj",
        value: "Este documento não é um documento válido",
      });
    }
  };

  const handleKeyPressDocument = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const allowedKeys = /[0-9\b\u007F]/;

    if (!allowedKeys.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <ContainerCompanyData>
      {personType === "legal_entity" && (
        <div className="legal-person-identification">
          <DescriptionModalDocuments
            title="Dados da empresa"
            description="Informe aqui o seu CNPJ para termos mais informações da sua empresa."
          />

          <InputMyDocuments
            label={"CNPJ"}
            value={companyData?.cnpj || ""}
            onChange={handleCheckDocumentEntity}
            required
            id="document"
            onKeyUp={handleKeyPressDocument}
            maxLength={18}
            onBlur={OnBlurDocument}
            error={errors.cnpj}
            isInvalid={!!errors.cnpj}
          />
        </div>
      )}

      <LegalRepresentative
        setCompanyData={setCompanyData}
        companyData={companyData}
        errors={errors}
        setErrors={setErrors}
        personType={personType}
      />
      <AddressFormMyDocuments
        setCompanyData={setCompanyData}
        companyData={companyData}
        errors={errors}
        setErrors={setErrors}
      />
    </ContainerCompanyData>
  );
};
