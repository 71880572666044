import moment from "moment";

import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { useTopFlagTextsContent } from "@Console/billing/hooks/useTopFlagTextsContent/index.tsx";
import {
  ITopFlagContent,
  ITopFlagContentMapStatus,
} from "@Console/billing/types";
import { topFlagContentMap } from "@Console/billing/utils/topFlagUtils";

const oneDayInMiliseconds = 1000 * 60 * 60 * 24 * 1; // 86.400.000 milliseconds
const sixDaysInMiliseconds = oneDayInMiliseconds * 6;

export const useTopFlagConfig = () => {
  const { getTextsFromBillingKey } = useTopFlagTextsContent();

  const {
    state: { tenancy },
  } = useGlobalContext();
  const { billing } = tenancy;

  let billingKey: ITopFlagContentMapStatus;

  let trialIsInProgress;
  let trialIsFinishing;
  let trialIsFinished;
  let unableToInvoice;
  let serviceWasInterrupted;

  const trialEndsAtTimeInMiliseconds = new Date(
    billing?.trial_ends_at,
  ).getTime();
  const subscriptionEndsAtTimeInMiliseconds = new Date(
    billing?.subscription_ends_at,
  ).getTime();
  const nowTimeInMilliseconds = new Date().getTime();

  const subscriptionAndTrialEndsAtSameTime =
    subscriptionEndsAtTimeInMiliseconds === trialEndsAtTimeInMiliseconds;

  if (subscriptionAndTrialEndsAtSameTime) {
    trialIsInProgress = moment(trialEndsAtTimeInMiliseconds).isAfter(
      moment.now(),
    );

    trialIsFinishing = moment(
      nowTimeInMilliseconds + sixDaysInMiliseconds,
    ).isAfter(trialEndsAtTimeInMiliseconds);

    trialIsFinished = moment(trialEndsAtTimeInMiliseconds).isBefore(
      moment.now(),
    );
  }

  const subscriptionIsInBeforeNow = moment(
    subscriptionEndsAtTimeInMiliseconds,
  ).isBefore(moment.now());

  if (subscriptionIsInBeforeNow) {
    unableToInvoice =
      billing?.billing_status === "pending" ||
      billing?.billing_status === "unpaid";

    serviceWasInterrupted = billing?.billing_status === "canceled";
  }

  if (unableToInvoice) billingKey = "billingFailed";
  else if (serviceWasInterrupted) billingKey = "canceledService";
  else if (trialIsFinished) billingKey = "trialIsFinished";
  else if (trialIsFinishing) billingKey = "trialIsFinishing";
  else if (trialIsInProgress) billingKey = "trial";

  const flagConfig: ITopFlagContent = {
    ...topFlagContentMap[billingKey],
    ...getTextsFromBillingKey(billingKey),
  };

  return {
    flagConfig,
  };
};
