import { ChangeEvent } from "react";

import { InputMyDocuments } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/components/InputMyDocuments";
import { formatCEP } from "pages/[tenant]/console/users/utils/userUtils";

import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";
import { useAddress } from "@Console/components/organisms/ModalSendDocuments/hooks/useAddress";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { AddressDetails } from "./components/AddressDetails";
import { ContainerAddressFormMyDocuments } from "./styles";

interface UserData {
  [key: string]: any;
}

interface Errors {
  [key: string]: string | undefined;
}

export const AddressFormMyDocuments = ({
  setCompanyData,
  companyData,
  errors,
  setErrors,
}) => {
  const { getAddressBrazilAPIRequest } = useAddress();
  const { setPersonType, personType } = useDocumentsContext();

  const changeErrors = ({ key, value }: { key: string; value: string }) => {
    setErrors((prev: Errors) => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeEditUserData = ({ key, value }: { key: string; value: any }) => {
    setCompanyData((prev: UserData) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getAddressBrazilAPI = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const zipcode = e.currentTarget.value.replace(/[^0-9]/g, "");

    if (zipcode?.length === 8) {
      const address = await getAddressBrazilAPIRequest(zipcode);

      if (address?.erro) {
        changeEditUserData({
          key: "residencial_city",
          value: "",
        });
        changeEditUserData({
          key: "residencial_uf",
          value: "",
        });
        changeEditUserData({
          key: "residencial_neighborhood",
          value: "",
        });
        changeEditUserData({
          key: "residencial_street",
          value: "",
        });

        changeErrors({
          key: "residencial_cep",
          value: "Cep não encontrado, preencha as informações abaixo",
        });
      } else {
        changeEditUserData({
          key: "residencial_city",
          value: address?.localidade,
        });
        changeEditUserData({
          key: "residencial_uf",
          value: address?.uf,
        });
        changeEditUserData({
          key: "residencial_neighborhood",
          value: address?.bairro,
        });
        changeEditUserData({
          key: "residencial_street",
          value: address?.logradouro,
        });
      }
    } else {
      changeErrors({
        key: "residencial_cep",
        value: "CEP inválido",
      });
    }
  };

  const handleZipcode = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const zipcode = formatCEP(target.value);

    changeEditUserData({ key: "residencial_cep", value: zipcode });

    setErrors((prevErrors) => {
      const { residencial_cep, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  return (
    <ContainerAddressFormMyDocuments>
      <DescriptionModalDocuments
        title="Endereço"
        description="Antes de partir para a próxima etapa, precisamos saber o seu endereço."
      />
      <div className="address-company">
        <div className="info-company">
          <InputMyDocuments
            label="CEP"
            value={companyData?.residencial_cep || ""}
            onChange={handleZipcode}
            required
            id="zipcode"
            onBlur={getAddressBrazilAPI}
            maxLength={9}
            error={errors.residencial_cep}
            isInvalid={!!errors.residencial_cep}
            autoComplete="off"
          />
          <InputMyDocuments
            label="Número residencial"
            value={companyData?.residencial_number || ""}
            onChange={(e) =>
              changeEditUserData({
                key: "residencial_number",
                value: e.target.value,
              })
            }
            maxLength={6}
            required
            id="residencial_number"
            autoComplete="off"
          />
        </div>
        <AddressDetails
          setCompanyData={setCompanyData}
          companyData={companyData}
          setErrors={setErrors}
          errors={errors}
        />
      </div>
    </ContainerAddressFormMyDocuments>
  );
};
