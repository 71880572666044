import React, { MouseEvent } from "react";

import Link from "next/link";

import { FiChevronRight } from "react-icons/fi";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import { ComponentErrorBoundary } from "shared/components/atoms/ComponentErrorBoundary";
import CustomButtonCTA from "shared/providers/Layout/components/NewTopBar/components/atoms/CustomButtonCTA";
import { MenusList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MenusList";
import { MyGroupsList } from "shared/providers/Layout/components/NewTopBar/components/atoms/MyGroupsList";
import { useTopbarContext } from "shared/providers/Layout/hooks/useTopbarContext";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useFeature } from "shared/utils/hooks/useFeature";

import {
  ContentItem,
  Divider,
  Overlay,
  PrimarySideMenuContainer,
  SecondaryDivider,
  SecondarySideMenuContainer,
} from "./styles";

export const SideMenu = () => {
  const trailIsEnabled = useFeature("f_trails");

  const {
    closeSideMenu,
    sideMenuIsOpen,
    isSecondaryMenuContainerOpen,
    openSecondaryMenuContainer,
    closeSecondaryMenuContainer,
  } = useTopbarContext();

  const { myTrailData, hasNoActiveTrail } = useGroupContext();

  const { loggedUser } = useAuthContext();

  const handleOutsideClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    const clickedElement = target as HTMLDivElement;
    const userClickedInOverlay = [...clickedElement.classList].includes(
      Overlay.styledComponentId,
    );

    if (userClickedInOverlay) {
      closeSideMenu();
      closeSecondaryMenuContainer();
    }
  };

  const userHasActiveTrail = !!myTrailData && !hasNoActiveTrail;

  return (
    <Overlay show={sideMenuIsOpen} onClick={handleOutsideClick}>
      <PrimarySideMenuContainer show={sideMenuIsOpen}>
        <Divider />

        <ComponentErrorBoundary>
          <MenusList triggerOnLinkClick={closeSideMenu} />
        </ComponentErrorBoundary>

        {loggedUser && (
          <div className="content-items">
            <ComponentErrorBoundary>
              {userHasActiveTrail && trailIsEnabled && (
                <Link href={`/trail/${myTrailData?.slug}`}>
                  <ContentItem>
                    Minha Trilha
                    <FiChevronRight />
                  </ContentItem>
                </Link>
              )}

              <ContentItem
                onClick={
                  isSecondaryMenuContainerOpen
                    ? closeSecondaryMenuContainer
                    : openSecondaryMenuContainer
                }
              >
                Meus conteúdos
                <FiChevronRight />
              </ContentItem>
            </ComponentErrorBoundary>
          </div>
        )}

        <div className="custom-button-cta">
          <ComponentErrorBoundary>
            <CustomButtonCTA />
          </ComponentErrorBoundary>
        </div>
      </PrimarySideMenuContainer>

      <SecondarySideMenuContainer show={isSecondaryMenuContainerOpen}>
        <SecondaryDivider />

        <ComponentErrorBoundary>
          <MyGroupsList triggerOnLinkClick={closeSecondaryMenuContainer} />
        </ComponentErrorBoundary>
      </SecondarySideMenuContainer>
    </Overlay>
  );
};
