import styled from "styled-components";

export const ContainerAlertUpdateModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .cards-type-person {
    display: flex;
    gap: 1rem;

    @media (max-width: 550px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  span,
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    margin-bottom: 0;

    color: ${({ theme }) => theme?.myDocuments?.primaryDescription};
  }
`;
