import { useContext } from "react";

import { IoMdClose } from "react-icons/io";

import { useDocumentsValidation } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/hooks/useDocumentsValidation";
import useSearchCNPJ from "pages/[tenant]/console/components/organisms/ModalSendDocuments/hooks/useSearchCNPJ";
import { useSendDocuments } from "pages/[tenant]/console/components/organisms/ModalSendDocuments/hooks/useSendDocuments";

import api from "shared/infra/services/api";
import getToken from "shared/infra/services/auth/getToken";
import AuthContext from "shared/providers/context/AuthContext";

import { ConsolePageForm } from "@Console/components/molecules/ConsolePageForm";
import { Alert } from "@Console/components/organisms/ModalSendDocuments/assets/Alert";
import { Check } from "@Console/components/organisms/ModalSendDocuments/assets/Check";
import { getModeledInitialData } from "@Console/components/organisms/ModalSendDocuments/utils/getModeledInitialData";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";
import { ErrorsState, IDocuments } from "@Console/statement/types";

import {
  ModalDocumentsContainer,
  ModalDocumentsContent,
  ModalDocumentsHeader,
  WrapperButtonsDocuments,
} from "./styles";

type ModalDocumentsProps = {
  children: any;
};

const ModalDocuments = ({ children }: ModalDocumentsProps) => {
  const {
    openModalDocuments,
    setOpenModalDocuments,
    setSteps,
    steps,
    setIsValidationCNPJ,
    personType,
    setPersonType,
    errors,
    setErrors,
    companyData,
    setCompanyData,
    setLoading,
    checkboxStepCNPJ,
    setCheckboxStepCNPJ,
    hasImagesDocuments,
    cnpjData,
    setCnpjData,
    isDocumentDisapproved,
    setInitialDataDocuments,
    statusSendDocuments,
    setStatusSendDocuments,
  } = useDocumentsContext();

  const { allFieldsIsValid } = useDocumentsValidation(companyData, personType);
  const { loggedUser } = useContext(AuthContext);
  const { getInformationCNPJ } = useSearchCNPJ();

  const { sendDocuments } = useSendDocuments();

  const disabledNextButton =
    Object.keys(errors).length != 0 || !allFieldsIsValid();

  const id_update = loggedUser?.personal_documents?.id;

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "X-Requested-With": "XMLHttpRequest",
  };

  const handleCheckInformationCNPJ = () => {
    setLoading(true);

    getInformationCNPJ({
      cnpj: companyData.cnpj,
      setLoading: setLoading,
      setCnpjData: setCnpjData,
      setIsValidationCNPJ: setIsValidationCNPJ,
    });
  };

  const changeEditUserData = (dataToUpdate) => {
    setCompanyData((prev) => ({
      ...prev,
      ...Object.fromEntries(dataToUpdate.map(({ key, value }) => [key, value])),
    }));
  };

  const newCnpjData = [
    { key: "company_city", value: cnpjData?.endereco?.municipio?.descricao },
    { key: "company_neighborhood", value: cnpjData?.endereco?.bairro },
    {
      key: "company_street",
      value:
        cnpjData?.endereco?.tipoLogradouro + cnpjData?.endereco?.logradouro,
    },
    { key: "company_uf", value: cnpjData?.endereco?.uf },
    { key: "company_complement", value: cnpjData?.endereco?.complemento },
    { key: "company_coutry", value: cnpjData?.endereco?.pais?.descricao },
    { key: "company_siafi", value: cnpjData?.endereco?.municipio?.codigo },
    { key: "company_cnpj", value: cnpjData?.ni },
    {
      key: "fantasy_name",
      value: cnpjData?.nomeFantasia
        ? cnpjData?.nomeFantasia
        : cnpjData?.nomeEmpresarial,
    },
    { key: "legal_name", value: cnpjData?.nomeEmpresarial },
    { key: "company_cep", value: cnpjData?.endereco?.cep },
    { key: "company_number", value: cnpjData?.endereco?.numero },
  ];

  const handleCheckSetCNPJInformation = () => {
    changeEditUserData(newCnpjData);
  };

  const handlePersonType = () => {
    setCompanyData((prev) => ({
      ...prev,
      ["person_type"]: personType,
    }));
  };

  const handleSendDocuments = async (companyData) => {
    await sendDocuments(companyData);
  };

  const handleCloseDocuments = () => {
    setSteps(1);
    setOpenModalDocuments(false);
    setStatusSendDocuments("");
    if (statusSendDocuments === "success") {
      window.location.reload();
    }
    setCompanyData({} as IDocuments);
  };

  async function getInitialDocumentsUpdate() {
    try {
      const response = await api.get(`personal-document/${id_update}`, {
        headers,
      });
      getModeledInitialData(response, setCompanyData);
      setInitialDataDocuments(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  const buttonsHandleClickMap = {
    0: {
      nextStep: () => {
        setSteps(1);
        getInitialDocumentsUpdate();
      },
      prevStep: () => {
        setOpenModalDocuments(false);
        setSteps(0);
      },
    },

    1: {
      nextStep: () => {
        handlePersonType();
        setSteps(2);
      },
      prevStep: () => {
        setSteps(isDocumentDisapproved ? 0 : 1);
        {
          !isDocumentDisapproved && setOpenModalDocuments(false);
        }
        setPersonType("");
      },
    },
    2: {
      nextStep: () => {
        personType === "legal_entity" && handleCheckInformationCNPJ();
        personType === "legal_entity" ? setSteps(3) : setSteps(4);
      },
      prevStep: () => {
        setSteps(1);
        {
          !isDocumentDisapproved && setCompanyData({} as IDocuments);
        }
        setErrors({} as ErrorsState);
      },
    },

    3: {
      nextStep: () => {
        handleCheckSetCNPJInformation();
        setSteps(4);
      },
      prevStep: () => {
        setSteps(2);
        setCheckboxStepCNPJ(false);
      },
    },

    4: {
      nextStep: () => {
        handleSendDocuments(companyData);
        setSteps(5);
      },
      prevStep: () => {
        setSteps(personType === "legal_person" ? 3 : 2);
      },
    },

    5: {
      prevStep: () => {
        handleCloseDocuments();
      },
    },
  };

  const disabledButton = {
    1: personType?.length <= 0,
    2: !!disabledNextButton,
    3: !checkboxStepCNPJ,
    4: !hasImagesDocuments,
  };

  const handleCloseModal = () => {
    setOpenModalDocuments(false);
    setPersonType("");
    setSteps(isDocumentDisapproved ? 0 : 1);
    setCheckboxStepCNPJ(false);
    setCompanyData({} as IDocuments);
  };

  if (openModalDocuments) {
    return (
      <ModalDocumentsContainer>
        <ModalDocumentsContent stepSmall={steps === 0 || steps === 5}>
          <ModalDocumentsHeader>
            <div className="description-steps">
              {statusSendDocuments === "success" ? (
                <Check />
              ) : (
                statusSendDocuments === "Error" && <Alert />
              )}
              <p>
                {isDocumentDisapproved && steps === 0
                  ? "Alguns dados precisam ser corrigidos"
                  : statusSendDocuments === "success"
                  ? "Documentos enviados"
                  : statusSendDocuments === "Error"
                  ? "Erro ao enviar documento"
                  : "Enviando documentos"}
              </p>
            </div>

            <div className="icon-close-modal">
              <IoMdClose onClick={handleCloseModal} />
            </div>
          </ModalDocumentsHeader>

          <div className="content-documents">{children}</div>

          <WrapperButtonsDocuments>
            <ConsolePageForm.Button
              variant="secondary"
              onClick={buttonsHandleClickMap[steps]?.prevStep}
              style={{ padding: "0.375rem 1rem" }}
              id="my-documents"
            >
              {steps === 1 || steps === 0
                ? "Cancelar"
                : steps === 5
                ? "Fechar"
                : "Voltar"}
            </ConsolePageForm.Button>
            {steps !== 5 && (
              <ConsolePageForm.Button
                variant="primary"
                onClick={buttonsHandleClickMap[steps]?.nextStep}
                style={{ padding: "0.375rem 1rem" }}
                id="my-documents"
                disabled={disabledButton[steps]}
              >
                {steps === 4
                  ? "Concluir"
                  : steps === 0
                  ? "Corrigir dados"
                  : "Continuar"}
              </ConsolePageForm.Button>
            )}
          </WrapperButtonsDocuments>
        </ModalDocumentsContent>
      </ModalDocumentsContainer>
    );
  }

  return null;
};

export default ModalDocuments;
