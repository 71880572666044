import { ContainerDescriptionDocuments } from "./styles";

interface IDescriptionDocuments {
  title: string;
  description: string;
  required?: boolean;
}

export const DescriptionModalDocuments = ({
  title,
  description,
  required,
}: IDescriptionDocuments) => {
  return (
    <ContainerDescriptionDocuments>
      <div className="title-required">
        <p>{title}</p>
        {required && <span id="alert-required">*</span>}
      </div>
      <span>{description}</span>
    </ContainerDescriptionDocuments>
  );
};
