import React from "react";

export const Briefcase = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V6H4C2.897 6 2 6.897 2 8V12H7V10H9V12H15V10H17V12H22V8C22 6.897 21.103 6 20 6ZM9 4H15V6H9V4ZM17 15H15V13H9V15H7V13H2V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V13H17V15Z"
        fill="#C8C8C9"
      />
    </svg>
  );
};
