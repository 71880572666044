import React from "react";

export const RocketIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_283_18103)">
        <path
          d="M6.8925 4.76237C5.3625 6.47987 4.3125 8.94737 4.215 9.17987L1.5 8.01737L4.5375 4.97987C4.89 4.62737 5.4 4.46987 5.895 4.56737L6.8925 4.76237ZM8.3775 12.7499C8.3775 12.7499 11.1825 11.5874 12.795 9.97487C16.845 5.92487 16.17 2.75987 15.9525 2.04737C15.24 1.82237 12.075 1.15487 8.025 5.20487C6.4125 6.81737 5.25 9.62237 5.25 9.62237L8.3775 12.7499ZM13.2375 11.1074C11.52 12.6374 9.0525 13.6874 8.82 13.7849L9.9825 16.4999L13.02 13.4624C13.3725 13.1099 13.53 12.5999 13.4325 12.1049L13.2375 11.1074ZM6.75 13.4999C6.75 14.1224 6.495 14.6849 6.09 15.0899C5.205 15.9749 1.5 16.4999 1.5 16.4999C1.5 16.4999 2.025 12.7949 2.91 11.9099C3.315 11.5049 3.8775 11.2499 4.5 11.2499C5.745 11.2499 6.75 12.2549 6.75 13.4999ZM9.75 6.74987C9.75 5.92487 10.425 5.24987 11.25 5.24987C12.075 5.24987 12.75 5.92487 12.75 6.74987C12.75 7.57487 12.075 8.24987 11.25 8.24987C10.425 8.24987 9.75 7.57487 9.75 6.74987Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_18103">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
