import { IPageLog } from "shared/types/monitoringInfiniteReloadingTypes";

import { captureException, captureMessage } from "@sentry/nextjs";

interface IMakeObjectWithTimeOfEachPageReloadedProps {
  pageLogs: IPageLog;
  timeOfTolerance: number;
}

export const makeObjectWithTimeOfEachPageReloaded = ({
  pageLogs,
  timeOfTolerance,
}: IMakeObjectWithTimeOfEachPageReloadedProps) => {
  const timeOfEachPageRealodedBetweenMilisecondsOfTolerance = {};

  try {
    const pageLogsInArrayWithKeyAndValue: [string, number[]][] =
      Object?.entries(pageLogs);

    pageLogsInArrayWithKeyAndValue?.forEach(([path, timeLogs]) => {
      const timesOfPageReloadedBetweenMilisecondsOfTolerance = timeLogs?.reduce(
        (acc, timeLog, index) =>
          index < timeLogs?.length - 1 &&
          timeLogs[index + 1] - timeLog < timeOfTolerance
            ? acc + 1
            : acc,
        0,
      );

      timeOfEachPageRealodedBetweenMilisecondsOfTolerance[path] =
        timesOfPageReloadedBetweenMilisecondsOfTolerance;
    });
  } catch (error) {
    console.log("Error (makeObjectWithTimeOfEachPageReloaded): ", error);
    captureException(error);
    captureMessage("Error (makeObjectWithTimeOfEachPageReloaded)");
  }

  return timeOfEachPageRealodedBetweenMilisecondsOfTolerance;
};
