import { useEffect, useRef } from "react";

import Link from "next/link";

import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";
import { IOption } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

export const Option = ({ option }: { option: IOption }) => {
  const {
    activeOptionPathInRealTime,
    setActiveOptionPathInRealTime,
    closeMobileSectionsDropdown,
    setInfoToMoveActiveOptionIndicator,
  } = useConsolePageContext();
  const isActive = option.path === activeOptionPathInRealTime;
  const hasNotifications = !!option?.notificationNumber;
  const className = isActive ? "active" : "";
  const linkRef = useRef(null);

  useEffect(() => {
    const shouldMoveActiveSectionIndicatorWhenPageLoads =
      linkRef.current && isActive;

    if (shouldMoveActiveSectionIndicatorWhenPageLoads)
      setInfoToMoveActiveOptionIndicator(linkRef.current);
  }, [linkRef, activeOptionPathInRealTime]);

  const handleClick = (e) => {
    if (option.shouldDisable) return e.preventDefault();
    else {
      setActiveOptionPathInRealTime(option.path);
      setInfoToMoveActiveOptionIndicator(e);
      closeMobileSectionsDropdown();

      if (option?.onClick) option?.onClick();
    }
  };

  const customId = removeSpacesAndAccentsFromString(option.label);

  if (option.shouldHide) return null;
  return (
    <li data-disabled={option.shouldDisable} id={customId}>
      <Link
        href={option.path}
        className={className}
        onClick={handleClick}
        ref={linkRef}
      >
        {option?.icon && option.icon}

        {option.label}

        {hasNotifications && (
          <div className="notification-number">
            {option?.notificationNumber}
          </div>
        )}
      </Link>
    </li>
  );
};
