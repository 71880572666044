/* eslint-disable @next/next/no-img-element */
import Link from "next/link";

import { UserMenu } from "shared/providers/Layout/components/NewTopBar/components/atoms/UserMenu";
import { useAuthContext } from "shared/utils/hooks/useAuthContext";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { ContainerTopBarCheckout } from "./styles";

export const TopBarCheckout = () => {
  const { loggedUser } = useAuthContext();
  const { state } = useGlobalContext();

  const isDarkMode = state.theme.value === "dark";
  const tenantData = state.tenancy;
  const tenantTheme = tenantData?.settings["general-theme"];

  const {
    state: { tenancy },
  } = useGlobalContext();

  return (
    <ContainerTopBarCheckout>
      <div className="image-container">
        <Link href={`/${tenancy.settings.site.mainPage}`}>
          <img
            className="logo"
            src={
              isDarkMode
                ? tenantTheme?.themeDarkModeLogoHorizontal
                : tenantTheme?.themeLogoHorizontal
            }
            alt="Logotipo"
          />
        </Link>
      </div>
      {loggedUser && (
        <div className="container-user-menu">
          <UserMenu />
        </div>
      )}
    </ContainerTopBarCheckout>
  );
};
