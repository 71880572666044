export const TrailsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66536 4.09508V12.6444L6.04187 11.1974L10.0419 13.1974L13.332 11.7873V3.23793L14.2007 2.86564C14.37 2.79312 14.5659 2.8715 14.6384 3.04071C14.6562 3.0822 14.6654 3.12688 14.6654 3.17202V12.6665L9.9987 14.6665L5.9987 12.6665L1.79667 14.4674C1.62746 14.5399 1.4315 14.4615 1.35898 14.2923C1.3412 14.2508 1.33203 14.2061 1.33203 14.161V4.6665L2.66536 4.09508ZM10.8271 7.4949L7.9987 10.3234L5.17027 7.4949C3.60817 5.93283 3.60817 3.40018 5.17027 1.83808C6.73236 0.27598 9.26503 0.27598 10.8271 1.83808C12.3892 3.40018 12.3892 5.93283 10.8271 7.4949ZM7.9987 8.43777L9.8843 6.55212C10.9257 5.51072 10.9257 3.82228 9.8843 2.78088C8.8429 1.73949 7.1545 1.73949 6.11308 2.78088C5.07168 3.82228 5.07168 5.51072 6.11308 6.55212L7.9987 8.43777Z"
        fill="currentColor"
      />
    </svg>
  );
};
