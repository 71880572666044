import { useCallback, useEffect } from "react";

import { useRouter, useSearchParams } from "next/navigation";

import ModulesList from "pages/[tenant]/console/groups/[group_slug]/modules/components/molecules/ModulesList";
import useModulesPageContext from "pages/[tenant]/console/groups/[group_slug]/modules/hooks/useModulesPageContext";

import { useConsolePageContext } from "pages/[tenant]/console/hooks/useConsolePageContext";

import { Plus } from "shared/assets/icons/Plus";

import {
  ModuleManagerContainer,
  ModulesInternalSidebarContentContainer,
  NewModuleButton,
} from "./styles";

const ModulesInternalSidebarContent = () => {
  const { push } = useRouter();
  const params = useSearchParams();
  const group_slug = params.get("group_slug");

  const { closeMobileInternalSectionsDropdown } = useConsolePageContext();

  const {
    group,
    setModulesInternalRoutes,
    clearModuleState,
    updateModuleLocally,
    modules,
    clearAllStates,
  } = useModulesPageContext();

  const goToCreatingModule = useCallback(() => {
    if (!group) return;

    const { slug, id } = group;

    clearModuleState();
    closeMobileInternalSectionsDropdown();
    setModulesInternalRoutes("creating-a-module");
    updateModuleLocally("position", modules?.length);
    updateModuleLocally("group_id", id);
    push(`/console/groups/${slug}/modules`);
  }, [
    group,
    modules,
    clearModuleState,
    closeMobileInternalSectionsDropdown,
    setModulesInternalRoutes,
    updateModuleLocally,
    push,
  ]);

  useEffect(() => {
    return () => clearAllStates();
  }, [group_slug]);

  return (
    <ModulesInternalSidebarContentContainer>
      <div role="heading">{group?.title}</div>

      <ModuleManagerContainer>
        <NewModuleButton onClick={goToCreatingModule}>
          <Plus />
          Novo módulo
        </NewModuleButton>
      </ModuleManagerContainer>

      <ModulesList />
    </ModulesInternalSidebarContentContainer>
  );
};

export default ModulesInternalSidebarContent;
