import React, { InputHTMLAttributes } from "react";

import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";
import { IConsolePageInputsProps } from "pages/[tenant]/console/types";
import { removeSpacesAndAccentsFromString } from "pages/[tenant]/console/utils/normalizeString";

import { InputWithIconContainer } from "./styles";

type IUnionInputProps = IConsolePageInputsProps &
  InputHTMLAttributes<HTMLInputElement>;

interface IInputWithIconProps extends IUnionInputProps {
  leftIcon: JSX.Element;
}

export const InputWithIcon = ({
  label,
  leftIcon,
  ...rest
}: IInputWithIconProps) => {
  const customId = removeSpacesAndAccentsFromString(label);

  return (
    <InputWithIconContainer id={customId}>
      <div className="icon-container">{leftIcon}</div>
      <ConsolePageForm.Input label={label} {...rest} />
    </InputWithIconContainer>
  );
};
