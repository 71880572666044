import api from "shared/infra/services/api";
import { getAllSettledPromiseValue } from "shared/utils/getAllSettledPromiseValue";

import { captureException, captureMessage } from "@sentry/nextjs";

const fetchMenus = async () => {
  try {
    const sessionStorageMenus = sessionStorage?.getItem("menus");

    if (sessionStorageMenus) return JSON.parse(sessionStorageMenus);

    const [sidebarResult, topbarResult, profileMenuResult] =
      await Promise.allSettled([
        api.get("menu/menu"),
        api.get("menu/topbar"),
        api.get("menu/profile"),
      ]);

    const sidebar = getAllSettledPromiseValue(sidebarResult, {
      data: [],
    });

    const topbar = getAllSettledPromiseValue(topbarResult, {
      data: [],
    });

    const profileMenu = getAllSettledPromiseValue(profileMenuResult, {
      data: [],
    });

    const menus = {
      sidebar: sidebar.data.data,
      topbar: topbar.data.data,
      profileMenu: profileMenu.data.data,
    };

    sessionStorage?.setItem("menus", JSON.stringify({ menus }));

    return { menus };
  } catch (error) {
    console.log("[fetchMenus.ts]: an error occurred in fetchMenus.ts", error);
    sessionStorage.removeItem("menus");
    sessionStorage.removeItem("menu-items");
    captureException(error);
    captureMessage("[fetchMenus.ts]: an error occurred in fetchMenus.ts");
    return error;
  }
};

export default fetchMenus;
