import Image from "next/image";

import useTranslation from "next-translate/useTranslation";

import locked from "shared/assets/images/locked.svg";
import empty from "shared/assets/images/mock_empty.svg";
import StaticIntlTranslator from "shared/components/atoms/StaticIntlTranslator";
import DefaultModal from "shared/components/templates/DefaultModal";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import staticIntlTranslatorToString from "shared/utils/staticIntlTranslatorToString";

import {
  ContainerImage,
  ContainerModal,
  Header,
  OnlyEnrolledUsersPermissionContainer,
  RequiredItemsContainer,
} from "./styles";

export default function OnlyEnrolledUsersPermission() {
  const { state, setState } = useGlobalContext();
  const { lang } = useTranslation();

  const {
    reasonsCourses: requiredCourses,
    reasonsBundles: requiredBundles,
    contextType,
    reasonsAnyDescription,
  } = state?.enrolledUsersPermission;

  const handleNavigationType = (type, slug) => {
    switch (type) {
      case "App\\MediaSpaceContent":
        return window.open(`/media/${slug}`);

      case "Vico068\\CourseTools\\Course":
        return window.open(`/courses/${slug}`);

      case "Vico068\\CourseTools\\Lesson":
        return window.open(`/courses/${slug}`);

      default:
        return null;
    }
  };

  const handleInfoType = (type) => {
    switch (type) {
      case "App\\MediaSpaceContent":
        return "Media space";

      case "Vico068\\CourseTools\\Course":
        return <StaticIntlTranslator id="common:course" />;

      case "Vico068\\CourseTools\\Lesson":
        return <StaticIntlTranslator id="common:course" />;

      default:
        return null;
    }
  };

  const handleHeaderTitleType = () => {
    switch (contextType) {
      case "community":
        return (
          <h1>
            <StaticIntlTranslator id="common:viewContent" />
          </h1>
        );

      case "communityTopic":
        return (
          <h1>
            <StaticIntlTranslator id="common:newTopic" />
          </h1>
        );

      case "comment":
        return (
          <h1>
            <StaticIntlTranslator id="common:viewComments" />
          </h1>
        );

      case "commentEditor":
        return (
          <h1>
            <StaticIntlTranslator id="common:writeComments" />
          </h1>
        );

      default:
        return null;
    }
  };

  const handleOnClose = () => {
    setState((prev) => ({
      ...prev,
      enrolledUsersPermission: {
        show: false,
        contextType: "",
        reasonsCourses: "",
        reasonsBundles: "",
        reasonsAnyDescription: "",
      },
    }));
  };

  return (
    <>
      <DefaultModal onClose={handleOnClose}>
        <OnlyEnrolledUsersPermissionContainer>
          <div className="locked-container">
            <Image
              src={locked}
              width={74}
              height={54}
              alt=""
              className="lock"
            />
          </div>

          <Header>
            {handleHeaderTitleType()}

            <p>
              <StaticIntlTranslator id="common:onlyAllowedForUsersWithAccess" />
              :
            </p>
          </Header>

          {/* Private treatments for the community */}
          <RequiredItemsContainer>
            {requiredCourses?.length > 0 &&
              requiredCourses?.map((course) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() => window.open(`/courses/${course.slug}`)}
                  key={course.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <Image
                          src={
                            course?.thumbnail ||
                            course?.image_thumbnail ||
                            empty
                          }
                          alt=""
                          width={100}
                          height={100}
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>
                      <StaticIntlTranslator id="common:course" />:
                    </strong>
                    <i>{course?.title}</i>
                  </div>
                </ContainerModal>
              ))}

            {/* Private treatments for the community */}
            {requiredBundles?.length > 0 &&
              requiredBundles?.map((bundle) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() => window.open(`/bundles/${bundle.slug}`)}
                  key={bundle.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <Image
                          src={
                            bundle?.thumbnail ||
                            bundle?.image_thumbnail ||
                            empty
                          }
                          blurDataURL={
                            bundle?.thumbnail ||
                            bundle?.image_thumbnail ||
                            empty
                          }
                          width={500}
                          height={300}
                          alt=""
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>
                      <StaticIntlTranslator id="common:bundle" />:{" "}
                    </strong>
                    <i>{bundle?.title}</i>
                  </div>
                </ContainerModal>
              ))}

            {reasonsAnyDescription?.length > 0 &&
              reasonsAnyDescription?.map((entity) => (
                <ContainerModal
                  className="gx-product-item gx-product-horizontal"
                  onClick={() =>
                    handleNavigationType(entity.className, entity.slug)
                  }
                  key={entity.id}
                >
                  <ContainerImage className="gx-product-image">
                    <div className="gx-grid-thumb-equal">
                      <span className="gx-link gx-grid-thumb-cover">
                        <Image
                          src={
                            entity?.thumbnail ||
                            entity?.image_thumbnail ||
                            empty
                          }
                          width={500}
                          height={300}
                          alt=""
                        />
                      </span>
                    </div>
                  </ContainerImage>

                  <div className="gx-product-body">
                    <strong>{handleInfoType(entity?.className)}: </strong>
                    <i>{staticIntlTranslatorToString(entity?.title, lang)}</i>
                  </div>
                </ContainerModal>
              ))}
          </RequiredItemsContainer>
        </OnlyEnrolledUsersPermissionContainer>
      </DefaultModal>
    </>
  );
}
