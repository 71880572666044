import { usePathname, useSearchParams } from "next/navigation";

import { ArchivedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/ArchivedIcon";
import { GroupsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/GroupsIcon";
import { NexusIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/NexusIcon";
import { PublishedIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/PublishedIcon";
import { QuestionBankIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/QuestionBankIcon";
import { TrailsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/groupsAndContent/TrailsIcon";
import { IOptionsHook, ISubSection } from "pages/[tenant]/console/types";

import { useFeature } from "shared/utils/hooks/useFeature";

export const useGroupAndContentOptions = (): IOptionsHook => {
  const pathname = usePathname();
  const params = useSearchParams();
  const group_slug = params.get("group_slug");
  const trailIsEnabled = useFeature("f_trails");

  const isInLocalhost =
    typeof window !== "undefined" &&
    window?.location?.href?.includes("localhost");

  const sectionLabel = "Grupos e recursos";
  const groupsAndContentSubSections: ISubSection[] = [
    {
      label: "Grupos",
      options: [
        {
          id: 1,
          label: "Todos os grupos",
          description:
            "Os grupos permitem que você reúna seus usuários, conteúdos, eventos e comunidade em um só lugar.",
          path: "/console/groups",
          icon: <GroupsIcon />,
        },
        {
          id: 2,
          label: "Publicados",
          path: "/console/groups/published",
          icon: <PublishedIcon />,
          shouldHide: true,
        },
        {
          id: 3,
          label: "Arquivados",
          path: "/console/groups/archived",
          icon: <ArchivedIcon />,
          shouldHide: true,
        },
      ],
    },
    {
      label: "Recursos",
      options: [
        {
          id: 20,
          label: "Vínculos",
          description:
            "Relacione grupos e permita que seus alunos obtenham acesso a outros grupos a partir da mesma inscrição.",
          path: "/console/groups/bindings",
          icon: <NexusIcon />,
          shouldHide: !isInLocalhost,
        },
        {
          id: 21,
          label: "Banco de Questões",
          description:
            "Reutilize questões entre quizzes de vários grupos diferentes e mantenha uma relação centralizada.",
          path: "/console/questionbank",
          icon: <QuestionBankIcon />,
          shouldHide: true,
        },
        {
          id: 22,
          label: "Trilhas",
          description:
            "As trilhas permitem que você monte uma escada de valor multi-nível e determine uma direção para que seus usuários completem o conteúdo.",
          path: "/console/trails",
          icon: <TrailsIcon />,
          shouldHide: !trailIsEnabled,
        },
      ],
    },
  ];

  return {
    subSections: groupsAndContentSubSections,
    label: sectionLabel,
  };
};
