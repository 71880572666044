import React, { useLayoutEffect, useState } from "react";

import Modal from "shared/components/templates/DefaultModal";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";
import openNotificationWithIcon from "shared/utils/openNotificationWithIcon";

import Close from "./screens/Close";
import ForgotPassword from "./screens/ForgotPassword";
import InitialScreen from "./screens/Initial";
import Login from "./screens/Login/index";
import Register from "./screens/Register/index";

function AuthModal() {
  const { state, setState } = useGlobalContext();

  const choosePage = state.modal.page;

  const [loginPage, setLoginPage] = useState(choosePage);
  const [history, setHistory] = useState([]);

  const showLoginModal = state.modal.open;

  const userSignUpAnyoneCanCreateAccount =
    state.tenancy?.settings?.users?.userSignUpAnyoneCanCreateAccount;

  function handleCloseAuthModal() {
    setLoginPage("first");
    setState((prev) => ({
      ...prev,
      modal: {
        open: false,
      },
    }));
  }

  const handleBackAuthModal =
    loginPage === "first"
      ? false
      : () => {
          const tempHistory = history;
          const lastItem = tempHistory.pop();
          setHistory(tempHistory);
          setLoginPage(lastItem || "first");
        };

  function handleNavigate(page) {
    setHistory([...history, loginPage]);

    const closeAuthModal = page === "confirmation";
    if (closeAuthModal) {
      handleCloseAuthModal();
      openNotificationWithIcon("success", "Conta criada com sucesso!");
    }

    setLoginPage(page);
  }

  function RenderPage() {
    switch (loginPage) {
      case "first":
        return <InitialScreen navigate={handleNavigate} />;
      case "login":
        return <Login navigate={handleNavigate} />;
      case "register":
        return (
          <>
            {userSignUpAnyoneCanCreateAccount === "1" ? (
              <Register navigate={handleNavigate} />
            ) : (
              <Close navigate={handleNavigate} />
            )}
          </>
        );
      case "forgot":
        return <ForgotPassword navigate={handleNavigate} />;
      default:
        return null;
    }
  }

  useLayoutEffect(() => {
    if (choosePage) {
      setLoginPage(choosePage);
    } else {
      setLoginPage("first");
    }
  }, [choosePage]);

  return (
    showLoginModal && (
      <Modal onGoBack={handleBackAuthModal} onClose={handleCloseAuthModal}>
        <RenderPage />
      </Modal>
    )
  );
}

export default AuthModal;
