import { ConsolePageForm } from "pages/[tenant]/console/components/molecules/ConsolePageForm";

import { MessageError } from "@Console/assets/MessageError";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { ContainerAlertMyDocuments } from "./styles";

export const AlertMyDocuments = ({ statusDocuments }) => {
  const { setOpenModalDocuments } = useDocumentsContext();

  const status = !statusDocuments
    ? "create"
    : statusDocuments?.status === "disapproved" && "update";

  const description = !statusDocuments
    ? "Complete seu cadastro para começar a vender."
    : statusDocuments.status === "disapproved" &&
      "Alguns dados do seu cadastro precisam ser atualizados.";

  const action = !statusDocuments
    ? "Concluir cadastro"
    : statusDocuments?.status === "disapproved" && "Corrigir dados";

  const handleClickOpen = () => {
    setOpenModalDocuments(true);
  };

  return (
    <ContainerAlertMyDocuments status={status}>
      <div className="alert-message-my-documents">
        <MessageError />
        <p>{description}</p>
      </div>
      <ConsolePageForm.Button
        variant={!statusDocuments ? "documentsAlert" : "documentsError"}
        onClick={handleClickOpen}
        style={{ width: "auto" }}
        id="my-documents"
      >
        {action}
      </ConsolePageForm.Button>
    </ContainerAlertMyDocuments>
  );
};
