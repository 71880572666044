import { captureException, captureMessage } from "@sentry/nextjs";

export const removeDuplicatedObjects = (
  arrayWithDuplicatedObjects: any[],
): any[] => {
  let normalizedArray = [...arrayWithDuplicatedObjects];

  try {
    const seenIds = new Set();

    normalizedArray = arrayWithDuplicatedObjects?.filter((obj) => {
      if (!seenIds?.has(obj?.id)) {
        seenIds?.add(obj?.id);
        return true;
      }
      return false;
    });
  } catch (error) {
    console.log("Erro (removeDuplicatedObjects): ", error);
    captureException(error);
    captureMessage("Error (removeDuplicatedObjects): ");
  }

  return normalizedArray;
};
