import { useSearchParams } from "next/navigation";

import { IPagesToShow } from "../../types";

export const usePagesToShowInInternalModulePage = (): IPagesToShow => {
  const params = useSearchParams();
  const group_slug = params.get("group_slug");
  const module_slug = params.get("module_slug");
  const lesson_slug = params.get("lesson_slug");

  return [
    `/console/groups/${group_slug}/modules`,
    `/console/groups/${group_slug}/modules/${module_slug}/lesson-types`,
    `/console/groups/${group_slug}/modules/${module_slug}/lesson-types/${lesson_slug}`,
  ];
};
