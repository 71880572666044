import { Briefcase } from "@Console/assets/Briefcase";
import { UserBadge } from "@Console/assets/UserBadge";
import { Wallet } from "@Console/assets/Wallet";
import { CardPersonalType } from "@Console/components/atoms/CardPersonalType";
import { DescriptionModalDocuments } from "@Console/components/atoms/DescriptionModalDocuments";
import { useDocumentsContext } from "@Console/statement/hooks/useDocumentsContext";

import { ContainerAlertNaturalPerson, ContainerEntityType } from "./styles";

export const EntityType = () => {
  const { setPersonType, personType } = useDocumentsContext();

  const handleClick = (type) => {
    setPersonType(type);
  };

  return (
    <ContainerEntityType>
      <DescriptionModalDocuments
        title="Precisamos saber o tipo da sua empresa!"
        description="Selecione o tipo da sua empresa para continuarmos com o seu envio de documentos."
      />
      <div className="cards-type-person">
        <CardPersonalType
          icon={<Briefcase />}
          title="Pessoa Jurídica"
          description="Entidade legal criada através de um processo formal, como registro ou incorporação, para fins comerciais, organizacionais, ou outros."
          onClick={() => handleClick("legal_entity")}
          selected={personType === "legal_entity"}
        />

        <CardPersonalType
          icon={<UserBadge />}
          title="Pessoa Física"
          description="Indivíduo singular, uma pessoa com existência física que pode ser identificada e possui direitos e responsabilidades legais"
          onClick={() => handleClick("natural_person")}
          selected={personType === "natural_person"}
        />
      </div>
      {personType === "natural_person" && (
        <ContainerAlertNaturalPerson>
          <Wallet />
          <span>
            Ao optar pelo cadastro como Pessoa Física, há um limite anual de R$
            28.559,70 de saque.
          </span>
        </ContainerAlertNaturalPerson>
      )}
    </ContainerEntityType>
  );
};
