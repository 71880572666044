import styled from "styled-components";

export const ContainerAddressFormMyDocuments = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  .info-company {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;
