import { captureException, captureMessage } from "@sentry/nextjs";

export const isInLocalhost = (): boolean => {
  let isInLocalhost = false;

  try {
    isInLocalhost = window?.location?.href.includes("localhost");
  } catch (error) {
    console.log("Error (isInLocalhost): ", error);
    captureException(error);
    captureMessage("Error (isInLocalhost)");
  }

  return isInLocalhost;
};
