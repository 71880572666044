import { AccessIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/AccessIcon";
import { AffiliatesIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/AffiliatesIcon";
import { BagIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/BagIcon";
import { CuponsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/CuponsIcon";
import { OffersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/OffersIcon";
import { OrdersIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/OrdersIcon";
import { SplitsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/SplitsIcon";
import { StatmentIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/StatmentIcon";
import { TransactionsIcon } from "pages/[tenant]/console/assets/icons/sidebarSection/sales/TransactionsIcon";
import {
  IOption,
  IOptionsHook,
  ISubSection,
} from "pages/[tenant]/console/types";

export const useSalesOptions = (): IOptionsHook => {
  const sectionLabel = "Vendas";
  const sales: IOption[] = [
    {
      id: 1,
      label: "Ofertas",
      path: "/console/offers",
      icon: <OffersIcon />,
      shouldHide: true,
    },
    {
      id: 2,
      label: "Pedidos",
      path: "/console/orders",
      icon: <OrdersIcon />,
      shouldHide: true,
    },
    {
      id: 3,
      label: "Transações",
      description:
        "Acompanhe as transações realizadas por suas vendas no checkout da Ensinio Pay.",
      path: "/console/transactions",
      icon: <TransactionsIcon />,
    },
    {
      id: 4,
      label: "Acessos vendidos",
      description:
        "Monitore os acessos e a renovação das assinaturas na sua plataforma.",
      path: "/console/access",
      icon: <AccessIcon />,
    },
    {
      id: 5,
      label: "Cupons",
      description: "Potencialize suas vendas com nossa página de cupons. Acompanhe de perto o uso dos cupons e ajuste suas estratégias para alcançar o máximo retorno sobre investimento.",
      path: "/console/coupons",
      icon: <CuponsIcon />,
    },
    {
      id: 6,
      label: "Afiliados",
      path: "/console/affiliates",
      icon: <AffiliatesIcon />,
      shouldHide: true,
    },
    {
      id: 7,
      label: "Split de vendas",
      path: "/console/split",
      icon: <SplitsIcon />,
      shouldHide: true,
    },
    {
      id: 8,
      label: "Extrato e saques",
      description:
        "Acompanhe o repasse das suas receitas e gerencie suas solicitações de saques.",
      path: "/console/statement",
      icon: <StatmentIcon />,
    },
  ];
  const salesSubSection: ISubSection[] = [
    // {
    //   label: "Ferramentas",
    //   options: [
    //     {
    //       id: 20,
    //       label: "Funil inteligente",
    //       path: "/console/404",
    //       icon: <FunnelIcon />,
    //       shouldHide: true,
    //     },
    //     {
    //       id: 21,
    //       label: "Order bump",
    //       path: "/console/404",
    //       icon: <BagIcon />,
    //       shouldHide: true,
    //     },
    //   ],
    // },
  ];

  return {
    options: sales,
    label: sectionLabel,
    subSections: salesSubSection,
  };
};
