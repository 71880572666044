import { useCallback, useEffect } from "react";

import { captureException, captureMessage } from "@sentry/nextjs";

const useWindowErrorEvents = () => {
  const handleWindowErrorEvents = useCallback(() => {
    try {
      window?.addEventListener("error", (errorEvent) => {
        console.log("Evento de erro capturado: ", errorEvent?.error?.stack);
        captureException(errorEvent);
      });
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that handles window error events.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, []);

  useEffect(() => {
    handleWindowErrorEvents();
  }, [handleWindowErrorEvents]);

  return {};
};

export default useWindowErrorEvents;
