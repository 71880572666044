import { useCallback, useEffect } from "react";

import { destroyCookie } from "nookies";

import { captureException, captureMessage } from "@sentry/nextjs";

const useDestroyCookieBeforeUnload = () => {
  const handleDestroyCookieBeforeUnload = useCallback(() => {
    try {
      window?.addEventListener("beforeunload", () => {
        destroyCookie(null, "slug");
      });
    } catch (error) {
      const errorMessage =
        "[_app]: window?.addEventListener('beforeunload', ... failed.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, []);

  useEffect(() => {
    handleDestroyCookieBeforeUnload();
  }, [handleDestroyCookieBeforeUnload]);

  return {};
};

export default useDestroyCookieBeforeUnload;
