import { captureMessage } from "@sentry/nextjs";
import { detect } from "detect-browser";

import getUsernameFromCookies from "shared/infra/services/auth/getUsernameFromCookies";

export const retryPageLoading = () => {
  const PageHasBeenForceRefreshed = "page-has-been-force-refreshed-by-page-error-boundary";
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window?.localStorage?.getItem(PageHasBeenForceRefreshed) || "false",
  );
  if (!pageHasAlreadyBeenForceRefreshed) {
    window?.localStorage?.setItem(PageHasBeenForceRefreshed, "true");
    console.log("Página recarregando através de src/shared/providers/PageErrorBoundary/utils/index.ts");
    captureMessage("Página recarregando através de src/shared/providers/PageErrorBoundary/utils/index.ts");
    return window?.location?.reload();
  }
  window?.localStorage?.setItem(PageHasBeenForceRefreshed, "false");
};

const getBrowserInfo = () => {
  const browser = detect();
  return `${browser?.name} v${browser?.version}`;
};

export const formatStackTrace = (stackTrace) => {
  const firstLine = stackTrace?.split("\n")?.slice(1, 2)?.join("\n");
  return `\`\`\`${firstLine}\`\`\``;
};

export const formatErrorMessage = (error: any): string => {
  const username = getUsernameFromCookies();
  const cache = document
    ?.querySelector("meta[http-equiv=cache-control]")
    ?.getAttribute("content");

  const lines = [
    `\n *🔴 Erro:* ${error?.message}`,
    `\n *Origem:* ${window?.location?.href}`,
    `\n *Navegador:* ${getBrowserInfo()}`,
    username ? `\n *Usuário:* ${username}` : "",
    cache ? `\n *Cache:* ${cache}` : "",
    `\n *Linha:* ${formatStackTrace(error?.stack)}`,
  ];

  const logText = lines.reduce(
    (currentText, currentLine) => currentText + currentLine,
    "",
  );

  return logText;
};
