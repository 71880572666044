import { SeePlansButton } from "@Console/billing/components/atoms/TopFlag/styles";
import { ITopFlagContent } from "@Console/billing/types";

export const TopFlagContent = ({
  icon,
  leftText,
  rightText,
  buttonOnClick,
  buttonLabel,
}: ITopFlagContent) => {
  return (
    <>
      <div className="left-text">
        {icon}

        {leftText}
      </div>

      <div className="plan-info">
        {rightText}

        <SeePlansButton onClick={buttonOnClick}>{buttonLabel}</SeePlansButton>
      </div>
    </>
  );
};
