import styled from "styled-components";

interface ICardProps {
  selected: boolean;
}

export const ContainerCardPersonalType = styled.div<ICardProps>`
  max-width: 368px;
  min-width: 250px;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  gap: 0.5rem;

  border-radius: 0.25rem;
  border: 1px solid #373940;

  background: ${({ theme }) => theme?.myDocuments?.backgroundCards};

  .icon {
    display: flex;
    flex-direction: column;

    svg path {
      fill: ${({ theme }) => theme.console.cardDescriptionColor};
    }
  }

  .title-person-type {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
  }

  .description-person {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.313rem;
    text-align: left;
    color: ${({ theme }) => theme.console.cardDescriptionColor};
  }

  .type-person {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &:hover {
    border: 1px solid #605cff;
    .type-person h3 {
      color: #605cff;
    }
  }

  &:hover .icon-person-type svg path {
    fill: #605cff;
  }

  ${({ selected }) =>
    selected &&
    `
    border: 1px solid #605cff;

    .title-person-type {
      color:#605cff!important;
    }

    .icon-person-type svg path {
      fill: #605cff;
    }

    &:hover {
      border: 1px solid #605cff;
    }
  `}
`;
