import { ClockIcon } from "@Console/billing/assets/icons/ClockIcon";
import { PadlockIcon } from "@Console/billing/assets/icons/PadlockIcon";
import { SadFaceIcon } from "@Console/billing/assets/icons/SadFaceIcon";
import { WalletIcon } from "@Console/billing/assets/icons/WalletIcon";
import { ITopFlagContentMap } from "@Console/billing/types";
import { RocketIcon } from "@Console/components/templates/ConsoleLayout/assets/icons/RocketIcon";

export const topFlagContentMap: ITopFlagContentMap = {
  trial: {
    backgroundColor: "#605CFF",
    icon: <RocketIcon />,
    buttonLabel: "Ver planos",
  },
  trialIsFinishing: {
    backgroundColor: "#E06516",
    icon: <ClockIcon />,
    buttonLabel: "Ver planos",
  },
  trialIsFinished: {
    backgroundColor: "#FB3449",
    icon: <SadFaceIcon />,
    buttonLabel: "Ver planos",
  },
  billingFailed: {
    backgroundColor: "#FF7A2F",
    icon: <WalletIcon />,
    buttonLabel: "Conferir faturamento",
  },
  canceledService: {
    backgroundColor: "#FB3449",
    icon: <PadlockIcon />,
    buttonLabel: "Conferir faturamento",
  },
} as ITopFlagContentMap;
