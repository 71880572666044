import React from "react";

import { detect } from "detect-browser";

import getUsernameFromCookies from "shared/infra/services/auth/getUsernameFromCookies";
import sendLogToSlack from "shared/infra/services/slackAPI";

import { captureException, captureMessage } from "@sentry/nextjs";

import { ErrorBoundaryContainer } from "./styles";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed-by-error-boundary";

const retryPageLoading = () => {
  try {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window?.localStorage?.getItem(PageHasBeenForceRefreshed) || "false",
    );
    if (!pageHasAlreadyBeenForceRefreshed) {
      window?.localStorage?.setItem(PageHasBeenForceRefreshed, "true");
      console.log("Página recarregando através de src/shared/providers/ErrorBoundary/index.jsx");
      captureMessage("Página recarregando através de src/shared/providers/ErrorBoundary/index.jsx");
      return window?.location?.reload();
    }
    window?.localStorage?.setItem(PageHasBeenForceRefreshed, "false");
  } catch (error) {
    console.log(
      "[ErrorBoundary]: an error occurred in the retryPageLoading function of ErrorBoundary",
      error,
    );
    captureException(error);
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    try {
      captureException(error);
      retryPageLoading();

      const username = getUsernameFromCookies();

      const lines = [
        `\n *🔴 Erro:* ${error?.message}`,
        `\n *Origem:* ${window?.location?.href}`,
        `\n *Navegador:* ${this.getBrowserInfo()}`,
        username ? `\n *Usuário:* ${username}` : "",
        `\n *Linha:* ${this.formatStackTrace(error?.stack)}`,
      ];

      const logText = lines.reduce(
        (currentText, currentLine) => currentText + currentLine,
        "",
      );

      if (!window?.location?.hostname?.includes("localhost")) {
        sendLogToSlack(logText);
      }
    } catch (error) {
      console.log(
        "[ErrorBoundary]: an error occurred in the componentDidCatch method of ErrorBoundary",
        error,
      );
      captureException(error);
    }
  }

  getBrowserInfo = () => {
    const browser = detect();
    return `${browser?.name} v${browser?.version}`;
  };

  formatStackTrace = (stackTrace) => {
    const firstLine = stackTrace.split("\n").slice(1, 2).join("\n");
    return `\`\`\`${firstLine}\`\`\``;
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <ErrorBoundaryContainer>
        <h1>Algo deu errado :(</h1>

        <p>Lamentamos o inconveniente.</p>
        <p>
          Geramos automaticamente um relatório sobre o ocorrido, e trabalharemos
          para corrigir o quanto antes.
        </p>

        <p>Por favor, tente novamente mais tarde.</p>

        <button onClick={() => window?.location?.reload()} type="button">
          Tentar novamente
        </button>
      </ErrorBoundaryContainer>
    ) : (
      <>{children}</>
    );
  }
}

export default ErrorBoundary;
