import { captureException, captureMessage } from "@sentry/nextjs";

const sessionStoragePreviousUrlKey = "previourUrl";
const sessionStorageDestinationUrlKey = "destinationUrl";

export const savePreviousURLInSessionStorage = (previourUrl: string): void => {
  try {
    sessionStorage.setItem(sessionStoragePreviousUrlKey, previourUrl);
  } catch (error) {
    console.log("Erro (savePreviousURLInSessionStorage): ", error);
    captureException(error);
    captureMessage("Erro (savePreviousURLInSessionStorage)");
  }
};

export const getPreviousURLFromSessionStorage = (): string => {
  let previourUrl = "";

  try {
    previourUrl = sessionStorage.getItem(sessionStoragePreviousUrlKey);
  } catch (error) {
    console.log("Erro (getPreviousURLFromSessionStorage): ", error);
    captureException(error);
    captureMessage("Erro (getPreviousURLFromSessionStorage)");
  }

  return previourUrl;
};

export const saveDestinationInSessionStorage = (
  destinationUrl: string,
): void => {
  if (
    destinationUrl.includes("error") ||
    destinationUrl.includes("not-found") ||
    destinationUrl.includes("404")
  )
    return;

  try {
    sessionStorage.setItem(sessionStorageDestinationUrlKey, destinationUrl);
  } catch (error) {
    console.log("Erro (saveDestinationInSessionStorage): ", error);
    captureException(error);
    captureMessage("Erro (saveDestinationInSessionStorage)");
  }
};

export const getDestinationFromSessionStorage = (): string => {
  let destinationUrl = "";

  try {
    destinationUrl = sessionStorage.getItem(sessionStorageDestinationUrlKey);
  } catch (error) {
    console.log("Erro (getDestinationFromSessionStorage): ", error);
    captureException(error);
    captureMessage("Erro (getDestinationFromSessionStorage)");
  }

  return destinationUrl;
};
