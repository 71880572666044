import { css } from "styled-components";

export const consolePageButtonVariants = {
  primary: css`
    background-color: #605cff;
    color: #fafafa;
  `,
  secondary: css`
    background-color: ${({ theme }) =>
      theme?.console.secondaryButtonBackground};
    color: ${({ theme }) => theme?.console?.secondaryButtonTextColor};
  `,
  confirm: css`
    background-color: #23a840;
    color: #f2f2f2;
  `,
  danger: css`
    background-color: #ec3434;
    color: #f2f2f2;
  `,
  transparent: css`
    color: ${({ theme }) => theme?.console?.sidebar.optionTextColor};
  `,
  documentsError: css`
    background-color: #ffffff;
    color: #fd4848;
  `,
  documentsAlert: css`
    background-color: #ffeb39;
    color: #000000;
  `,
};
